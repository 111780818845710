import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "_topLine"
  }, [_c('div', [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.notification.subject))]), _c('small', [_vm._v("  (von: " + _vm._s((_vm._svNamesFB[_vm.notification.sender] || {}).name || (_vm._notificationGroups[_vm.notification.sender] || {}).name) + ")")]), _vm.notification.crmAkt ? _c('small', [_vm._v(" "), _c('router-link', {
    staticClass: "bold color-blue",
    attrs: {
      "to": '/crmakt/' + _vm.notification.crmAkt
    }
  }, [_vm._v("(" + _vm._s(_vm.notification.crmAkt) + ")")])], 1) : _vm._e()]), _c('div', [_vm.notification && _vm.notification.created_at && _vm.notification.created_at.seconds ? _c('small', {
    staticClass: "thin"
  }, [_c('small', [_vm._v(_vm._s(_vm.$dayjs(_vm.notification.created_at.toDate()).format(_vm.MAIN_DATE)))]), _c('small', {
    staticClass: "color-lightgrey"
  }, [_vm._v("  " + _vm._s(_vm.$dayjs(_vm.notification.created_at.toDate()).format(_vm.MAIN_TIME)))])]) : _vm._e(), _vm.userReceivedMessage(_vm.notification) ? _c('div', {
    staticClass: "inl"
  }, [_c('message-status', {
    attrs: {
      "status": _vm.notification.status
    },
    on: {
      "status-changed": function statusChanged($event) {
        _vm.µ_setStatus(_vm.notification.id, $event);
      }
    }
  })], 1) : _vm._e()])]), _vm.notification.message.length > 150 ? _c('div', [!_vm.isOpen ? _c('button', {
    staticClass: "btn _small _clear color-blue",
    on: {
      "click": function click($event) {
        _vm.isOpen = !_vm.isOpen;
      }
    }
  }, [_vm._v("anzeigen")]) : _vm._e(), _vm.isOpen ? _c('button', {
    staticClass: "btn _small _clear color-blue",
    on: {
      "click": function click($event) {
        _vm.isOpen = !_vm.isOpen;
      }
    }
  }, [_vm._v("verbergen")]) : _vm._e()]) : _vm._e(), _vm.isOpen || _vm.notification.message.length <= 150 ? _c('div', [_vm.notification.type === 'process-transition-annotation' && _vm.notification.sender === _vm._userUid ? _c('button', {
    staticClass: "btn _small change-button",
    on: {
      "click": _vm.onChangeMessage
    }
  }, [_vm._v("Ändern")]) : _vm._e(), _c('div', {
    staticClass: "_textMesssage",
    domProps: {
      "innerHTML": _vm._s(_vm.notification.message)
    }
  })]) : _vm._e(), _vm.messageAttachments.length ? [_c('h5', [_vm._v("Anhänge")]), _c('ul', {
    staticClass: "attachments"
  }, _vm._l(_vm.messageAttachments, function (a, idx) {
    return _c('li', [_c('a', {
      staticClass: "attachment",
      on: {
        "click": function click($event) {
          return _vm.openPreview(a, idx);
        }
      }
    }, [_vm._v(_vm._s(a.name))])]);
  }), 0), _c('CrmFilePreview', {
    attrs: {
      "files": _vm.messageAttachments,
      "id": _vm.id
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };