import "core-js/modules/es.array.join.js";
var consolestyle = {};
consolestyle.greenstyle = [
// 'background: #e55'
'background: #170',
// , 'border: 1px solid #3E0E02'
'color: white', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.lgreenstyle = [
// 'background: #e55'
'background: #5dbb4d',
// , 'border: 1px solid #3E0E02'
'color: white', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.llgreenstyle = [
// 'background: #e55'
'background: #87d978',
// , 'border: 1px solid #3E0E02'
'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.redstyle = [
// "background: #e55",
'background: #f33', 'color: white', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.bluestyle = ['background: #3333FF', 'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.lbluestyle = ['background: #3987EB', 'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.llbluestyle = ['background: #26E2FF', 'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.orangestyle = [
// 'background: #e55'
'background: #ec5834',
// , 'border: 1px solid #3E0E02'
'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.lorangestyle = [
// 'background: #e55'
'background: #e88a43',
// , 'border: 1px solid #3E0E02'
'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.yellowstyle = [
// 'background: #e55'
'background: #e88a43',
// , 'border: 1px solid #3E0E02'
'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.pinkstyle = [
// 'background: #e55'
'background: #b93cd3',
// , 'border: 1px solid #3E0E02'
'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
consolestyle.blackstyle = [
// 'background: #e55'
'background: #111111',
// , 'border: 1px solid #3E0E02'
'color: #fff', 'display: block', 'text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)', 'line-height: 20px', 'border-radius: 12px', 'text-align: center', 'font-weight: bold'].join(';');
// let DEBUG_MODE_ON = true
// import axios from 'axios'
// import $env from "../env";

export default {
  INIT: function INIT(debug) {
    var LOG = {};
    // let LOG = console.log.bind(console, "%c # %c", consolestyle.lorangestyle)

    window.userlogged = '';
    LOG.log = debug ? console.log.bind(console) : function () {};

    // LOG.err = console.error.bind(console, '%c ERROR %c', consolestyle.redstyle, '') // ALWAYS SHOW!
    LOG.err = console.error.bind(console, '%c ERROR %c', consolestyle.redstyle, ''); // ALWAYS SHOW!
    LOG.error = console.error.bind(console, '%c ERROR %c', consolestyle.redstyle, ''); // ALWAYS SHOW!
    LOG.warn = console.warn.bind(console, '%c WARN %c', consolestyle.lorange, ''); // ALWAYS SHOW!

    LOG.green = debug ? console.log.bind(console, '%c # %c', consolestyle.greenstyle, '') : function () {};
    LOG.lgreen = debug ? console.log.bind(console, '%c # %c', consolestyle.lgreenstyle, '') : function () {};
    LOG.llgreen = debug ? console.log.bind(console, '%c # %c', consolestyle.llgreenstyle, '') : function () {};
    LOG.red = debug ? console.log.bind(console, '%c # %c', consolestyle.redstyle, '') : function () {};
    LOG.blue = debug ? console.log.bind(console, '%c # %c', consolestyle.bluestyle, '') : function () {};
    LOG.lblue = debug ? console.log.bind(console, '%c # %c', consolestyle.lbluestyle, '') : function () {};
    LOG.llblue = debug ? console.log.bind(console, '%c # %c', consolestyle.llbluestyle, '') : function () {};
    LOG.yellow = debug ? console.log.bind(console, '%c # %c', consolestyle.yellowstyle, '') : function () {};
    LOG.orange = debug ? console.log.bind(console, '%c # %c', consolestyle.orangestyle, '') : function () {};
    LOG.lorange = debug ? console.log.bind(console, '%c # %c', consolestyle.lorangestyle, '') : function () {};
    LOG.pink = debug ? console.log.bind(console, '%c # %c', consolestyle.pinkstyle, '') : function () {};
    LOG.black = debug ? console.log.bind(console, '%c # %c', consolestyle.blackstyle, '') : function () {};

    // function green(x) {
    //   greenC(x)
    //   window.userlogged += JSON.stringify(x) + '\n'
    // }
    // LOG.green = green
    // var greenC = console.log.bind(console, '%c # %c', consolestyle.greenstyle, '')

    // async function err(x) {
    //   errConsole(x)
    //   window.userlogged += JSON.stringify(x).substring(0, 2500) + '\n'
    //   // let consensetoken = localStorage.getItem("consenseToken");
    //   let consensetoken = 'TODO:pass here consensetoken from store';
    //   let userid = 'TODO:pass here userId from store';
    //   let res = await axios.post($env.mainurl + 'logger', { logs: window.userlogged }, { headers: { userid, consensetoken } })
    //   console.log(res);
    //   window.userlogged = ''

    // }
    // LOG.err = err
    // var errConsole = console.error.bind(console, '%c ERROR %c', consolestyle.redstyle, '') // ALWAYS SHOW!

    window.LOG = LOG;
  }
};