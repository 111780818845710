var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "notificationsTable"
  }, [!_vm.list.length ? _c('span', [_vm._v("keine Nachrichten verfügbar")]) : _c('ul', {
    staticClass: "_allmessages"
  }, _vm._l(_vm.notifications, function (n) {
    return _c('li', {
      key: n.id,
      class: {
        isPending: n.status === 'pending',
        isProcessing: n.status === 'processing',
        isCompleted: n.status === 'completed',
        isNoStatus: !_vm.userReceivedMessage(n)
      }
    }, [n.importantToEstablishContact || n.urgent ? _c('div', {
      staticClass: "important-icons"
    }, [n.importantToEstablishContact ? _c('i', {
      staticClass: "icon-phone-outgoing-exclamation"
    }) : _vm._e(), n.urgent ? _c('i', {
      staticClass: "icon-exclamation"
    }) : _vm._e()]) : _vm._e(), _c('message', {
      attrs: {
        "notification": n
      }
    })], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };