// process.env.VUE_APP_ENV
// Initialize Firebase
import firebase from "firebase";
var config = {};
if (process.env.VUE_APP_ENV === "dev") {
  // NEW DEV
  config = {
    apiKey: "AIzaSyCidKp5dX97gEcIfRlUEwDGdsfYYxP4_no",
    authDomain: "consense-dev.firebaseapp.com",
    databaseURL: "https://consense-dev.firebaseio.com",
    projectId: "consense-dev",
    storageBucket: "consense-dev.appspot.com",
    messagingSenderId: "968604919439",
    appId: "1:968604919439:web:77b80673d7debf3cb0b2fa"
  };
} else if (process.env.VUE_APP_ENV === "live") {
  config = {
    apiKey: "AIzaSyCGp44039oi9bxFA69veUW3sQ94AGLcKno",
    authDomain: "consensewp.firebaseapp.com",
    databaseURL: "https://consensewp.firebaseio.com",
    projectId: "consensewp",
    storageBucket: "consensewp.appspot.com",
    messagingSenderId: "175091140659"
  };
}
export default config;
export var app = firebase.initializeApp(config);