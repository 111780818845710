var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "storage-selector"
  }, [_vm.value == null ? _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": _vm.openDialog
    }
  }, [_vm._v("Speicherort auswählen")]) : [_c('span', [_vm._v(_vm._s(_vm.filepath))]), _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": _vm.openDialog
    }
  }, [_vm._v("Speicherort ändern")])]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };