import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "FileInput"
  }, [_vm._v("#ThisComp"), _c('pre', [_vm._v(_vm._s(_vm.touploadfiles))]), _c('div', {
    staticClass: "file-upload"
  }, [_c('label', {
    staticClass: "file-label",
    attrs: {
      "for": "allianzfilesp"
    }
  }, [_vm.touploadfiles.length == 0 ? _c('span', {
    staticClass: "link"
  }, [_vm._v("Dateien auswählen")]) : _vm._e(), _vm._l(_vm.touploadfiles, function (file) {
    return _c('span', {
      staticClass: "file"
    }, [_vm._v(_vm._s(file.name))]);
  }), _vm.touploadfiles.length > 0 ? _c('span', {
    staticClass: "uploadbtn bold link",
    on: {
      "click": function click($event) {
        return _vm.SendFiles();
      }
    }
  }, [_vm._v("Hochladen")]) : _vm._e()], 2), _c('input', {
    staticClass: "file-input",
    attrs: {
      "type": "file",
      "name": "allianzfilesp",
      "accept": "image/png, image/jpeg, application/pdf",
      "multiple": ""
    },
    on: {
      "change": _vm.addFiles
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };