import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": "Speicherort auswählen"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm.init ? _c('div', {
    staticClass: "loading"
  }, [_c('i', {
    staticClass: "loading-spinner"
  })]) : _c('div', {
    staticClass: "file-navigator"
  }, [_c('div', {
    staticClass: "file-navigator-header"
  }, [_c('div', [!_vm.isTopLevel ? _c('div', {
    staticClass: "link bold",
    on: {
      "click": _vm.goBack
    }
  }, [_c('i', {
    staticClass: "icon-arrow-left"
  }), _vm._v("  Zurück")]) : _vm._e()]), _c('div', [_vm._v(_vm._s(_vm.currentPath))]), _c('div', {
    staticClass: "actions"
  }, [_c('a', {
    staticClass: "btn _small _bordered",
    on: {
      "click": _vm.reloadCurrentFolder
    }
  }, [_vm._v("Neu laden")])])]), !_vm.loadingFolder ? _c('ul', {
    staticClass: "foldercontent folders"
  }, _vm._l(_vm.folders, function (item) {
    return _c('li', {
      staticClass: "file-row _folder",
      on: {
        "click": function click($event) {
          return _vm.openFolder(item);
        }
      }
    }, [_c('div', {
      staticClass: "file-name link"
    }, [_c('i', {
      staticClass: "icon-folder"
    }, [_vm._v(" " + _vm._s(item.name))])])]);
  }), 0) : _c('div', {
    staticClass: "loading"
  }, [_c('i', {
    staticClass: "loading-spinner"
  })]), _c('div', {
    staticClass: "file-name-selector input-field"
  }, [_c('span', [_vm._v("Dateiname:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filename,
      expression: "filename"
    }],
    staticClass: "input",
    domProps: {
      "value": _vm.filename
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.filename = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": _vm.onSaveClicked
    }
  }, [_vm._v("Speichern")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };