import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "notificationsList max960"
  }, [_c('h4', [_vm._v("Nachrichten")]), _c('div', {
    staticClass: "card"
  }, [_c('notifications-table', {
    attrs: {
      "list": _vm.notifications,
      "showUntilsDate": 1
    }
  }), !_vm.isArchiveLoaded ? _c('div', {
    staticClass: "fr"
  }, [_c('button', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        !_vm.isArchiveLoaded && _vm.loadArchiveNotifications();
      }
    }
  }, [_vm._v("Ältere Nachrichten laden")])]) : _vm._e(), _c('div', {
    staticClass: "clear"
  })], 1), _vm.sendToOffice ? _c('h4', [_vm._v("Neue Nachrichten ans Consense Office versenden:")]) : _c('h4', [_vm._v("Neue Nachricht versenden:")]), _c('div', {
    staticClass: "card"
  }, [!_vm.sendToOffice ? _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Empfänger auswählen")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.recipient,
      expression: "recipient"
    }],
    staticClass: "input",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.recipient = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.availableRecipients, function (_ref) {
    var value = _ref.value,
      label = _ref.label;
    return _c('option', {
      domProps: {
        "value": value
      }
    }, [_vm._v(_vm._s(label))]);
  }), 0)]) : _vm._e(), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Nachricht auswählen")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedMessage,
      expression: "selectedMessage"
    }],
    staticClass: "input",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedMessage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    domProps: {
      "value": -1
    }
  }, [_vm._v("...Nachricht auswählen...")]), _vm._l(_vm.preparedMessages, function (m, ix) {
    return _c('option', {
      domProps: {
        "value": ix
      }
    }, [_vm._v(_vm._s(m.subject))]);
  })], 2)]), _vm.preparedMessages[_vm.selectedMessage] && _vm.preparedMessages[_vm.selectedMessage].form ? _c(_vm.formExtensions[_vm.preparedMessages[_vm.selectedMessage].form], {
    tag: "component",
    attrs: {
      "crm-akt": _vm.crmAkt,
      "main-data-changed": _vm.isMainDataChanged,
      "update-text": _vm.onTextUpdated
    },
    on: {
      "update:crmAkt": function updateCrmAkt($event) {
        _vm.crmAkt = $event;
      },
      "update:crm-akt": function updateCrmAkt($event) {
        _vm.crmAkt = $event;
      }
    }
  }) : _vm._e(), _vm.preparedMessages[_vm.selectedMessage] ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Text:")]), _vm.upDateTick ? _c('div', {
    staticClass: "input"
  }, [_c('p', [_vm._v(_vm._s(_vm.preparedMessages[_vm.selectedMessage].hint))]), _c('TipTap', {
    attrs: {
      "content": _vm.theMessage,
      "ckey": 'message'
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm._isAdmin ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "input"
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm._filteredStatusList,
      "reduce": function reduce(val) {
        return parseInt(val.id || '100000000');
      },
      "clearable": false
    },
    on: {
      "input": _vm.statusUpdated
    },
    model: {
      value: _vm.crmAkt.new_status,
      callback: function callback($$v) {
        _vm.$set(_vm.crmAkt, "new_status", $$v);
      },
      expression: "crmAkt.new_status"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "input-field top"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Anhang")]), _c('div', {
    staticClass: "input"
  }, [_c('sharepoint-file-selector', {
    staticClass: "input",
    model: {
      value: _vm.attachments,
      callback: function callback($$v) {
        _vm.attachments = $$v;
      },
      expression: "attachments"
    }
  }), _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('dropzoneNotifications');
      }
    }
  }, [_vm._v("Neue Dateien hochladen")])], 1)]), _vm.theMessage.message.length > 3 && _vm.preparedMessages[_vm.selectedMessage] && !!_vm.recipient ? _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Nachricht versenden:")]), _c('div', {
    staticClass: "input"
  }, [!_vm.savingMessage ? _c('button', {
    staticClass: "btn _mid _bordered",
    on: {
      "click": _vm.sendMessage
    }
  }, [_c('span', [_vm._v("Senden")])]) : _c('span', [_c('i', {
    staticClass: "loading-spinner"
  })])])]) : _vm._e()], 1), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "id": "dropzoneNotifications",
      "name": "dropzoneNotifications",
      "adaptive": true,
      "scrollable": true,
      "reset": true,
      "width": "960",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Dateien Hochladen"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('dropzoneNotifications');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('sharepoint-dropzone', {
    attrs: {
      "directory": "Auftragsunterlagen",
      "commission": _vm.crmAkt
    },
    on: {
      "file-uploaded": _vm.appendAttachment
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };