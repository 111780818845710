var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "opencalendar"
  }, [_c('div', {
    staticClass: "cals"
  }, [_c('h5', [_vm._v("Anstehende Besichtigungen")]), _vm._l(_vm.week, function (wee) {
    return _c('ul', {
      staticClass: "flexweek"
    }, _vm._l(wee, function (w, i) {
      return _c('li', [_c('div', {
        staticClass: "weekhead"
      }, [_vm._v(_vm._s(_vm.$dayjs(i).format('ddd DD. MMM. YYYY')))]), _c('div', {
        staticClass: "weekitem"
      }, [w.length > 0 ? _c('span', {
        staticClass: "link",
        on: {
          "click": function click($event) {
            _vm.CurrentTermineWeek = i;
            _vm.CurrentTermine = w;
            _vm.$modal.show('DashTermine');
          }
        }
      }, [_vm._v(_vm._s(w.length) + " Termine anzeigen")]) : w.length == 0 ? _c('span', [_vm._v("keine Termine")]) : _vm._e()])]);
    }), 0);
  })], 2), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "DashTermine",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "840",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Termine"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('DashTermine');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$dayjs(_vm.CurrentTermineWeek).format('ddd DD. MMM. YYYY')))]), _vm._l([_vm.CurrentTermine], function (w) {
    return _c('div', {
      staticClass: "terminlist"
    }, [_c('table', {
      staticClass: "table"
    }, [_c('thead', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Adresse")]), _c('th', [_vm._v("Uhrzeit")])]), _c('tbody', _vm._l(w, function (termin, e) {
      return _vm.maplist[termin] ? _c('tr', [_c('td', [_c('router-link', {
        attrs: {
          "to": "/crmakt/" + _vm.maplist[termin].aktname
        }
      }, [_vm._v(_vm._s(_vm.maplist[termin].aktname) + " ")])], 1), _c('td', [_vm._v(_vm._s(_vm.maplist[termin].strasseba) + " - " + _vm._s(_vm.maplist[termin].ortba))]), _c('td', [_vm._v(_vm._s(_vm.$dayjs(_vm.maplist[termin].besdatum).format("HH:mm")) + " ")])]) : _vm._e();
    }), 0)])]);
  })], 2), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "tar"
  }, [_c('div', {
    staticClass: "btn _m _green",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('DashTermine');
      }
    }
  }, [_vm._v("Schließen")])])])]), _c('gmap-map', {
    ref: "themap",
    attrs: {
      "id": "DASHmap",
      "center": _vm.maincenter,
      "zoom": _vm.zoom,
      "options": {
        styles: ''
      }
    }
  }, _vm._l(_vm.week, function (wee) {
    return _c('span', _vm._l(wee, function (w) {
      return _c('span', _vm._l(w, function (akt) {
        return _c('gmap-marker', {
          key: akt + "mp",
          attrs: {
            "icon": {
              url: _vm.$root.img.icon15,
              size: {
                width: 20,
                height: 30,
                f: 'px',
                b: 'px'
              },
              scaledSize: {
                width: 20,
                height: 30,
                f: 'px',
                b: 'px'
              }
            },
            "position": _vm.maplist[akt].loc,
            "draggable": false
          },
          on: {
            "mouseover": function mouseover($event) {},
            "mouseout": function mouseout($event) {}
          }
        });
      }), 1);
    }), 0);
  }), 0)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };