var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('header', {
    staticClass: "navigation",
    class: {
      _devcolor: _vm.isDev
    }
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": _vm.$root.img.logo
    }
  }), _c('div', {
    staticClass: "quicksearch"
  }, [_vm.isFb ? [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentSearch,
      expression: "currentSearch"
    }],
    staticClass: "searchinput",
    attrs: {
      "type": "text",
      "placeholder": "Filter..."
    },
    domProps: {
      "value": _vm.currentSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.currentSearch = $event.target.value;
      }
    }
  })] : _vm._e(), _vm.currentSearch != '' ? _c('span', {
    staticClass: "closerSearch",
    on: {
      "click": function click($event) {
        _vm.currentSearch = '';
      }
    }
  }, [_c('i', {
    staticClass: "icon-close"
  })]) : _vm._e()], 2), _c('div', {
    staticClass: "menu"
  }, [_vm.isFb || _vm.isCrm ? _c('div', {
    staticClass: "head-link"
  }, [_c('router-link', {
    staticClass: "no-underline",
    attrs: {
      "to": "/feedback"
    }
  }, [_c('div', {
    staticClass: "tac link bold color-white"
  }, [_c('i', {
    staticClass: "icon-feedback _large"
  }), _c('br'), _c('small', {
    staticClass: "condensed thin"
  }, [_vm._v("Feedback")])])])], 1) : _vm._e(), _vm.isFb || _vm.isCrm ? _c('div', {
    staticClass: "head-link"
  }, [_vm.fbDataLoading || _vm.crmDataLoading ? _c('div', {
    staticClass: "loading-spinner tac"
  }) : _c('div', {
    staticClass: "tac link color-white",
    on: {
      "click": function click($event) {
        return _vm._combinedApiCalls(true);
      }
    }
  }, [_c('i', {
    staticClass: "icon-refresh _large"
  }), _c('br'), _c('small', {
    staticClass: "condensed thin"
  }, [_vm._v("Neu laden")])])]) : _vm._e(), _vm.isFb || _vm.isCrm ? _c('div', {
    staticClass: "head-link"
  }, [_c('div', {
    staticClass: "tac link bold color-red",
    on: {
      "click": function click($event) {
        return _vm.$store.dispatch('_logOut');
      }
    }
  }, [_c('i', {
    staticClass: "icon-logout _large"
  }), _c('br'), _c('small', {
    staticClass: "condensed thin"
  }, [_vm._v("Abmelden")])])]) : _c('div', {
    staticClass: "head-link"
  }, [_c('router-link', {
    staticClass: "no-underline",
    attrs: {
      "to": "/login"
    }
  }, [_c('div', {
    staticClass: "tac link color-red"
  }, [_c('i', {
    staticClass: "icon-login _large"
  }), _c('br'), _c('small', {
    staticClass: "condensed thin"
  }, [_vm._v("Anmelden")])])])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };