import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var HN_SACHVERHALTSERMITTLUNG = 100000017;
var HN_FAHRKOSTEN = 100000006;
var HN_REISEZEIT = 100000037; // = hn_fahrzeiten
var HN_BERICHTERSTELLUNG = 100000033;
var HN_BUERO = 100000034;
var HN_FREMDKOSTEN = 100000036;
var HN_BEARBEITUNGSPAUSCHALE = 100000032;
var HN_REISE_SACH_GA = 100000015; //! NOT EQUAL WITH TATIGKEIT
var HN_SACH_GA = 100000018;
var HN_SONSTIGE_KOSTEN = 100000039;
var allConstants = {
  DATE_CONDENSED: 'DD.MMM.YYYY',
  MAIN_DATE_FORMAT: 'DD.MMM YYYY ddd, HH:mm',
  MAIN_DATE_FORMAT_CONDENSED: 'DD.MMM YY HH:mm',
  MAIN_TIME: 'HH:mm',
  MAIN_DATE: 'DD.MMM YY',
  MAIN_OUTPUT_DATE_FORMAT: 'YYYY-MM-DD - HH:mm',
  MAIN_DATE_FORMAT_FULL: 'DD.MMM YYYY ddd, HH:mm:ss',
  MAIN_ONLY_DATE_FORMAT: 'DD.MMM YYYY ddd',
  MAIN_PICK_FORMAT: 'YYYY-MM-DD',
  // HNFAHRZEITEN: 100000007,
  FAHRZEITEN: 100000013,
  FAHRKOSTEN: 100000018,
  EINHEITSTUNDEN: 100000000,
  EINHEITKM: 100000001,
  EINHEITPAUSCHAL: 100000002,
  EINHEITPROMILLE: 100000003,
  HNPAUSCHAL: 100000015,
  PAUSCHAL: 100000017,
  SEKRETARIAT: 100000019,
  HN_SACHVERHALTSERMITTLUNG: HN_SACHVERHALTSERMITTLUNG,
  HN_FAHRKOSTEN: HN_FAHRKOSTEN,
  HN_REISEZEIT: HN_REISEZEIT,
  HN_BERICHTERSTELLUNG: HN_BERICHTERSTELLUNG,
  HN_BUERO: HN_BUERO,
  HN_FREMDKOSTEN: HN_FREMDKOSTEN,
  HN_BEARBEITUNGSPAUSCHALE: HN_BEARBEITUNGSPAUSCHALE,
  HN_REISE_SACH_GA: HN_REISE_SACH_GA,
  HN_SACH_GA: HN_SACH_GA,
  HN_SONSTIGE_KOSTEN: HN_SONSTIGE_KOSTEN,
  CONSENSE_MITARBEITER_ID: '84dfc743-7772-e511-812c-c4346bac6e90',
  TAT_HN_TRANS: {
    // "100000000": "Durchsicht Unterlagen", // "Durchsicht Unterlagen",
    "100000001": HN_SACHVERHALTSERMITTLUNG,
    // "100000002allConstants.": "Besichtigung mit VN", // "Besichtigung mit VN",
    "100000003": HN_SACHVERHALTSERMITTLUNG,
    "100000004": HN_SACHVERHALTSERMITTLUNG,
    "100000005": HN_BERICHTERSTELLUNG,
    "100000006": HN_SACHVERHALTSERMITTLUNG,
    "100000007": HN_SACHVERHALTSERMITTLUNG,
    "100000008": HN_SACHVERHALTSERMITTLUNG,
    "100000009": HN_BERICHTERSTELLUNG,
    "100000010": HN_SACHVERHALTSERMITTLUNG,
    "100000011": HN_SACHVERHALTSERMITTLUNG,
    "100000012": HN_SACHVERHALTSERMITTLUNG,
    "100000013": HN_REISEZEIT,
    "100000014": HN_FREMDKOSTEN,
    "100000015": HN_SACHVERHALTSERMITTLUNG,
    "100000016": HN_BERICHTERSTELLUNG,
    // "100000017": "Reise Sachverhalt Gutachtenausarbeitung", // "Reise Sachverhalt Gutachtenausarbeitung",
    "100000018": HN_FAHRKOSTEN // "Fahrtkosten"
  },
  IS_KM: function IS_KM(HN) {
    return UNIT_FILTER_HN(HN) === allConstants.EINHEITKM;
  },
  IS_PAUSCHAL: function IS_PAUSCHAL(HN) {
    return UNIT_FILTER_HN(HN) === allConstants.EINHEITPAUSCHAL;
  },
  IS_STUNDEN: function IS_STUNDEN(HN) {
    return UNIT_FILTER_HN(HN) === allConstants.EINHEITSTUNDEN;
  }
};
var UNIT_FILTER_HN = function UNIT_FILTER_HN(HN) {
  var isPauschal = [allConstants.HN_REISE_SACH_GA + '', allConstants.HN_FREMDKOSTEN + '', allConstants.HN_BEARBEITUNGSPAUSCHALE + ''].includes(HN + "");
  var isKm = [allConstants.HN_FAHRKOSTEN + ''].includes(HN + "");
  if (isPauschal) return allConstants.EINHEITPAUSCHAL;else if (isKm) return allConstants.EINHEITKM;else return allConstants.EINHEITSTUNDEN;
};
export default allConstants;