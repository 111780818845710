import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import * as XLSX from "xlsx";
import store from "@/store/store";
var exportXls = function exportXls(list) {
  var options = {
    insuranceLineOpt: store.state.sparten.sparten,
    insuranceLineDetailsOpt: store.state.sparten.details,
    damageKindOpt: store.state.sparten.art,
    kindOpt: store.state.arten
  };
  var _iterator = _createForOfIteratorHelper(list),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var el = _step.value;
      for (var key in el) {
        if (options[key]) el[key] = options[key];
        if (deletor[key]) {
          delete el[key];
        }
        if (translator[key]) {
          el[translator[key]] = el[key];
          delete el[key];
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  console.log(list);
  var fileName = "Consense Akte.xlsx";
  var ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(list);
  var wb = XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Consense Akte");
  XLSX.writeFile(wb, fileName);
};
export default exportXls;
var deletor = {
  assignedAppraiserContactId: "assignedAppraiserContactId",
  assignedUsers: "assignedUsers",
  consenseNr: "consenseNr",
  importantToEstablishContact: "importantToEstablishContact",
  insurantId: "insurantNameId",
  insurantAddress: "AG Adresse",
  insureeAddress: "VN Adresse",
  insureeId: "insureeId",
  isFaulty: "isFaulty",
  loc: "loc",
  refereeId: "refereeId",
  sharedUsers: "sharedUsers",
  statusOpt: "statusOpt",
  title: "title",
  visitAddress: "Besichtigung",
  visitEntityId: "visitEntityId",
  _id: "_id"
};
var translator = {
  assignedAppraiserName: "Mitarbeiter",
  commissionNr: "Akt",
  completionDate: "erledigt am",
  customName: "Bezeichnung",
  damageDate: "Schadendatum",
  damageNumber: "Schadennummer",
  firstContactDate: "Erstkontakt Datum",
  infoversDate: "InfoVers Datum",
  insuranceLineDetailsOpt: "Sparte",
  insuranceLineOpt: "insuranceLineOpt",
  damageKindOpt: "Schadenart",
  kindOpt: "Art",
  insurancePolicyNumber: "Polizzennummer",
  insurantName: "AG Name",
  insureeCity: "VN Ort",
  insureeCountry: "VN Land",
  insureeName: "VN Name",
  insureePostal: "VN PLZ",
  insureeStreet: "VN Straße",
  insureeTel: "VN Tel",
  marker: "marker",
  modifiedon: "geändert",
  orderDate: "Auftragsdatum",
  orderNumber: "Auftragsnummer",
  refereeEmail: "Ref. Email",
  refereeName: "Ref. Name",
  status: "Status",
  touched: "gesehen",
  urgency: "Priorität",
  urgent: "drinend Kontakt erforderlich",
  visitCity: "Besichtigung Ort",
  visitCountry: "Besichtigung Land",
  visitDate: "Besichtigungsdatum",
  visitPostal: "Besichtigung PLZ",
  visitStreet: "Besichtigung Straße",
  vorinfoDate: "VorInfo Datum"
};