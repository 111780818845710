import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "image-chooser modal"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Dateien auswählen"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _vm.content.data ? _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "scard smin420"
  }, [_c('div', {
    staticClass: "clear"
  }), !_vm.isErrorOnLoad ? _c('div', {
    staticClass: "folderContent"
  }, [_c('ul', {
    staticClass: "foldercontent folderCont"
  }, [_c('li', {
    staticClass: "folderCont__topline"
  }, [_c('div', [!_vm.isInRoot ? _c('span', [_c('div', {
    staticClass: "link bold",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.fetchSpFolderContent(_vm.folderArtAkt, '');
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-left"
  }), _vm._v("  Zurück")])]) : _vm._e()]), _c('div', [_vm.preloaderforfile ? _c('span', [_c('div', {
    staticClass: "loading-spinner inline"
  })]) : _vm._e(), !_vm.isInRoot ? _c('span', [_vm._v("  " + _vm._s(_vm.fullAktFolderName.substring(0, 10)) + _vm._s(_vm.subUrlPaths))]) : _c('span', [_vm._v("  " + _vm._s(_vm.fullAktFolderName.substring(0, 10)))])]), _c('div', [_c('a', {
    staticClass: "btn _small _bordered",
    on: {
      "click": function click($event) {
        return _vm.fetchSpFolderContent('', _vm.currentDriveId);
      }
    }
  }, [_vm._v("Neu laden")]), _c('v-popover', {
    staticClass: "inl",
    attrs: {
      "offset": "3",
      "placement": "bottom"
    }
  }, [_c('a', {
    staticClass: "btn _small _bordered tooltip-target"
  }, [_vm._v("Sortieren")]), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList"
  }, [_c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'created' && _vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'created';
        _vm.reverse = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-top"
  }), _c('span', [_vm._v("  erstellt am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'created' && !_vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'created';
        _vm.reverse = false;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('span', [_vm._v("  erstellt am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'modified' && _vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'modified';
        _vm.reverse = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-top"
  }), _c('span', [_vm._v("  geändert am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'modified' && !_vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'modified';
        _vm.reverse = false;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('span', [_vm._v("  geändert am")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'name' && _vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'name';
        _vm.reverse = false;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-top"
  }), _c('span', [_vm._v("  Name")])])]), _c('li', [_c('a', {
    staticClass: "chooseList_a",
    class: {
      'color-blue': _vm.sortBy == 'name' && !_vm.reverse
    },
    on: {
      "click": function click($event) {
        _vm.sortBy = 'name';
        _vm.reverse = true;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-down"
  }), _c('span', [_vm._v("  Name")])])])])])], 2)], 1)])]), _c('div', {
    staticClass: "clear"
  }), _c('ul', {
    staticClass: "foldercontent"
  }, _vm._l(_vm.folders, function (item, ind) {
    return _c('li', {
      staticClass: "file-row _folder",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          _vm.setUrl(item);
          _vm.fetchSpFolderContent('', item.id, item.name);
        }
      }
    }, [_c('div', {
      staticClass: "file-name link"
    }, [_c('i', {
      staticClass: "icon-folder"
    }), _c('span', [_vm._v(" " + _vm._s(item.name))]), !_vm.filetypeFilter.length ? _c('span', [_vm._v(" (" + _vm._s((item.folder || {}).childCount || '0') + ")")]) : _vm._e()])]);
  }), 0), _c('ul', {
    staticClass: "foldercontent file-flex"
  }, [_vm.maxImages < _vm.content.data.length ? _c('li', {
    staticClass: "fullwidth"
  }, [_c('div', {
    staticClass: "tac color-red bold padding"
  }, [_vm._v(" An dieser Stelle nur maximal " + _vm._s(_vm.maxImages) + " Bilder auswählen."), _c('br'), _vm._v("Mehr Bilder werden im Gutachten nicht angezeigt.")])]) : _vm._e(), _vm._l(_vm.sortedFiles, function (item, ind) {
    return _vm.onlyFormat.length == 0 || _vm.onlyFormat.includes(_vm.$_extension(item.name)) ? _c('li', {
      staticClass: "file-row files",
      class: {
        _global: (_vm.blockedImages || []).includes(item.id),
        _sel: _vm.idArr.includes(item.id)
      }
    }, [_c('label', [_c('div', {
      staticClass: "abs"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.content.data,
        expression: "content.data"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": {
          id: item.id,
          path: _vm.subUrlPaths + '/' + item.name,
          caption: _vm.includeNameInCaption ? item.name : ''
        },
        "checked": Array.isArray(_vm.content.data) ? _vm._i(_vm.content.data, {
          id: item.id,
          path: _vm.subUrlPaths + '/' + item.name,
          caption: _vm.includeNameInCaption ? item.name : ''
        }) > -1 : _vm.content.data
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.content.data,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = {
                id: item.id,
                path: _vm.subUrlPaths + '/' + item.name,
                caption: _vm.includeNameInCaption ? item.name : ''
              },
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.content, "data", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.content, "data", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.content, "data", $$c);
          }
        }
      }
    })]), _c('div', {
      staticClass: "tac"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: _vm.makeImgDiv(item.id),
          placement: 'right-end',
          offset: 10,
          delay: {
            show: 300,
            hide: 30
          }
        },
        expression: "{ content: makeImgDiv(item.id), placement: 'right-end', offset: 10, delay: { show: 300, hide: 30 } }"
      }],
      staticClass: "thumbnail"
    }, [_c('div', {
      staticClass: "img",
      style: 'background-image:url(' + _vm.$_makeImgUrl(item.id) + ')',
      attrs: {
        "width": "128px",
        "height": "128px"
      }
    })]), _c('hr'), _c('div', {
      staticClass: "file-name word-break-all"
    }, [_c('small', [_vm._v(_vm._s(item.name))])])])])]) : _vm._e();
  })], 2)]) : _vm._e()]), _c('div', {
    staticClass: "border-around"
  }, [_c('div', [!_vm.content.$dontShowCaption ? _c('label', {
    staticClass: "input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.includeNameInCaption,
      expression: "includeNameInCaption"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.includeNameInCaption) ? _vm._i(_vm.includeNameInCaption, null) > -1 : _vm.includeNameInCaption
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.includeNameInCaption,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.includeNameInCaption = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.includeNameInCaption = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.includeNameInCaption = $$c;
        }
      }
    }
  }), _vm._v("  Dateiname in Untertitel übernehmen")]) : _vm._e()]), _vm._m(0)])]) : _c('div', [_vm._v("no content?")]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.acceptImages();
      }
    }
  }, [_vm._v("Auswählen")])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('div', {
    staticClass: "file-row inl _sel"
  }, [_vm._v("bereits ausgewählt in dieser Section")]), _c('div', {
    staticClass: "file-row inl _global"
  }, [_vm._v("bereits an andere Stelle im Gutachten vorhanden ")])]);
}];
export { render, staticRenderFns };