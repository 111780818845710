var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "v-date"
  }, [_c('DatePick', {
    attrs: {
      "format": _vm.MAIN_OUTPUT_DATE_FORMAT,
      "displayFormat": _vm.MAIN_OUTPUT_DATE_FORMAT,
      "pickTime": true,
      "hasInputElement": true
    },
    model: {
      value: _vm.currentValue,
      callback: function callback($$v) {
        _vm.currentValue = $$v;
      },
      expression: "currentValue"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };