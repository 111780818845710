import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
/* eslint-disable no-console */
import { register } from "register-service-worker";
var listeners = [];
window.updateAvailable = false;
window.newVersionAvailable = function (_v) {
  listeners.forEach(function (cb) {
    return cb();
  });
};
if (process.env.NODE_ENV === "production") {
  register("".concat(process.env.BASE_URL, "service-worker.js"), {
    ready: function ready(registration) {
      console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
      listeners.push(function () {
        return registration.update().catch(console.error);
      });
    },
    registered: function registered() {
      console.log("Service worker has been registered.");
    },
    cached: function cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound: function updatefound() {
      console.log("New content is downloading.");
    },
    updated: function updated() {
      console.log("New content is available; please refresh.");
      window.updateAvailable = true;
    },
    offline: function offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error: function error(_error) {
      console.error("Error during service worker registration:", _error);
    }
  });
}