import "/Users/weber/projects/CONSENSE/app/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/weber/projects/CONSENSE/app/node_modules/core-js/modules/es.promise.js";
import "/Users/weber/projects/CONSENSE/app/node_modules/core-js/modules/es.object.assign.js";
import "/Users/weber/projects/CONSENSE/app/node_modules/core-js/modules/es.promise.finally.js";
/* eslint-disable */
import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import { app as firebaseApp } from './config/firebaseConfig';
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import features from '@/libs/features';
import "./registerServiceWorker";
import "vue-awesome/icons";
// @ts-ignore
import TheNavigation from "@/components/TheNavigation.vue";
// @ts-ignore
import TheSidebar from "@/components/TheSidebar.vue";
// import LogRocket from 'logrocket';
import FilterSideBar from "./components/FilterSideBar.vue";
import FilterMap from "./components/FilterMap.vue";
import mixins from "./libs/mixins";
import allConstants from "./libs/allConstants";
import VInputFile from "./components/VInputFile.vue";
import VPagination from "./components/VPagination.vue";
import VCheckbox from "./components/VCheckbox.vue";
import VRadioCheck from "./components/VRadioCheck.vue";
import VDate from "./components/VDate.vue";
import OfflineImageStore from "./components/OfflineImageStore.vue";
import VTableHead from "./components/VTableHead.vue";
import CrmDate from "@/components/ConCrmDate.vue";
import VTabs from "@/components/VTabs.vue";
import CrmDateNative from "@/components/CrmDateNative.vue";
import TipTap from "@/components/TipTap.vue";
// because of typesript error, this module is locally
// import VTooltip from "v-tooltip";
// @ts-ignore
import VTooltip from "@/libs/tooltip.umd.js";
import debounce from "v-debounce";
import * as VueGoogleMaps from "vue2-google-maps";
import _ from "lodash";
import dayjs from "dayjs";
import LocalizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/de'; // load on demand
import ToggleButton from "vue-js-toggle-button";
import Notifications from "vue-notification";
import VModal from "vue-js-modal";
import VueScrollTo from "vue-scrollto";
import 'vue-select/dist/vue-select.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
// CRM COMPONENTS:
import CrmOpenProjects from "@/components/Crm/CrmOpenProjects.vue";
import CommissionList from "@/components/Crm/CommissionList.vue";
import OpenCalendar from "@/components/Crm/OpenCalendar.vue";
import NotificationButton from "@/components/NotificationButton.vue";
import NotificationsList from "@/components/NotificationsList.vue";
import NotificationsTable from "@/components/NotificationsTable.vue";
import NotificationsModal from "@/components/NotificationsModal.vue";
// ALLIANZ COMPONENTS:
import AllianzAddNewProject from "@/components/Allianz/AllianzAddNewProject.vue";
import localforage from 'localforage';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
// @ts-ignore
import popover from 'vue-popover';
import "vue-popover/dist/styles.css";
import env from "./env";
import './assets/icons/rendered/icons.css';
import "./design/index.styl";
import VueTextareaAutosize from "vue-textarea-autosize";
import InputField from "@/components/Form/InputField.vue";
import './components';
var _require = require("deep-object-diff"),
  detailedDiff = _require.detailedDiff;
// REGISTER LOCAL FORAGE FOR IMAGES
var imageStore = localforage.createInstance({
  name: "imageStore"
});
var FastClick = require('fastclick');
FastClick.attach(document.body, {});
// window.dayjs = dayjs;
Vue.directive("debounce", debounce);
Vue.use(ToggleButton);
Vue.use(mixins);
Vue.use(VTooltip);
Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true
});
// Vue.use(VModal, { dialog: true ,dynamic: true, injectModalsContainer: true })
Vue.use(Notifications);
Vue.use(VueScrollTo);
Vue.use(VueTextareaAutosize);
Vue.use(VueGoogleMaps, require("./config/googleMapsConfig.ts").default);
// Vue.use(VCheckbox);
// Vue.use(VueWorker)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('DatePick', DatePick);
Vue.component("CrmOpenProjects", CrmOpenProjects);
Vue.component("CommissionList", CommissionList);
Vue.component("OpenCalendar", OpenCalendar);
Vue.component("AllianzAddNewProject", AllianzAddNewProject);
Vue.component("v-table-head", VTableHead);
Vue.component("the-navigation", TheNavigation);
Vue.component("the-sidebar", TheSidebar);
Vue.component("v-input-file", VInputFile);
Vue.component("v-pagination", VPagination);
Vue.component("v-checkbox", VCheckbox);
Vue.component("v-radiocheck", VRadioCheck);
Vue.component("v-date", VDate);
Vue.component("OfflineImageStore", OfflineImageStore);
Vue.component("FilterSideBar", FilterSideBar);
Vue.component("FilterMap", FilterMap);
Vue.component('popover', popover);
Vue.component('crmDate', CrmDate);
Vue.component('v-tabs', VTabs);
Vue.component('crm-date-native', CrmDateNative);
Vue.component('notification-button', NotificationButton);
Vue.component('NotificationsList', NotificationsList);
Vue.component('NotificationsTable', NotificationsTable);
Vue.component('NotificationsModal', NotificationsModal);
Vue.component('TipTap', TipTap);
Vue.component('input-field', InputField);
dayjs.extend(LocalizedFormatPlugin);
Object.assign(Vue.prototype, allConstants);
Vue.prototype.$diff = detailedDiff;
Vue.prototype.$imageStore = imageStore;
Vue.prototype.$features = features;
Vue.prototype.$_ = _;
dayjs.locale('de');
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$fb = firebase;
// Vue.prototype.env = env;
Vue.prototype.$env = env;
Vue.prototype.$db = firebaseApp.firestore();
Vue.prototype.$firebase = firebase;
if (process.env.VUE_APP_ENV === "live") {
  Vue.config.productionTip = false;
  Vue.config.silent = true;
}
firebase.auth().onAuthStateChanged(function (user) {
  store.dispatch('_onFirebaseIserAuthStateChange', user);
});
// store.commit("set", [userDataFb]false);
store.commit('reset', 'userLogin');
var app;
if (!app) {
  app = new Vue({
    router: router,
    store: store,
    render: function render(h) {
      return h(App);
    }
  }).$mount("#app");
}