import _typeof from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/typeof.js";
export default {
  name: "VCheckbox",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    rctype: {
      type: String,
      required: false,
      default: "checkbox"
    },
    value: {
      type: [Boolean, Array, String],
      required: true,
      default: false
    },
    item: {
      // FOR ARRAYS AND RADIOS
      type: [String, Array],
      required: false,
      default: "-"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // TODO: since this element is almost exclusively used as a block element, we should inverse this property
    block: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    value: function value(_value) {
      // console.log(value);
      if (this.isBool) this.toggled = !!_value;
      if (this.isArray) this.currentValue = _value;
      if (this.isString) this.currentValueRadio = _value;
    }
  },
  data: function data() {
    return {
      toggled: !!this.value,
      currentValue: this.value,
      currentValueRadio: this.value
    };
  },
  computed: {
    isBool: function isBool() {
      return typeof this.value === "boolean";
    },
    isArray: function isArray() {
      return _typeof(this.value) === "object";
    },
    isString: function isString() {
      return this.value === "" || typeof this.value === "string";
    }
  },
  methods: {
    updateValue: function updateValue(event) {
      this.$emit("input", this.currentValue);
    },
    updateValueRadio: function updateValueRadio(event) {
      // console.log(this.currentValueRadio);
      this.$emit("input", this.currentValueRadio + "");
    },
    toggle: function toggle(event) {
      var toggled = !this.toggled;
      this.$emit("input", toggled);
      // this.$emit("change", {
      //   value: toggled,
      //   srcEvent: event
      // });
    }
  }
};