import _defineProperty from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "v-checkbox",
    class: {
      'block-element': _vm.block
    }
  }, [_c('label', {
    class: _vm.rctype
  }, [_vm.rctype === 'radio' && !_vm.label && !_vm.$slots.default ? _c('div', {
    staticClass: "inl"
  }, [_vm._v(_vm._s(_vm.item))]) : _c('div', {
    staticClass: "inl"
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2), _vm.rctype === 'checkbox' && _vm.isBool ? _c('input', {
    staticClass: "input",
    attrs: {
      "type": "checkbox",
      "disabled": _vm.disabled
    },
    domProps: {
      "checked": _vm.value
    },
    on: {
      "change": function change($event) {
        $event.stopPropagation();
        return _vm.toggle.apply(null, arguments);
      }
    }
  }) : _vm.rctype === 'checkbox' && _vm.isArray ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "input",
    attrs: {
      "type": "checkbox",
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.item,
      "checked": Array.isArray(_vm.currentValue) ? _vm._i(_vm.currentValue, _vm.item) > -1 : _vm.currentValue
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.currentValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = _vm.item,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.currentValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.currentValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.currentValue = $$c;
        }
      }, function ($event) {
        $event.stopPropagation();
        return _vm.updateValue.apply(null, arguments);
      }]
    }
  }) : _vm.rctype === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValueRadio,
      expression: "currentValueRadio"
    }],
    staticClass: "input",
    attrs: {
      "type": "radio",
      "disabled": _vm.disabled
    },
    domProps: _defineProperty({
      "value": _vm.item,
      "checked": _vm.currentValueRadio == _vm.item
    }, "checked", _vm._q(_vm.currentValueRadio, _vm.item)),
    on: {
      "change": [function ($event) {
        _vm.currentValueRadio = _vm.item;
      }, function ($event) {
        $event.stopPropagation();
        return _vm.updateValueRadio.apply(null, arguments);
      }]
    }
  }) : _vm._e(), _c('div', {
    staticClass: "checkmark"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };