var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('compact-picker', {
    staticClass: "color-picker-popout",
    attrs: {
      "value": {
        hex8: _vm.color
      },
      "palette": _vm.palette
    },
    on: {
      "input": _vm.onColorChanged
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };