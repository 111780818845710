var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Datum")]), _c('div', {
    staticClass: "input"
  }, [_c('crm-date-native', {
    model: {
      value: _vm.synced.new_besichtigungsdatum,
      callback: function callback($$v) {
        _vm.$set(_vm.synced, "new_besichtigungsdatum", $$v);
      },
      expression: "synced.new_besichtigungsdatum"
    }
  }), _vm.mainDataChanged ? _c('small', {
    staticClass: "color-red"
  }, [_vm._v("  Bitte Speichern nicht vergessen!")]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };