export default {
  name: "VTableHead",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    sortby: {
      type: String,
      required: false,
      default: ""
    },
    sortvalue: {
      type: String,
      required: false,
      default: ""
    },
    reverse: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    setSorter: function setSorter() {
      this.$emit("change", [this.sortvalue, !this.reverse]);
    }
  }
};