import "core-js/modules/es.array.concat.js";
import { aktStatus, hntexte, tatigkeit } from "@consense/crm";
import { chain } from "lodash";
var crmstuff = {
  svfactorc: {
    "100000001": 4,
    "100000002": 4,
    "100000014": 4,
    "100000010": 3,
    "100000003": 4,
    "100000012": 3,
    "100000004": 2,
    "100000011": 3,
    "100000013": 1,
    "100000015": 4,
    "100000017": 1,
    "100000023": 1,
    "100000024": 4,
    "100000025": 2,
    "100000026": 2
  },
  svfactor: {
    "100000000": 0,
    //
    "100000001": 4,
    "100000002": 4,
    "100000014": 4,
    "100000010": 3,
    "100000003": 4,
    "100000012": 3,
    "100000020": 0,
    //
    "100000021": 0,
    //
    "100000004": 2,
    "100000005": 0,
    ////
    "100000006": 0,
    ////
    "100000007": 0,
    ////
    "100000008": 0,
    ////
    "100000009": 0,
    ////
    "100000011": 3,
    "100000013": 1,
    "100000015": 4,
    "100000016": 0,
    //
    "100000017": 1,
    "100000018": 0,
    ////
    "100000019": 0,
    ////
    "100000022": 0,
    ////
    "100000023": 1,
    "100000024": 4,
    "100000025": 2,
    "100000026": 2,
    "100000027": 0,
    "100000028": 0 //
  },
  einheiten: {
    "100000000": "Stunden",
    "100000001": "Kilometer",
    "100000002": "Pauschale",
    "100000003": "Promillsatz"
  },
  tatigkeit: tatigkeit,
  sparten: {
    sparten: {
      "100000000": "Sachversicherung",
      "100000001": "Haftpflichtversicherung",
      "100000002": "Vermögensschaden",
      "100000003": "Transport",
      "100000004": "Bewertung"
    },
    details: {
      "100000000": "Bauherrenhaftpflicht",
      "100000001": "Betriebshaftpflicht",
      "100000002": "Betriebsstillstand",
      "100000003": "BUFT",
      "100000004": "E/D",
      "100000005": "Elektrogeräte/EDV",
      "100000006": "Elementar",
      "100000007": "Fahrzeuge/Verkehrsmittel",
      "100000008": "Feuer",
      "100000009": "Glasbruch",
      "100000010": "KFZ-Haftpflicht",
      "100000011": "Landtransport",
      "100000012": "Leitungswasser",
      "100000013": "Maschinenbruch",
      "100000014": "Montage",
      "100000015": "private Haftpflicht/Haus Grundbesitz",
      "100000016": "Produkthaftpflicht",
      "100000017": "Seetransport",
      "100000018": "Umwelthaftpflicht",
      "100000019": "Gebäudebewertung",
      "100000020": "TKB-Bewertung",
      "100000021": "Gebäude- und TKB-Bewertung",
      "100000022": "Verkehrswert-/Liegenschaftsbewertung",
      "100000023": "Risikoanalyse",
      "100000024": "Inhaltsbewertung",
      "100000025": "Kühlgut",
      "100000026": "Bauwesen"
    },
    art: {
      "100000000": "Boote",
      "100000001": "ED/Vandalismus",
      "100000002": "Elementar",
      "100000003": "EU",
      "100000004": "Fehlbedienung",
      "100000005": "Feuer",
      "100000006": "Gebäude",
      "100000007": "Gebäude & BU",
      "100000008": "Gebäude & Inhalt",
      "100000009": "Gebäude & Inhalt & BU",
      "100000010": "indirekter Blitzschlag",
      "100000011": "Inhalt",
      "100000012": "Inhalt & BU",
      "100000013": "Inland",
      "100000014": "KFZ",
      "100000015": "Leitungswasser",
      "100000016": "Luftfahrzeuge",
      "100000017": "Mechanische Schäden",
      "100000018": "Sachschaden",
      "100000019": "Sachschaden & Vermögensschaden",
      "100000020": "Schienenfahrzeuge",
      "100000021": "Sonstiges",
      "100000022": "Überspannung",
      "100000023": "Verkehrsleiteinrichtung",
      "100000024": "Vermögensschaden",
      "100000025": "Weltweit",
      "100000026": "/",
      "100000027": "Haftpflicht",
      "100000028": "Inhalt/TKB",
      "100000029": "Einfamilienhaus/Wohnung",
      "100000030": "Mehrfamilienhaus",
      "100000031": "Gemeindeobjekte",
      "100000032": "Gewerbebetriebe",
      "100000033": "Industriebetrieb",
      "100000034": "Landwirtschaftsbetrieb",
      "100000035": "Sonderobjekte",
      "100000036": "Kleinunternehmen",
      "100000037": "Mittelbetrieb",
      "100000038": "Industrie",
      "100000039": "Gemeinden",
      "100000040": "Wertsachen/Kunstobjekte",
      "100000041": "Bürogebäude",
      "100000042": "Hochwasser",
      "100000043": "Hagel",
      "100000044": "Sturm",
      "100000045": "Bau-/Planungsfehler",
      "100000046": "Glasbruch",
      "100000047": "Asphaltschaden",
      "100000048": "Verkehrsleiteinrichtung & Asphaltschaden"
    }
  },
  arten: {
    "100000000": "Auftrag",
    "100000001": "Angebot",
    "100000002": "Unwetterschäden",
    "100000003": "verdächtiger Auftrag",
    "100000004": "Generali",
    "100000005": "Beweissicherung",
    "100000006": "Risikoanalyse",
    "100000007": "Allianz",
    "100000008": "Tour",
    "100000009": "Kärnten"
  },
  status: chain(aktStatus).keyBy('id').mapValues(function (x) {
    return "".concat(x.nr, " - ").concat(x.label);
  }).value(),
  hntexte: hntexte,
  ereignisse: {
    "100000000": "keine Besichtigung (4)",
    "100000001": "Urgenz (99)",
    "100000002": "warten auf Unterlagen (9)",
    "100000003": "Kontaktaufnahme mit VN/HG (2)",
    "100000004": "Info an Versicherer über Kontaktaufnahme (3)",
    "100000005": "Gutachten geht an Versicherer (11)",
    "100000006": "Besichtigung durchgefuehrt (6)",
    "100000007": "weiterer Besichtigungstermin (5)",
    "100000008": "Erstinfo an Auftraggeber erstellt (7)",
    "100000009": "Stellungnahme/Prüfung geht an Versicherer (13)",
    "100000010": "Aktnotiz ()",
    "100000011": "Besichtigungsdatum (5)",
    "100000012": "Honorarnote erstellt (12)",
    "100000013": "Mails und Unterlagen eingelangt (10)",
    "100000014": "Akt stornieren (15)",
    "100000015": "Vorbericht an Versicherer (8)",
    "100000016": "Bearbeiter wurde zugewiesen",
    "100000017": "Gutachten wurde hochgeladen (75)",
    "100000018": "Unterlagen eingelangt (95)",
    "100000019": "Gutachten zur Durchsicht weitergegeben (72)",
    "100000020": "Erinnerung (98)",
    "100000021": "GA in Ausarbeitung(97)",
    "100000022": "Erst-/Zwischeninfo wurde hochgeladen (73)",
    "100000023": "Vorbericht wurde hochgeladen (74)",
    "100000024": "Stellungnahme/Pruefung wurde hochgeladen (76)",
    "100000025": "Urgenz (99)",
    "100000026": "Gutachten wurde freigegeben (77)",
    "100000027": "Statustext eingetragen ()",
    "100000028": "Statusaenderung"
  },
  hntyp: {
    "100000000": "an AG-Referent",
    "100000001": "an AG-Zentrale",
    "100000002": "an VN-HG",
    "100000003": "Privatauftrag"
  },
  kindOptions: {
    "100000000": "Auftrag",
    //B
    "100000001": "Angebot",
    //B
    "100000002": "Unwetterschäden",
    //B
    "100000003": "verdächtiger Auftrag",
    //B
    "100000004": "Generali",
    //B
    "100000005": "Beweissicherung",
    //B
    "100000006": "Risikoanalyse",
    //B
    "100000007": "Allianz",
    //B
    "100000008": "Tour",
    //B
    "100000009": "Kärnten" //B
  },
  statusColors: {
    // # colors for these status in map & commissionList
    // "100000000": "pink",
    "100000020": "pink",
    "100000021": "yellow",
    "100000001": "blue",
    "100000002": "blue",
    "100000014": "blue",
    "100000010": "blue",
    "100000003": "green",
    "100000012": "greenyellow",
    "100000004": "greenyellow",
    "100000011": "greenyellow",
    "100000022": "greenyellow",
    "100000024": "greenyellow",
    "100000013": "greenyellow",
    "100000005": "grey",
    "100000009": "grey",
    "100000006": "grey",
    "100000023": "grey",
    "100000025": "grey",
    "100000026": "grey",
    "100000028": "grey",
    "100000007": "purple",
    "other": "lightgrey",
    "default": "lightgrey"
  },
  // combinedStatus: {
  //   "01": {color:'pink' ,name: "Akt angelegt", options:["100000020"]},
  //   "02": {color:'yellow' ,name: "Dringender Akt angelegt", options:["100000021"]},
  //   "1,2,3": {color:'blue' ,name: "Besichtigung zu vereinbaren", options:["100000001", "100000002", "100000014"]},
  //   "5": {color:'green' ,name: "Besichtigung vereinbart", options:["100000003"]},
  //   "4,6,7,10,95,97,9": {color:'greenyellow' ,name: "In Bearbeitung (SV)", options:["100000012", "100000004", "100000011", "100000022", "100000024","100000010","100000013"]},
  //   "11,12,13,72,73,74,77": {color:'grey' ,name: "In Fertigstellung (OFFICE)", options:["100000005", "100000009", "100000006", "100000023", "100000025", "100000026","100000028",]},
  //   "14": {color:'purple' ,name: "Akt Erledigt", options:["100000007"]},
  // },
  combinedStatus: {
    's01': {
      color: 'pink',
      name: "Akt angelegt",
      options: ["100000020"]
    },
    's02': {
      color: 'yellow',
      name: "Dringender Akt angelegt",
      options: ["100000021"]
    },
    's03': {
      color: 'blue',
      name: "Besichtigung zu vereinbaren",
      options: ["100000001", "100000002", "100000014", "100000010"]
    },
    's04': {
      color: 'green',
      name: "Besichtigung vereinbart",
      options: ["100000003"]
    },
    's05': {
      color: 'greenyellow',
      name: "In Bearbeitung (SV)",
      options: ["100000010", "100000012", "100000004", "100000013", "100000011", "100000022", "100000024"]
    },
    's06': {
      color: 'grey',
      name: "In Fertigstellung (OFFICE)",
      options: ["100000005", "100000009", "100000006", "100000023", "100000025", "100000026", "100000028"]
    },
    's14': {
      color: 'purple',
      name: "Akt Erledigt",
      options: ["100000007", "100000008"],
      default: false
    }
  }
};
export default crmstuff;