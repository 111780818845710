import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm$content, _vm$content2, _vm$content2$images, _vm$content2$images$v, _vm$content2$images$v2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor images-selector"
  }, [(_vm$content = _vm.content) !== null && _vm$content !== void 0 && _vm$content.$captionOfFirstImageAsHeading && (_vm$content2 = _vm.content) !== null && _vm$content2 !== void 0 && (_vm$content2$images = _vm$content2.images) !== null && _vm$content2$images !== void 0 && (_vm$content2$images$v = _vm$content2$images.value) !== null && _vm$content2$images$v !== void 0 && (_vm$content2$images$v2 = _vm$content2$images$v.data) !== null && _vm$content2$images$v2 !== void 0 && _vm$content2$images$v2[0] ? _c('div', [_c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Titel/Beschreibung")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.content.images.value.data[0].caption,
      expression: "content.images.value.data[0].caption"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.content.images.value.data[0].caption
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.content.images.value.data[0], "caption", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', [_c('drop-list', {
    staticClass: "flex",
    attrs: {
      "items": _vm.content.images.value.data.slice(0, _vm.content.$maxImages),
      "mode": "cut"
    },
    on: {
      "reorder": function reorder($event) {
        return $event.apply(_vm.content.images.value.data);
      },
      "insert": _vm.insert
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var _vm$content3, _vm$content3$images, _vm$content3$images$v;
        var item = _ref.item,
          reorder = _ref.reorder;
        return [_c('drag', {
          key: item.id,
          attrs: {
            "data": "item"
          },
          on: {
            "cut": function cut($event) {
              return _vm.remove(_vm.content.images.value.data, item);
            }
          }
        }, [_c('div', {
          staticClass: "chip"
        }, [_c('div', {
          staticClass: "image"
        }, [_c('div', {
          staticClass: "img",
          class: _vm.rotClass(item.rotate),
          style: _vm.makeImgBgStyle(item.id),
          attrs: {
            "width": "80px"
          }
        }), _c('div', {
          staticClass: "deleter _hover",
          on: {
            "click": function click($event) {
              return _vm.removeImage(item.id);
            }
          }
        }, [_c('i', {
          staticClass: "icon-times inline"
        })]), _c('div', {
          staticClass: "turner _hover",
          on: {
            "click": function click($event) {
              return _vm.rotateImage(item.id);
            }
          }
        }, [_c('i', {
          staticClass: "icon-rotate-circle inline"
        })]), !_vm.content.$captionOfFirstImageAsHeading && !((_vm$content3 = _vm.content) !== null && _vm$content3 !== void 0 && (_vm$content3$images = _vm$content3.images) !== null && _vm$content3$images !== void 0 && (_vm$content3$images$v = _vm$content3$images.value) !== null && _vm$content3$images$v !== void 0 && _vm$content3$images$v.$dontShowCaption) ? _c('div', {
          staticClass: "caption"
        }, [_c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: item.caption,
            expression: "item.caption"
          }],
          staticClass: "input",
          attrs: {
            "placeholder": "Beschreibung"
          },
          domProps: {
            "value": item.caption
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(item, "caption", $event.target.value);
            }
          }
        })]) : _vm._e()])])])];
      }
    }])
  }), _vm.maxAllowedImgs > _vm.content.images.value.data.length ? _c('div', {
    staticClass: "blockImages__addIt"
  }, [_c('a', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ChooseImagesForBlock', {
          content: _vm.content.images.value,
          maxImages: _vm.content.$maxImages,
          blockedImages: _vm.blockedImages
        });
      }
    }
  }, [_c('i', {
    staticClass: "icon-add"
  })])]) : _vm._e()], 1), _vm.maxAllowedImgs < _vm.content.images.value.data.length ? _c('div', {
    staticClass: "tac color-red bold"
  }, [_vm._v("es werden nur die ersten " + _vm._s(_vm.content.$maxImages) + " Bilder an dieser Stelle im Gutachten übernommen. Alle restlichen bitte zum letzten Bilder Block hinzufügen.")]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };