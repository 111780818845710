import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.starts-with.js";
import self from "@/libs/utils";
import _ from "lodash";
import $env from "@/env";
import { aktStatus } from "@consense/crm";
var STATUSZ1 = '525';
var STATUSM2 = '999';
var STATUSM3 = '584';
var STATUSH5 = '193';
var statusForMap = [100000000, 100000001, 100000002, 100000014, 100000010, 100000003, 100000020, 100000021, 100000028];
export default {
  _config: function _config(state) {
    var userDataFb = state.userDataFb;
    var userrole = userDataFb.userRole;
    var consensetoken = state.userDataCrm.consenseToken;
    var accesstoken = state.userDataCrm.accessToken;
    var Cookie = state.userDataCrm.cookie;
    var crmid = state.userDataCrm.crmid || '';
    // let cpass: any = state.userDataCrm.cpass
    var email = state.userDataCrm.userName;
    var uid = userDataFb.uid;
    // important:  all lowercase!!!
    var config = {
      headers: {
        accesstoken: accesstoken,
        usercookie: Cookie,
        consensetoken: consensetoken,
        crmid: crmid,
        uid: uid,
        email: email,
        // cpass,
        userrole: userrole,
        env: $env.version
      },
      params: {}
    };
    return config;
  },
  projectarr: function projectarr(s) {
    return self.$_O2A(s.projects);
  },
  xprojectarr: function xprojectarr(s) {
    return self.$_O2A(s.xlist);
  },
  // maparray: s => self.$_O2A(s.maplist),
  maparray: function maparray(s) {
    return Object.values(s.projects).filter(function (el) {
      return statusForMap.includes(el.stid);
    });
  },
  // commissionsList: s => {return Object.values(s.commissions).filter((el: any) => statusForMap.includes(el.statusId))},
  commissionsList: function commissionsList(s) {
    return Object.values(s.commissions);
  },
  _showInTable: function _showInTable(s) {
    var ob = {};
    s.showInAllianzTable.forEach(function (el) {
      ob[el.type] = el.value;
    });
    return ob;
  },
  _svNamesFB: function _svNamesFB(s) {
    return _.keyBy(_.sortBy(Object.values(s.FB_users.data).map(function (sv) {
      return {
        id: sv.crmid,
        uId: sv.id,
        titel: sv.akktitle || "",
        name: (sv.lastname + ', ' + sv.firstname).trim()
      };
    }), "name"), 'uId');
  },
  _svNamesByCrmContactId: function _svNamesByCrmContactId(s) {
    return _.keyBy(_.sortBy(Object.values(s.FB_users.data).filter(function (el) {
      return (el.crmContactId || '').length > 4;
    }).map(function (sv) {
      return _objectSpread(_objectSpread({}, sv), {}, {
        id: sv.crmid,
        uId: sv.id,
        titel: sv.akktitle || "",
        name: (sv.lastname + ', ' + sv.firstname).trim()
      });
    }), "name"), 'id');
  },
  /**
   * @deprecated Use the module's getter `groups` instead
   */
  _notificationGroups: function _notificationGroups(s) {
    return Object.fromEntries(Object.entries(s.FB_notificationGroups.data).filter(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
        k = _ref2[0];
      return k !== 'id';
    }));
  },
  _myGroups: function _myGroups(s, g) {
    return Object.values(s.FB_notificationGroups.data).filter(function (n) {
      return (n.usersList || []).includes(g._userUid);
    }).map(function (n) {
      return n.id;
    });
  },
  userUid: function userUid(s) {
    return s.userDataFb.uid;
  },
  _userUid: function _userUid(s) {
    return s.userDataFb.uid;
  },
  isFb: function isFb(s) {
    return s.userLogin.isSignedUpFb;
  },
  isCrm: function isCrm(s) {
    return s.userLogin.isSignedUpCrmb;
  },
  _userLoggedIn: function _userLoggedIn(s) {
    return s.userDataCrm.consenseToken;
  },
  userRole: function userRole(s) {
    return s.userDataFb.userRole;
  },
  _userRole: function _userRole(s) {
    return s.userDataFb.userRole;
  },
  isNoRole: function isNoRole(s) {
    return s.userDataFb.userRole == 'no';
  },
  isSv: function isSv(s) {
    return s.userDataFb.userRole == 'sv';
  },
  _isSv: function _isSv(s) {
    return s.userDataFb.userRole == 'sv';
  },
  _isAdmin: function _isAdmin(s) {
    return ['admin', 'admin-plus', 'gf'].includes(s.userDataFb.userRole);
  },
  _isAdminPlus: function _isAdminPlus(s) {
    return ['admin-plus', 'gf'].includes(s.userDataFb.userRole);
  },
  _isGf: function _isGf(s) {
    return ['gf'].includes(s.userDataFb.userRole);
  },
  svRights: function svRights(s) {
    return s.userDataFb.rights || {};
  },
  allowCrm: function allowCrm(s, g) {
    return g._isAdmin || s.userDataFb.rights.crmReadWriteOwn || s.userDataFb.rights.crmReadWriteAll;
  },
  allowAllianz: function allowAllianz(s, g) {
    return g._isAdmin || g.svRights.allianzReadWriteOwn || g.svRights.allianzReadWriteAll || g.svRights.allianzReadReports;
  },
  _allianzReadWriteOwn: function _allianzReadWriteOwn(s, g) {
    return g._isAdmin || g.svRights.allianzReadWriteOwn;
  },
  _allianzReadWriteOwnEmpty: function _allianzReadWriteOwnEmpty(s, g) {
    return g._isAdmin || g.svRights.allianzReadWriteOwnEmpty;
  },
  _allianzReadWriteAll: function _allianzReadWriteAll(s, g) {
    return g._isAdmin || g.svRights.allianzReadWriteAll;
  },
  _allianzReadReports: function _allianzReadReports(s, g) {
    return g._isAdmin || g.svRights.allianzReadReports;
  },
  _crmMayEditVertraulich: function _crmMayEditVertraulich(s, g) {
    return s.userDataFb.userRole == 'gf' || g.svRights.crmMayEditVertraulich;
  },
  _isExternSv: function _isExternSv(s, g) {
    return !g._isAdmin && g._isSv && g._allianzReadWriteOwn && !g._allianzReadWriteOwnEmpty && !g._allianzReadWriteAll;
  },
  _isSvPlus: function _isSvPlus(s, g) {
    return !g._isAdmin && g._isSv && g._allianzReadWriteOwn && g._allianzReadWriteOwnEmpty && !g._allianzReadWriteAll;
  },
  _isInternSv: function _isInternSv(s, g) {
    return !g._isAdmin && g._isSv && g._allianzReadWriteAll;
  },
  _filteredStatusList: function _filteredStatusList(s) {
    return _.chain(aktStatus).filter(function (x) {
      return !['8', '75', '76', '98', '99'].includes(x.nr);
    }) // Hidden as per WEB-128
    .sortBy([function (x) {
      return parseInt(x.nr);
    }, 'id']).map(function (x) {
      return {
        id: x.id,
        name: "".concat(x.nr, " - ").concat(x.label)
      };
    }).value();
  },
  tok: function tok(s) {
    return "?consenseTokenCookie=" + s.userDataCrm.consenseToken;
  },
  _currentUser: function _currentUser(s) {
    return s.FB_users_uid.data || {
      usermetadata: {}
    };
  },
  _fbProjects: function _fbProjects(s) {
    return Object.assign({}, s.MY_allianzProjects.data, s.FB_allianzProjects.data);
  },
  _fbReports: function _fbReports(s) {
    return s.DL_reports;
  },
  _fbUsers: function _fbUsers(s) {
    return s.FB_users.data;
  },
  _fbHausverwaltungen: function _fbHausverwaltungen(s) {
    return s.FB_allianzHausverwaltungen.data || {};
  },
  _fbStatus: function _fbStatus(s) {
    return s.FB_allianzStatus.data.status || {};
  },
  _allowedSv2Status: function _allowedSv2Status(s, g) {
    var _g$_fbStatus$aktStatu;
    return ((_g$_fbStatus$aktStatu = g._fbStatus.aktStatus) === null || _g$_fbStatus$aktStatu === void 0 ? void 0 : _g$_fbStatus$aktStatu.map(function (item) {
      if (item.sv2) return item.id;
    }).filter(function (item) {
      return item;
    })) || s.allowedSv2Status;
  },
  _statusObj: function _statusObj(s, g) {
    return _.keyBy(g._fbStatus.aktStatus, 'id');
  },
  _statusHvObj: function _statusHvObj(s, g) {
    return _.keyBy(g._fbStatus.hvStatus, 'id');
  },
  _fbGeneral: function _fbGeneral(s) {
    return s.FB_general.data;
  },
  featureFlags: function featureFlags(s) {
    return s.FB_general.data.features || {};
  },
  // _fbTemplates: (s) => s.FB_templates.data,
  _fbTemplates: function _fbTemplates(s) {
    return s.templates.data;
  },
  _fbUsersSelectingArr: function _fbUsersSelectingArr(s, g) {
    var arr = [];
    arr.push({
      crmContactId: null,
      crmUserId: null,
      id: "000",
      name: "keine Auswahl"
    });
    for (var svUid in g._fbUsers) {
      var theUser = g._fbUsers[svUid];
      // console.log(theUser, svUid);
      arr.push({
        // crmId: theUser.crmid || null,
        crmContactId: theUser.crmid || null,
        crmUserId: theUser.crmUserId || null,
        id: svUid,
        name: "".concat(theUser.firstname, "  ").concat(theUser.lastname).trim(),
        titelName: "".concat(theUser.akktitle !== '-' ? theUser.akktitle : '', " ").concat(theUser.firstname, "  ").concat(theUser.lastname).trim()
        // (g._fbUsers[svUid].new_titel || "") + ' ' + ((g._fbUsers[svUid].firstname || "") + "." + g._fbUsers[svUid].lastname).trim()
      });
    }
    return arr;
  },
  _filteredUsers: function _filteredUsers(s, g) {
    var _fbUsers = _.values(g._fbUsers);
    var globalSearch = s.globalSearch.toLowerCase().trim().replace(";", '');
    var resultArr = _fbUsers.filter(function (user) {
      return globalSearch ? JSON.stringify(user).toLowerCase().includes(globalSearch) : true;
    });
    return resultArr;
  },
  _countProjectsForHV: function _countProjectsForHV(s, g) {
    var differ = Date.now();
    var resObj = {};
    var _fbHausverwaltungen = g._fbHausverwaltungen;
    var _fbProjects = g._fbProjects;
    for (var hvId in _fbHausverwaltungen) {
      resObj[hvId] = [];
      for (var prId in _fbProjects) {
        if (_fbProjects[prId].hvUid == hvId) {
          // resObj[hvId]++
          resObj[hvId].push(prId);
        }
      }
    }
    return resObj;
  },
  _cleanedFBProjectsArr: function _cleanedFBProjectsArr(state, getters) {
    var differ = Date.now();
    // let fbProjects = self.$_cp(getters._fbProjects);
    var fbProjects = _.values(getters._fbProjects);
    var fbUsers = self.$_cp(getters._fbUsers);
    var fbHausverwaltungen = self.$_cp(getters._fbHausverwaltungen);
    var statusObj = getters._statusObj;
    var statusHvObj = getters._statusHvObj;
    var filterMyAkte = state.filterMyAkte;
    var allowedSv2Status = getters._allowedSv2Status;
    var filterMyAkteSv1 = state.filterMyAkteSv1;
    var filterStatus = state.filterStatus;
    var isShowOnlyUrgent = state.isShowOnlyUrgent;
    var isShowOnlyVisible = state.isShowOnlyVisible;
    var isShowOnlyDs = state.isShowOnlyDs;
    var isShowOnlyMa = state.isShowOnlyMa;
    var is2023Projects = state.is2023Projects;
    var isMfhSFP = state.isMfhSFP;
    var thisUserUid = getters._userUid;
    if (filterMyAkte) {
      fbProjects = fbProjects.filter(function (project) {
        return [project.svUid, project.svUid2].includes(thisUserUid);
      });
      if (filterMyAkteSv1) {
        fbProjects = fbProjects.filter(function (project) {
          // return [project.svUid2].includes(thisUserUid)
          return (project.svUid2 || "").length < 8;
        });
      }
    }
    // FOR "fertige  Berichte":
    if (!filterStatus.m2) {
      fbProjects = fbProjects.filter(function (project) {
        return ![project.status].includes(STATUSM2);
      });
    }
    if (!filterStatus.m3) {
      fbProjects = fbProjects.filter(function (project) {
        return ![project.status].includes(STATUSM3);
      });
    }
    if (!filterStatus.h5) fbProjects = fbProjects.filter(function (project) {
      return ![project.status].includes(STATUSH5);
    });
    if (filterStatus.z1) fbProjects = fbProjects.filter(function (project) {
      return ![project.status].includes(STATUSZ1);
    });
    if (isShowOnlyUrgent) fbProjects = fbProjects.filter(function (project) {
      return (project.flags || {}).isNewInJune || false;
    });
    if (isShowOnlyVisible) fbProjects = fbProjects.filter(function (project) {
      return (project.flags || {}).isVisible || false;
    });
    if (isShowOnlyDs) fbProjects = fbProjects.filter(function (project) {
      return (project.flags || {}).isDenkmalschutz || false;
    });
    if (isShowOnlyMa) fbProjects = fbProjects.filter(function (project) {
      return (project.flags || {}).isMehraufwand || false;
    });
    if (is2023Projects) fbProjects = fbProjects.filter(function (project) {
      return (project.flags || {}).is2023Projects || false;
    });
    if (isMfhSFP) fbProjects = fbProjects.filter(function (project) {
      return (project.flags || {}).isMfhSFP || false;
    });
    // "195", "Bereit für Massenermittlung" E1
    // "194", "Vollmacht vorhanden -> Bauamt" H6
    // "181", "Pläne vorhanden NUR Grundriss" H8
    // "162", "Vollmacht BEFRISTET ausgestellt!" H9
    // "230", "Bericht begonnen" S4
    // "211", "Besichtigung durchgeführt" S3
    // "197", "Massenermittlung abgeschlossen" E2
    // "774", "Bereit für EH-Ermittlung" T1
    // "210", "Besichtigungstermin vereinbart"
    // "743", SV2 = Nochmalige Kontrolle
    // A0 "100"
    // H1 "141"
    // H3 "160"
    // H5 "193"
    // H9 "162"
    // M1 "998"
    // M2 "999"
    // s11"150"
    // s6 "995"
    // s7 "298"
    // s8 "428"
    // s9 "438"
    // x1 "710"
    // let allowedSv2Status = ["195", "194", "181", "162", "230", "211", "197", "553", "774", "743"];
    // SV2 should only see his onw allowed status:
    // E1 H6 H8 H9 S4 S3 E2 - T1 T0
    // if (getters._isSv) {
    //   fbProjects = fbProjects.filter(project => ![STATUSZ1].includes(project.status.toString()))
    // }
    if (getters._isExternSv) {
      fbProjects = fbProjects.filter(function (project) {
        return allowedSv2Status.includes(((project || {}).status || 99).toString());
      });
    }
    var resultArr = fbProjects.map(function (project) {
      var _project$reportData, _project$reportData2, _totalArr$, _totalArr$$table, _totalArr$$table$tota;
      project.fillByHvAt = (project.hvData || {}).modified || 0;
      project = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, project), project.allianzData), project.contact || {}), project.flags), project.svData);
      var hvwProject = fbHausverwaltungen[project.hvUid || ''] || {};
      var statusProject = project.status || '100';
      var statusProjectHv = hvwProject.status || '700';
      var statusAKT = (statusObj[statusProject] || {}).icon || '';
      var statusHVW = (statusHvObj[statusProjectHv] || {}).icon || '';
      var statusAKTName = (statusObj[statusProject] || {}).name || '';
      var statusHVWName = (statusHvObj[statusProjectHv] || {}).name || '';
      project.resolvedstatus = statusAKT + '/' + statusHVW + ' ' + statusAKTName + '/' + statusHVWName;
      project.resolvedonlystatus = statusAKT + '/' + statusHVW;
      project.resolvedhv = hvwProject.name || '-';
      project.reportedDataModified = hvwProject.name || '';
      var svData = fbUsers[project.svUid] || {};
      project.resolvedsv = (svData.firstname || '') + ' ' + (svData.lastname || '');
      var svData2 = fbUsers[project.svUid2] || {};
      project.resolvedsv += '\n' + (svData2.firstname || '') + ' ' + (svData2.lastname || '');
      var onlyAllianzData = project.allianzData || {};
      project.all_ld = onlyAllianzData.ld || '-';
      project.all_insp = onlyAllianzData.insp || '-';
      project.all_tranche = onlyAllianzData.tranche || '-';
      project.all_flotte = onlyAllianzData.flottenname || '-';
      var isVnSame2 = ((((JSON.parse((project.reportData || {}).html || '{}')[0] || {}).values || [0, 1, 2, 3, 4, {}, {}])[5] || {}).value || '').trim();
      var isVnSame = ((onlyAllianzData.vorname || "") + ' ' + (onlyAllianzData.name || "")).trim();
      project.isVnSame = isVnSame == isVnSame2;
      var isPolNrSame = (onlyAllianzData.polnr || "").trim();
      var isPolNrSame2 = ((((JSON.parse((project.reportData || {}).html || '{}')[0] || {}).values || [0, 1, 2, 3, 4, {}, {}])[7] || {}).value || '').trim();
      project.isPolNrSame = isPolNrSame == isPolNrSame2;
      project.hasReportData = project.reportData && Object.keys(project.reportData || {}).length > 0 && !!(project.reportData || {}).html;
      project.reportedDataModified = ((_project$reportData = project.reportData) === null || _project$reportData === void 0 ? void 0 : _project$reportData.modified) || 0;
      project.completedDate = project.completedDate || 0;
      // "Denkmalschutz" and "Mehraufwand" efforts should be displayd in an own column
      project.dsmaEffort = '';
      if (project.isDenkmalschutz) project.dsmaEffort = "".concat((project.dsData || {}).effort || 0, " h (DS)");
      if (project.isMehraufwand) project.dsmaEffort += " ".concat((project.maData || {}).effort || 0, " h (MA)");
      var headCalc = JSON.parse(((_project$reportData2 = project.reportData) === null || _project$reportData2 === void 0 ? void 0 : _project$reportData2.html) || '[]');
      var totalArr = headCalc.filter(function (item) {
        return item.type == 'excel';
      });
      var summeNeu = ((_totalArr$ = totalArr[0]) === null || _totalArr$ === void 0 ? void 0 : (_totalArr$$table = _totalArr$.table) === null || _totalArr$$table === void 0 ? void 0 : (_totalArr$$table$tota = _totalArr$$table.total) === null || _totalArr$$table$tota === void 0 ? void 0 : _totalArr$$table$tota.cost) || 0;
      if (project.isCustomPdf) project.summeNeu = (project.reportData || {}).vsNew || 0;else project.summeNeu = summeNeu;
      var toRemoveList = ["__history", "allianzData", "svData", "created_by", "modified_by", "modifiedBy", "_history", "created_at", "updated_at", "updated_by", "tranche", "contacts", "contact", "flags", "groups", "flottenname", "insp", "emailReceiver", "besichtigungsTermin", "ld", "hvData", "reportData", "json2", "_id"];
      toRemoveList.forEach(function (rId) {
        delete project[rId];
      });
      return project;
    });
    return resultArr;
  },
  _globalSearchArr: function _globalSearchArr(s) {
    return _toConsumableArray(new Set(_toConsumableArray(s.globalSearch.split(';')).map(function (el) {
      return el.trim().toLowerCase().replace(";", "");
    }).filter(function (el) {
      return el.length > 2;
    })));
  },
  _filteredFBProjects: function _filteredFBProjects(s, g) {
    // const globalSearch = s.globalSearch.toLowerCase()
    var extraAllianzFilterFieldsObj = self.$_removeEmptyObjects(s.extraAllianzFilterFields);
    var cleanedFBProjectsArr = g._cleanedFBProjectsArr;
    // const globalSearchArr = globalSearch.split('\n') globalSearch.split(',')
    var globalSearchArr = g._globalSearchArr;
    var startsWithFilterArr = ["plz_risiko"];
    var resultArr = cleanedFBProjectsArr.filter(function (project) {
      var projectX = JSON.parse(JSON.stringify(project));
      delete projectX.generalInfo;
      delete projectX.notices;
      delete projectX.noticesSv2;
      var isInList = true;
      if (globalSearchArr.length && globalSearchArr[0].length > 2) {
        isInList = false;
        var _iterator = _createForOfIteratorHelper(globalSearchArr),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var gs = _step.value;
            if (JSON.stringify(projectX).toLowerCase().includes(gs)) {
              isInList = true;
              break;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      // let isInList = globalSearch ? JSON.stringify(projectX).toLowerCase().includes(globalSearch) : true;
      var _loop = function _loop(extraFieldIdx) {
        var extraField = (extraAllianzFilterFieldsObj[extraFieldIdx] + '').toLowerCase();
        var projectField = !!project[extraFieldIdx] && (('' + project[extraFieldIdx]).toLowerCase() || '');
        if (isInList && extraField.includes(',')) {
          var extraFieldArr = extraField.split(',');
          var foundField = 0;
          extraFieldArr.forEach(function (eField) {
            if (eField && projectField) {
              if (!startsWithFilterArr.includes(extraFieldIdx) && projectField.includes(eField.trim())) foundField++;else if (startsWithFilterArr.includes(extraFieldIdx) && projectField.startsWith(eField.trim())) foundField++;
            }
          });
          isInList = isInList && projectField ? foundField > 0 : isInList;
        } else {
          isInList = isInList && projectField && extraFieldIdx !== 'plz_risiko' ? projectField.includes(extraField) : isInList;
          isInList = isInList && projectField && extraFieldIdx === 'plz_risiko' ? projectField.startsWith(extraField) : isInList;
          if (isInList && projectField && extraFieldIdx === 'resolvedonlystatus') {
            // if (extraField.startsWith("*")) {
            var efArr = projectField.split('/');
            if (extraField.length == 1 && efArr[0][0] !== extraField && efArr[1][0] !== extraField) {
              isInList = false;
            } else if (extraField.length > 1 && !efArr.includes(extraField)) {
              console.log(efArr, extraField);
              isInList = false;
            }
          }
        }
      };
      for (var extraFieldIdx in extraAllianzFilterFieldsObj) {
        _loop(extraFieldIdx);
      }
      return isInList;
    });
    return resultArr;
  }
};