import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    attrs: {
      "id": "ListArray"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.showList = !_vm.showList;
      }
    }
  }, [_vm._v("Offene Akte")])]), _vm.showList && !_vm._currentUser.loadCrm ? _c('div', {
    staticClass: "card-content tac"
  }, [_c('h4', [_vm._v("Keine Daten geladen")]), _c('p', [_vm._v("In den Einstellungen kann angeben werden, was geladen werden soll.")]), _c('router-link', {
    attrs: {
      "to": "/useraccount"
    }
  }, [_vm._v("Einstellungen")])], 1) : _vm.showList && _vm._currentUser.loadCrm ? _c('div', {
    staticClass: "card-content"
  }, [!_vm.crmDataLoaded ? _c('div', {
    staticClass: "padding tac"
  }, [_c('div', {
    staticClass: "btn clear",
    on: {
      "click": function click($event) {
        return _vm._combinedApiCalls();
      }
    }
  }, [_vm._v("Daten laden")])]) : _vm._e(), !_vm.crmDataLoaded ? _c('div', {
    staticClass: "padding"
  }, [_vm._m(0)]) : _vm.crmDataLoaded ? _c('div', {
    staticClass: "crm-list"
  }, [_c('div', {
    staticClass: "ovautoNo"
  }, [_c('div', {
    staticClass: "fr"
  }, [_c('v-pagination', {
    attrs: {
      "fPages": _vm.fPages
    },
    model: {
      value: _vm.onpage,
      callback: function callback($$v) {
        _vm.onpage = _vm._n($$v);
      },
      expression: "onpage"
    }
  }), _c('div', {
    staticClass: "wz-tag"
  }, [_c('span', [_vm._v("Sortieren:")]), _c('span', [_vm._v("·")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.Sorter('akt');
      }
    }
  }, [_vm._v("Akt")]), _c('span', [_vm._v("·")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.Sorter('ba');
      }
    }
  }, [_vm._v("BA")]), _c('span', [_vm._v("·")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.Sorter('ageber');
      }
    }
  }, [_vm._v("AGEBER")]), _c('span', [_vm._v("·")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.Sorter('vnehmer');
      }
    }
  }, [_vm._v("VNEHMER")]), _c('span', [_vm._v("·")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.Sorter('stid');
      }
    }
  }, [_vm._v("Status")]), _c('span', [_vm._v("·")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.Sorter('modifiedon');
      }
    }
  }, [_vm._v("geändert")])])], 1), _c('span', [_c('div', {
    staticClass: "wz-tag"
  }, [_c('span', [_vm._v("Ansicht:")]), _c('span', [_vm._v("  ")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.LOCltview = 'table';
      }
    }
  }, [_vm._v("Tabelle")]), _c('span', [_vm._v("·")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.LOCltview = 'list';
      }
    }
  }, [_vm._v("Liste")])]), _c('v-checkbox', {
    attrs: {
      "item": '100000007',
      "label": 'Erledigte auch anzeigen'
    },
    model: {
      value: _vm.filterStatusArr,
      callback: function callback($$v) {
        _vm.filterStatusArr = $$v;
      },
      expression: "filterStatusArr"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "item": '100000009',
      "label": '`Honorarnote erstellt` auch anzeigen'
    },
    model: {
      value: _vm.filterStatusArr,
      callback: function callback($$v) {
        _vm.filterStatusArr = $$v;
      },
      expression: "filterStatusArr"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "item": '100000008',
      "label": 'Stornierte auch anzeigen'
    },
    model: {
      value: _vm.filterStatusArr,
      callback: function callback($$v) {
        _vm.filterStatusArr = $$v;
      },
      expression: "filterStatusArr"
    }
  })], 1), _vm.filteredListLength == 0 ? _c('div', {
    staticClass: "ovauto filter"
  }, [_c('div', [_vm._v("Daten: " + _vm._s(_vm.filteredListLength) + "/" + _vm._s(_vm.projectarr.length))]), _vm.globalSearch ? _c('div', [_vm._v("Suche nach: " + _vm._s(_vm.globalSearch))]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "clear"
  }), _c('hr')]), _vm.LOCltview == 'list' ? _c('span', [_vm.filteredListLength > 0 ? _c('ul', {
    staticClass: "mainlist"
  }, _vm._l(_vm.fPages[_vm.onpage - 1], function (item, index) {
    return _c('li', {
      staticClass: "list_item",
      class: {
        important: item.importantToEstablishContact || item.urgent
      },
      style: {
        backgroundColor: item.marker
      }
    }, [_c('div', {
      staticClass: "header"
    }, [!item.touched ? _c('div', {
      staticClass: "unread"
    }, [_c('i', {
      staticClass: "icon-email"
    })]) : _vm._e(), item.importantToEstablishContact || item.urgent ? _c('div', {
      staticClass: "important"
    }, [item.importantToEstablishContact ? _c('i', {
      staticClass: "icon-phone-outgoing-exclamation"
    }) : _vm._e(), item.urgent ? _c('i', {
      staticClass: "icon-exclamation"
    }) : _vm._e()]) : _vm._e(), _c('router-link', {
      staticClass: "akt-link",
      attrs: {
        "to": '/crmakt/' + item.aktname
      }
    }, [_c('div', {
      staticClass: "it akt"
    }, [_c('span', [_vm._v(_vm._s(item.aktname))]), item.bezeichnung ? _c('div', {
      staticClass: "color-blue"
    }, [_vm._v(_vm._s(item.bezeichnung))]) : _vm._e()])]), _c('div', [_c('div', {
      staticClass: "it status"
    }, [item.stid === 100000007 ? _c('span', [_vm._v("erledigt  "), _c('i', {
      staticClass: "icon-check"
    })]) : item.stid === 100000008 ? _c('span', [_vm._v("storno  "), _c('i', {
      staticClass: "icon-times"
    })]) : _c('span', [_vm._v(_vm._s(_vm.status[item.stid]) + "  "), _c('i', {
      staticClass: "icon-circle"
    })])])])], 1), _c('div', {
      staticClass: "it vnehmer"
    }, [_vm._v(_vm._s(item.vnehmer))]), _c('br'), _c('div', {
      staticClass: "fr lcrmink"
    }, [_c('a', {
      attrs: {
        "target": "_blank",
        "href": 'https://consense.crm4.dynamics.com/main.aspx?etc=10009&id=' + item.aid + '&Pagetype=entityrecord'
      }
    }, [_vm._v("CRM Link")])]), _c('div', {
      staticClass: "it ageber"
    }, [_vm._v(_vm._s(item.ageber))]), _c('br'), _c('div', {
      staticClass: "fr"
    }, [_c('small', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.besichtigungsadresse)), (item.loc || {}).hasCoords === 100000000 ? _c('i', {
      staticClass: "icon-mapmarker"
    }) : _vm._e()])]), _vm.sparten ? _c('div', {
      staticClass: "it details"
    }, [_c('span', {
      staticClass: "sparteart"
    }, [_vm._v(_vm._s((_vm.sparten.sparten[item.sparteart] || '-').substring(0, 119) + '') + " -")]), _c('span', {
      staticClass: "sparteart"
    }, [_vm._v(_vm._s((_vm.sparten.details[item.spartedet] || '-').substring(0, 119) + '') + " -")]), _c('span', {
      staticClass: "sparteart"
    }, [_vm._v(_vm._s((_vm.sparten.art[item.schadenart] || '-').substring(0, 119) + ''))])]) : _vm._e(), _c('marker-selector', {
      staticClass: "color-picker-popout",
      attrs: {
        "commission-nr": "".concat(item.art, " ").concat(item.akt)
      }
    })], 1);
  }), 0) : _vm._e()]) : _vm._e(), _vm.LOCltview == 'table' ? _c('span', [_vm.fPages ? _c('table', {
    staticClass: "listarray"
  }, [_c('thead', [_c('tr', [_c('th', {
    on: {
      "click": function click($event) {
        return _vm.Sorter('akt');
      }
    }
  }, [_vm._v("Name")]), _c('th', {
    on: {
      "click": function click($event) {
        return _vm.Sorter('stid');
      }
    }
  }, [_vm._v("Status")]), _c('th', {
    on: {
      "click": function click($event) {
        return _vm.Sorter('vnehmer');
      }
    }
  }, [_vm._v("V.Nemher")]), _c('th', {
    on: {
      "click": function click($event) {
        return _vm.Sorter('ageber');
      }
    }
  }, [_vm._v("A.Geber")]), _c('th', [_vm._v("Sparte")]), _c('th', [_vm._v("Link")]), _c('th', {
    on: {
      "click": function click($event) {
        return _vm.Sorter('modifiedon');
      }
    }
  }, [_vm._v("geändert")])])]), _vm.filteredListLength > 0 ? _c('tbody', _vm._l(_vm.fPages[_vm.onpage - 1], function (item, index) {
    return _c('tr', [_c('td', {
      staticClass: "minw"
    }, [_c('router-link', {
      attrs: {
        "to": '/crmakt/' + item.aktname
      }
    }, [_vm._v(_vm._s(item.akt))])], 1), _c('td', [_c('span', [item.stid === 100000007 ? _c('i', {
      staticClass: "icon check"
    }) : item.stid === 100000008 ? _c('i', {
      staticClass: "icon times"
    }) : _c('i', {
      staticClass: "icon circle"
    }), _c('span', [_vm._v(" " + _vm._s((_vm.status[item.stid] || '').substring(0, 2)))])])]), _c('td', [_vm._v(_vm._s(item.vnehmer))]), _c('td', [_vm._v(_vm._s(item.ageber))]), _c('td', [_vm.sparten ? _c('span', [_c('span', {
      staticClass: "sparteart"
    }, [_vm._v(_vm._s((_vm.sparten.sparten[item.sparteart] || '-').substring(0, 7) + '..') + " -")]), _c('span', {
      staticClass: "sparteart"
    }, [_vm._v(_vm._s((_vm.sparten.details[item.spartedet] || '-').substring(0, 7) + '..') + " -")]), _c('span', {
      staticClass: "sparteart"
    }, [_vm._v(_vm._s((_vm.sparten.art[item.schadenart] || '-').substring(0, 7) + '..'))])]) : _vm._e()]), _c('td', {
      staticClass: "minw"
    }, [_c('a', {
      attrs: {
        "target": "_blank",
        "href": 'https://consense.crm4.dynamics.com/main.aspx?etc=10009&id=' + item.aid + '&Pagetype=entityrecord'
      }
    }, [_vm._v("CRM Link")])]), _c('td', {
      staticClass: "minw"
    }, [_vm._v(_vm._s(_vm.$dayjs(item.modifiedon).format('HH:mm:ss - DD.MM.YY')))])]);
  }), 0) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.filteredListLength == 0 ? _c('div', {
    staticClass: "padding tac"
  }, [_vm._currentUser.loadOnlyMy ? _c('span', [_c('h4', [_vm._v("Keine Daten geladen")]), _c('p', [_vm._v("In den Einstellungen kann angeben werden, was geladen werden soll.")]), _c('router-link', {
    attrs: {
      "to": "/useraccount"
    }
  }, [_vm._v("Einstellungen")])], 1) : !_vm._currentUser.loadOnlyMy ? _c('span', [_c('h4', [_vm._v("Keine Daten geladen")]), _c('p', [_vm._v("Bitte Suche/Filter kontrollieren oder neu laden")])]) : _vm._e()]) : _vm._e()]) : _vm._e()]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })]);
}];
export { render, staticRenderFns };