import _defineProperty from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
export default {
  firestorePath: 'insurers',
  firestoreRefType: 'collection',
  moduleName: 'insurers',
  statePropName: 'data',
  namespaced: true,
  getters: {
    byReference: function byReference(state) {
      return Object.values(state.data).reduce(function (acc, cur) {
        return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, cur.reference, cur));
      }, {});
    }
  }
};