var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal-container', {
    attrs: {
      "title": "Nachricht bearbeiten"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Text:")]), _c('div', {
    staticClass: "input"
  }, [_c('TipTap', {
    attrs: {
      "content": _vm.message,
      "ckey": 'message'
    }
  })], 1), _c('div', {
    staticClass: "input"
  }, [!_vm.savingMessage ? _c('button', {
    staticClass: "btn _mid _bordered",
    on: {
      "click": _vm.sendMessage
    }
  }, [_c('span', [_vm._v("Speichern")])]) : _c('span', [_c('i', {
    staticClass: "loading-spinner"
  })])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };