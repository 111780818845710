import { render, staticRenderFns } from "./VInputFile.vue?vue&type=template&id=a0102ac6&scoped=true&lang=pug&"
import script from "./VInputFile.vue?vue&type=script&lang=ts&"
export * from "./VInputFile.vue?vue&type=script&lang=ts&"
import style0 from "./VInputFile.vue?vue&type=style&index=0&id=a0102ac6&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0102ac6",
  null
  
)

export default component.exports