import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "printer noselect",
    attrs: {
      "id": "PrinterSVG"
    }
  }, [_c('div', {
    style: _vm.mainBridge,
    attrs: {
      "id": "mainBridgeSvg"
    },
    on: {
      "mouseup": function mouseup($event) {
        return _vm.ME($event);
      },
      "mousemove": function mousemove($event) {
        return _vm.MM($event);
      }
    }
  }, [_c('div', {
    staticClass: "mgroup",
    style: _vm.svg_style2,
    attrs: {
      "onmousedown": "return false"
    }
  }, [_c('div', {
    staticClass: "gimg"
  }, [_c('div', {
    staticClass: "img",
    style: _vm.makebgimg(_vm.preview)
  })]), _vm.showimg != _vm.preview && _vm.svg_zoom > _vm.triggerZoom ? _c('div', {
    staticClass: "gimg roott",
    style: _vm.rotated
  }, [_c('div', {
    staticClass: "img",
    style: _vm.makebgimg(_vm.showimg)
  })]) : _vm._e()])]), _c('div', {
    staticClass: "printerTexts"
  }, [_c('span', [_vm._v("ALT-Taste zum Zoomen! (ZOOM: " + _vm._s(_vm.svg_zoom.toFixed(2)) + ")")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };