import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-integer.js";
var self = null;
export default {
  name: "VPagination",
  beforeCreate: function beforeCreate() {
    self = this;
  },
  components: {},
  data: function data() {
    return {
      onpageLOC: 1
    };
  },
  // props: ["onpage", "fPages"],

  props: {
    onpage: {
      type: Number,
      required: true,
      default: 1,
      validator: function validator(value) {
        return Number.isInteger(value);
      }
    },
    fPages: {
      type: Array,
      required: true,
      default: []
      // validator: (value) => {
      //   return Number.isInteger(value)
      // }
    }
  },
  model: {
    prop: "onpage",
    event: "UpdateDate"
  },
  watch: {
    onpage: {
      immediate: true,
      handler: function handler(nv) {
        if (nv == 0) nv = 1;
        this.onpageLOC = this.$_cp(nv);
      }
    },
    onpageLOC: function onpageLOC(nv) {
      if (nv <= 0) {
        this.onpageLOC = 1;
        // this.onpageLOC = 0;
      }
      if (nv > this.fPages.length) {
        this.onpageLOC = this.fPages.length;
      }
      this.$emit("UpdateDate", parseInt(this.onpageLOC));
    },
    fPages: function fPages(nv) {
      if (nv.length < this.onpageLOC) {
        var result = nv.length;
        if (nv.length == 0) result = 1;
        this.$emit("UpdateDate", result);
      }
    }
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    /************************************************* 
         PaginationList 
         *************************************************/
    PaginationList: function PaginationList(onpage, fPagesLength) {
      if (fPagesLength == 0) fPagesLength = 1;
      if (onpage == 4) onpage = onpage + 1;else if (onpage == 3) onpage = onpage + 2;else if (onpage == 2) onpage = onpage + 3;else if (onpage == 1) onpage = onpage + 4;else if (onpage == 0) onpage = onpage + 5;else if (onpage > fPagesLength - 4) onpage = fPagesLength - 4;
      return [onpage - 4, onpage - 3, onpage - 2, onpage - 1, onpage, onpage + 1, onpage + 2, onpage + 3, onpage + 4];
    }
  }
};