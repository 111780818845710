// import {InsurersModule} from "@/insurers/index";
export default {
  firestorePath: 'templates',
  firestoreRefType: 'collection',
  moduleName: 'templates',
  statePropName: 'data',
  namespaced: true
  // getters: {
  //     byReference: (state: InsurersModule) =>
  //         Object.values(state.data).reduce((acc, cur) => ({...acc, [cur.reference]: cur}), {})
  // }
};