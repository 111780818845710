var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "notificationButton"
  }, [_c('v-popover', {
    attrs: {
      "offset": "3",
      "placement": "right"
    }
  }, [_c('button', {
    staticClass: "btn _mid"
  }, [_vm._v(_vm._s(_vm.buttonText))]), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList"
  }, [_c('li', {
    staticClass: "chooseList_li",
    on: {
      "click": function click($event) {
        return _vm.sendMsgNoCal();
      }
    }
  }, [_vm._v("  Akt NICHT abschließen und NICHT Verrechnung.")]), _c('li', {
    staticClass: "chooseList_li",
    on: {
      "click": function click($event) {
        return _vm.sendMsgWithCal();
      }
    }
  }, [_vm._v("  Akt abschließen und Verrechnung.")])])])], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };