import _slicedToArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
// ~store/fireStoreModules.js
import { protectedGroups as _protectedGroups } from '@/components/Notifications/groups';
import FB_insurers from '@/insurers/firestoreModule';
import FB_templates from '@/templates/firestoreModule';
var FB_allianzStatus = {
  firestorePath: "allianzStatus",
  firestoreRefType: "collection",
  // 'collection' or 'doc'
  moduleName: "FB_allianzStatus",
  // for state name
  statePropName: "data",
  // nested object name
  namespaced: true,
  // automatically added
  sync: {
    // debounceTimerMs: 500,
    guard: ["created_at", "updated_at", "created_by", "updated_by"],
    patchHookBeforeSync: function patchHookBeforeSync(updateFirestore, doc, store) {
      doc.modified = Date.now();
      updateFirestore(doc);
    }
  }
};
var FB_general = {
  firestorePath: "general/general",
  firestoreRefType: "doc",
  // 'collection' or 'doc'
  moduleName: "FB_general",
  // for state name
  statePropName: "data",
  // nested object name
  namespaced: true,
  // automatically added

  sync: {
    // debounceTimerMs: 500,
    preventInitialDocInsertion: true,
    guard: ["created_at", "updated_at", "created_by", "updated_by"],
    patchHookBeforeSync: function patchHookBeforeSync(updateFirestore, doc, store) {
      doc.modified = Date.now();
      // updateFirestore(doc)
    }
  }
};
var FB_messageTemplates = {
  firestorePath: "general/messageTemplates",
  firestoreRefType: "doc",
  // 'collection' or 'doc'
  moduleName: "FB_messageTemplates",
  // for state name
  statePropName: "data",
  // nested object name
  namespaced: true,
  // automatically added

  sync: {
    // debounceTimerMs: 500,
    preventInitialDocInsertion: true,
    guard: ["created_at", "updated_at", "created_by", "updated_by"],
    patchHookBeforeSync: function patchHookBeforeSync(updateFirestore, doc, store) {
      doc.modified = Date.now();
      // updateFirestore(doc)
    }
  }
};
var FB_notificationGroups = {
  firestorePath: "notificationGroups/notificationGroups",
  firestoreRefType: "doc",
  // 'collection' or 'doc'
  moduleName: "FB_notificationGroups",
  // for state name
  statePropName: "data",
  // nested object name
  namespaced: true,
  // automatically added
  sync: {
    preventInitialDocInsertion: true,
    guard: ["created_at", "updated_at", "created_by", "updated_by"],
    patchHookBeforeSync: function patchHookBeforeSync(updateFirestore, doc, store) {
      doc.modified = Date.now();
    }
  },
  getters: {
    groups: function groups(s) {
      return Object.fromEntries(Object.entries(s.data).filter(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
          k = _ref2[0];
        return k !== 'id';
      }));
    },
    protectedGroups: function protectedGroups(_, g) {
      return Object.fromEntries(Object.entries(g.groups).filter(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 1),
          k = _ref4[0];
        return _protectedGroups.includes(k);
      }));
    },
    userDefinedGroups: function userDefinedGroups(_, g) {
      return Object.fromEntries(Object.entries(g.groups).filter(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 1),
          k = _ref6[0];
        return !_protectedGroups.includes(k);
      }));
    }
  }
};
export default [FB_general, FB_messageTemplates, FB_notificationGroups, FB_allianzStatus, FB_insurers, FB_templates];