var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block"
  }, [_vm._t("before"), _c('div', {
    staticClass: "content"
  }, [_vm._t("default")], 2), _vm._t("after")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };