var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "AllianzAddNewProject noselect"
  }, [_c('modal', {
    staticClass: "modal",
    attrs: {
      "name": "AllianzAddNewProject",
      "adaptive": true,
      "scrollable": true,
      "reset": true,
      "width": "960",
      "height": "auto"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.theCurrentProject))]), _c('AllianzAktRawData', {
    attrs: {
      "currentCoordinates": _vm.theCurrentProject.loc
    },
    on: {
      "updateLocation": function updateLocation($event) {
        _vm.theCurrentProject.loc = $event;
      }
    },
    model: {
      value: _vm.theCurrentProject.allianzData,
      callback: function callback($$v) {
        _vm.$set(_vm.theCurrentProject, "allianzData", $$v);
      },
      expression: "theCurrentProject.allianzData"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.theCurrentProject.consenseNr,
      expression: "theCurrentProject.consenseNr"
    }],
    domProps: {
      "value": _vm.theCurrentProject.consenseNr
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.theCurrentProject, "consenseNr", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.theCurrentProject.generalInfo,
      expression: "theCurrentProject.generalInfo"
    }],
    domProps: {
      "value": _vm.theCurrentProject.generalInfo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.theCurrentProject, "generalInfo", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": _vm.addNewProjectToFb
    }
  }, [_vm._v("Akt neu anlegen")]), _c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('AllianzAddNewProject');
      }
    }
  }, [_vm._v("Schließen")])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };