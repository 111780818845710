import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store";
Vue.use(Router);
var adminRoute = function adminRoute(_ref) {
  var path = _ref.path,
    name = _ref.name,
    component = _ref.component;
  return {
    path: path,
    name: name,
    component: component,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  };
};
var router = new Router({
  // mode: "history",
  routes: [
  // FOR ALL
  {
    path: "/login",
    name: "login",
    component: function component() {
      return import("@/views/Login.vue");
    }
  },
  // FOR ALL AUTH
  {
    path: "/useraccount",
    name: "UserAccount",
    component: function component() {
      return import("@/views/UserAccount.vue");
    },
    meta: {
      // requiresAuth: true,
    }
  }, {
    path: "/",
    // redirect: "/home",
    name: 'Home',
    component: function component() {
      return import("@/views/Home.vue");
    },
    meta: {
      // requiresAuth: true
    }
  }, {
    path: "/dashboard",
    name: "dashboard",
    component: function component() {
      return import("@/views/DashBoard.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmprojects']
    }
  }, {
    path: "/feedback",
    name: "feedback",
    component: function component() {
      return import("@/views/Feedback.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true
    }
  }, {
    path: "/changelogs",
    name: "changelogs",
    component: function component() {
      return import("@/views/ChangeLogs.vue");
    },
    meta: {
      // requiresNoRole: true,
      requiresAuth: true
    }
  },
  // CRM
  {
    path: "/list",
    name: "list",
    component: function component() {
      return import("@/views/Crm/CrmList/CrmList.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmprojects']
    }
  }, {
    path: "/listv2",
    name: "listv2",
    component: function component() {
      return import("@/views/Crm/CrmList/CrmListV2.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmprojects']
    }
  }, {
    path: "/crm-tasks",
    name: "crm-tasks",
    component: function component() {
      return import("@/views/Crm/CrmTasks/CrmTasks.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmprojects']
    }
  }, {
    path: "/crmakt/:id",
    name: "CrmAktDetails",
    component: function component() {
      return import("@/views/Crm/CrmAkt/CrmAktDetails.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true
      // load:['crmprojects']
    }
  }, {
    path: "/sharepoint/:id",
    name: "sharepoint",
    component: function component() {
      return import("@/views/sharepoint/sharepoint.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true
      // load:['crmprojects']
    }
  }, {
    path: "/entity/:folder/:entity",
    name: "entity",
    component: function component() {
      return import("@/views/entity.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true
    }
  }, {
    path: "/map",
    name: "map",
    component: function component() {
      return import("@/views/Crm/MapV2.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmprojects', 'crmsv']
    }
  }, {
    path: "/calendar",
    name: "calendar",
    component: function component() {
      return import("@/views/Crm/Calendar.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmsv', 'crmsv']
    }
  }, {
    path: "/schedule",
    name: "schedule",
    component: function component() {
      return import("@/views/Crm/Schedule.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmprojects', 'crmsv']
    }
  }, {
    path: "/notifications",
    name: "notifications",
    component: function component() {
      return import("@/views/NotificationsView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmsv', 'crmprojects']
    }
  }, {
    path: "/urgences",
    name: "urgences",
    component: function component() {
      return import("@/views/UrgencesView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresCrm: true,
      load: ['crmsv']
    }
  },
  // ADMIN
  {
    path: "/users",
    name: "Users",
    component: function component() {
      return import("@/views/Users.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }, adminRoute({
    path: '/insurers',
    name: 'Insurers',
    component: function component() {
      return import("@/insurers/InsurersPage.vue");
    }
  }), adminRoute({
    path: '/templates',
    name: 'Vorlagen',
    component: function component() {
      return import("@/templates/Templates.vue");
    }
  }), {
    path: "/admin",
    name: "admin",
    component: function component() {
      return import("@/views/Admin.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  // ALLIANZ
  {
    path: "/allianz/list",
    name: "AllianzList",
    component: function component() {
      return import("@/views/Allianz/AllianzList.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/list/:id",
    name: "AllianzListId",
    component: function component() {
      return import("@/views/Allianz/TheAkt/AllianzAkt.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/hvw",
    name: "AllianzHVW",
    component: function component() {
      return import("@/views/Allianz/AllianzHVW.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/hvw/:id",
    name: "AllianzHvwId",
    // component: () => import("@/views/Allianz/TheAkt/AllianzHVW.vue"),
    component: function component() {
      return import("@/views/Allianz/AllianzHvwId.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/map",
    name: "AllianzMap",
    component: function component() {
      return import("@/views/Allianz/AllianzMap.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/status",
    name: "AllianzStatus",
    component: function component() {
      return import("@/views/Allianz/AllianzStatus.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/uploads",
    name: "AllianzUploads",
    component: function component() {
      return import("@/views/Allianz/AllianzUploads.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/reminder",
    name: "AllianzReminder",
    component: function component() {
      return import("@/views/Allianz/AllianzReminder.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/reports",
    name: "AllianzReports",
    component: function component() {
      return import("@/views/Allianz/AllianzReports.vue");
    },
    meta: {
      // allowedRoles:[''],
      requiresAuth: true,
      requiresAllianz: true,
      requiresAllianzReports: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/wiki",
    name: "Wiki",
    component: function component() {
      return import("@/views/Allianz/Wiki.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true,
      load: ['allianz']
    }
  }, {
    path: "/allianz/stats",
    name: "StatsAllianz",
    component: function component() {
      return import("@/views/Allianz/StatsAllianz.vue");
    },
    meta: {
      requiresAuth: true,
      requiresAllianz: true
    }
  },
  // {
  //   path: "/allianz/:id",
  //   name: "AllianzListRedirect",
  //   redirect: "/allianz/list/:id",
  //   // component: () => import("@/views/Allianz/AllianzList.vue"),
  //   // meta: {
  //   //   requiresAuth: true,
  //   //   requiresAllianz: true
  //   // }
  // },
  // {
  //   path: "/allianzdownloads",
  //   name: "allianzdownloads",
  //   component: () => import("@/views/Allianz/AllianzAccount.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     requiresAllianz: true,
  //     requiresReports: true
  //     // requiresAdmin: true // NOT VAID
  //   }
  // },
  // FREE STUFF
  // HVW LINKS AKTE
  {
    path: "/akte/:id",
    name: "AllianzLinkHvw",
    component: function component() {
      return import("@/views/Allianz/OpenLinks/LinkHVW.vue");
    }
  }, {
    path: "/akte/:id/:uid",
    name: "AllianzLinkObjekt",
    component: function component() {
      return import("@/views/Allianz/OpenLinks/LinkObjekt.vue");
    }
  }]
});
router.beforeEach(function (to, from, next) {
  var p1 = to.query.noload ? "&noload=true" : "";
  var p2 = to.query.onlysp ? "&onlysp=true" : "";
  // ?console.log(vu.$route.query.onlySP);
  // ?noload=1&onlysp=1
  var params = "?redirect=" + to.path + p1 + p2;
  // isShowingOnlySp
  // store.commit('set', ['isShowingOnlySp', !!JSON.parse(((to.query || {}).onlysp || 'false') + '')])
  // store.commit('set', ['isAllowToFetchData', !!JSON.parse(((to.query || {}).noload || 'false') + '')])
  var requiresAuth = to.matched.some(function (record) {
    return record.meta.requiresAuth;
  });
  var requiresAdmin = to.matched.some(function (record) {
    return record.meta.requiresAdmin;
  });
  var requiresCrm = to.matched.some(function (record) {
    return record.meta.requiresCrm;
  });
  var requiresAllianz = to.matched.some(function (record) {
    return record.meta.requiresAllianz;
  });
  var requiresAllianzReports = to.matched.some(function (record) {
    return record.meta.requiresAllianzReports;
  });
  var requiresNoRole = to.matched.some(function (record) {
    return record.meta.requiresNoRole;
  });
  var _isAdmin = store.getters._isAdmin || store.getters._isGf;
  var isFb = store.getters.isFb;
  var _isSv = store.getters._isSv;
  var isNoRole = store.getters.isNoRole;
  var svRights = store.getters.svRights;
  var allowCRM = svRights.crmReadWriteOwn || svRights.crmReadWriteAll || _isAdmin || false;
  var allowAllianz = svRights.allianzReadWriteOwn || svRights.allianzReadWriteAll || _isAdmin || false;
  var allowAllianzReports = svRights.allianzReadReports || allowAllianz || _isAdmin || false;
  if (requiresAdmin) {
    if (_isAdmin) next();else next("/useraccount");
  }
  if (requiresAuth) {
    if (_isAdmin) {
      next();
    } else if (_isSv) {
      if (requiresCrm && allowCRM) next();else if (requiresAllianz && allowAllianz) next();else if (requiresAllianzReports && allowAllianzReports) next();else if (to.path == '/useraccount') next();else if (to.path == '/changelogs') next();
    } else if (isNoRole) {
      if (requiresNoRole) next();else next("/login" + params);
    } else next("/login" + params);
  } else {
    next();
  }
});
export default router;