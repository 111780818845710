import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    staticClass: "radiochecker noselect"
  }, [_vm._l(_vm.options, function (opt, idx) {
    return [_vm.template[opt.opt] ? [!_vm.template.multiple ? _c('div', {
      staticClass: "input-field"
    }, [_c('div', {
      staticClass: "input"
    }, [_c('v-select', {
      attrs: {
        "taggable": "",
        "options": _vm.template[opt.opt],
        "reduce": _vm.reduce
      },
      model: {
        value: _vm.item[opt.val],
        callback: function callback($$v) {
          _vm.$set(_vm.item, opt.val, $$v);
        },
        expression: "item[opt.val]"
      }
    })], 1)]) : _vm.template.multiple ? _c('div', {
      staticClass: "input-field"
    }, [_c('div', {
      staticClass: "input"
    }, [_c('v-select', {
      attrs: {
        "multiple": "",
        "taggable": "",
        "options": _vm.template[opt.opt],
        "reduce": _vm.reduce
      },
      model: {
        value: _vm.item[opt.vals],
        callback: function callback($$v) {
          _vm.$set(_vm.item, opt.vals, $$v);
        },
        expression: "item[opt.vals]"
      }
    })], 1)]) : _vm._e()] : _vm._e()];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };