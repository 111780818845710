import mixinSvg from "./mixinSVG";
var vu = null;
export default {
  mixins: [mixinSvg],
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  props: ["showimg", "preview", "rot"],
  // props: ["previewFilePath", "onlyOriginal", "isPureUrl"],
  data: function data() {
    return {
      triggerZoom: 3,
      innerwidth: 1280,
      innerheight: 1280,
      rotar: 0,
      svg_zoom: 1,
      svg_moveX: 0,
      svg_moveY: 0
    };
  },
  watch: {
    rotar: function rotar(nv) {
      // console.log(nv);
    },
    rot: function rot(nv) {
      vu.svg_zoom = 1;
      vu.svg_moveX = 1;
      vu.svg_moveY = 1;
    }
  },
  computed: {
    mainBridge: function mainBridge() {
      return {
        width: vu.innerwidth,
        height: vu.innerheight
      };
    },
    rotated: function rotated() {
      if (vu.rotar == 0) {
        return "transform : translate(-500px,-500px) rotateZ(" + vu.rotar + "deg)";
      } else if (vu.rotar == 90) {
        return "transform : translate(-500px,-500px) rotateZ(" + vu.rotar + "deg)";
      } else if (vu.rotar == 180) {
        return "transform : translate(-500px,-500px) rotateZ(" + vu.rotar + "deg)";
      } else if (vu.rotar == 270) {
        return "transform : translate(-500px,-500px) rotateZ(" + vu.rotar + "deg)";
      } else if (vu.rotar == 360) {
        vu.rotar = 0;
        return "transform : translate(-500px,-500px) rotateZ(" + vu.rotar + "deg)";
      } else if (vu.rotar == -90) {
        vu.rotar = 270;
      }
    }
  },
  mounted: function mounted() {},
  created: function created() {
    vu.rotar = 0;
  },
  methods: {
    makebgimg: function makebgimg(img) {
      return "background-image: url('" + img + "')";
    }
  }
};