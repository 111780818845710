import crmstuff from "@/assets/json/crmstuff.js";
function initialState() {
  return {
    userLogin: {
      isUserCurrentlySigningIn: false,
      isSignedUpFb: false,
      isLoadingUserLoginFb: false,
      isSignedUpCrm: false,
      isLoadingUserLoginCrm: false,
      isLoadingUserSignOut: false
    },
    userDataFb: {
      uid: '',
      roles: {},
      rights: {},
      userRole: 'no'
    },
    userDataCrm: {
      consenseToken: '',
      accessToken: '',
      spToken: '',
      cookie: '',
      userId: '',
      userName: '',
      expiresOn: '',
      ctexpireson: '' // FOR CONSENSE SERVER TOKEN
    },
    isAutoSaveGa: false,
    // user: {
    //   auth: false,
    //   wait: false,
    //   wholestate: false, // IF SV WANT THE WHOLE STATE!
    // },
    isUploadingOfflineFiles: false,
    uploadInfo: '',
    foruser: "",
    // THIS ONLY FROM CRM:
    projects: {},
    commissions: {},
    xlist: {},
    folder: [],
    svlist: {},
    maplist: {},
    modifiedSince: 0,
    fbDataLoaded: false,
    fbDataLoading: false,
    crmDataLoaded: false,
    crmDataLoading: false,
    filterMyAkte: false,
    filterMyAkteSv1: false,
    filterStatus: {
      z1: false,
      m2: false,
      m3: false,
      h5: false
    },
    isShowOnlyUrgent: false,
    isShowOnlyDs: false,
    isShowOnlyMa: false,
    is2023Projects: false,
    isMfhSFP: false,
    // isa03mio: false,
    // isShowOnlyVisible: false,
    isShowOnlyVisible: true,
    // // NEW STATE!!
    MY_allianzProjects: {
      data: {}
    },
    // MY_allianzProjects: {},
    FB_allianzProjects: {
      data: {}
    },
    FB_allianzHausverwaltungen: {
      data: {}
    },
    FB_users_uid: {
      data: {}
    },
    FB_users: {
      data: {}
    },
    FB_notificationGroups: {
      data: {}
    },
    // FB_allianzProjects: { data: {} },
    // FB_allianzHausverwaltungen: { data: {} },
    FB_allianzStatus: {
      data: {}
    },
    // FB_allianzReminder: { data: {} },
    DL_reports: [],
    iperpage: 30,
    SaveLaterFS: {},
    svg_moveY: 0,
    svg_moveX: 0,
    svg_zoom: 1,
    inited: false,
    lastcalls: {},
    lastCallsTimes: {
      loadAllianz: 0,
      loadCrmProjects: 0,
      loadCrmSv: 0,
      loadCrmMap: 0
    },
    selTab: "general",
    isShowingOnlySp: false,
    firsttimecalls: false,
    connected: true,
    showInAllianzTable: [{
      type: "status",
      value: true,
      name: "Status",
      roles: []
    }, {
      type: "polnr",
      value: true,
      name: "Pol.Nr.",
      roles: []
    }, {
      type: "adresse_risiko",
      value: true,
      name: "Adresse",
      roles: []
    }, {
      type: "contact_allianz",
      value: true,
      name: "Kontakt (Allianz)",
      roles: []
    }, {
      type: "vs_feuer",
      value: true,
      name: "Vsumme",
      roles: []
    }, {
      type: "contacts",
      value: true,
      name: "Kontakte",
      roles: []
    }, {
      type: "hvUid",
      value: true,
      name: "HV",
      roles: []
    }, {
      type: "svuid",
      value: true,
      name: "Bearbeiter",
      roles: []
    }, {
      type: "modified",
      value: true,
      name: "bearbeitet am",
      roles: []
    }, {
      type: "reportedDataModified",
      value: true,
      name: "abgeschloßen am",
      roles: []
    }, {
      type: "svStatus",
      value: true,
      name: "SV Status",
      roles: []
    }, {
      type: "paidStatus",
      value: false,
      name: "Bezahlstatus",
      roles: ["admin", "gf"]
    }, {
      type: "fillByHvAt",
      value: true,
      name: "Von HV befüllt am",
      roles: []
    }, {
      type: "efforts",
      value: false,
      name: "Mehraufwand",
      roles: ["admin", "gf"]
    }, {
      type: "isCustomPdf",
      value: false,
      name: "Eigenes PDF",
      roles: ["admin", "gf"]
    }, {
      type: "is2023Projects",
      value: false,
      name: "neu ab 2023",
      roles: ["admin", "gf"]
    }],
    allowedSv2Status: ["195", "194", "181", "162", "230", "211", "197", "553", "774", "743"],
    usermetadata: {
      /*remove usermetadata.*/
      leaveopen: false,
      since: true,
      svfilter: true,
      combinedStatusFilter: {},
      statusfilter: {
        "100000003": true
      },
      artfilter: {
        "100000000": true // "Auftrag", //B
      },
      dayover: false,
      // isAutoSaveGa:true,
      datefilter: "",
      datefilterto: "",
      sortmaplist: "datehr",
      mapStyleName: "light",
      calendar_sortsv: "factor",
      calendar_sortsvreverse: true,
      calendar_showonlyfactor: true,
      SVLocalInMap: {}
    },
    // OTHER CRM PREDEFINED DATA
    svfactor: crmstuff.svfactor,
    arten: crmstuff.arten,
    status: crmstuff.status,
    sparten: crmstuff.sparten,
    tatigkeit: crmstuff.tatigkeit,
    einheiten: crmstuff.einheiten,
    hntexte: crmstuff.hntexte,
    ereignisse: crmstuff.ereignisse,
    hntyp: crmstuff.hntyp,
    statusColors: crmstuff.statusColors,
    combinedStatus: crmstuff.combinedStatus,
    kindOptions: crmstuff.kindOptions,
    globalSearch: "",
    extraAllianzFilterFields: {
      resolvedonlystatus: '',
      plz_risiko: '',
      ortschaft_risiko: '',
      resolvedhv: '',
      makler: '',
      eigen: '',
      resolvedsv: ''
    },
    // search: "", // global searcher
    // heresince: Date.now() + 12000 ,
    isLoading: false,
    isInternet: true,
    mobile: {},
    isUsingOffline: true,
    isWsConnected: false,
    ipCalls: [],
    apiLogs: {}
  };
}
export default initialState;