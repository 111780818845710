import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var localDevUrl = "http://127.0.0.1:6777/";
// let localDevUrl = "http://192.168.1.104:6777/"

var localLiveUrl = "http://127.0.0.1:6779/";
var onlineDevUrl = "https://api.consense.at/dev/";
var onlineLiveUrl = "https://api.consense.at/";
// http://127.0.0.1:6779

var thisurl = window.location.origin + "/";
var thisHref = window.location.href;
var isDebug = false;
//REDIRECT IF NOT ON APP!!
var redirectUrls = [
// "consense-dev.web.app",
"consensewp.firebaseapp.com", "consensewp.web.app"
// "127.0.0.1:8181"
];
var toUrl = 'app.consense.at';
for (var _i = 0, _redirectUrls = redirectUrls; _i < _redirectUrls.length; _i++) {
  var url = _redirectUrls[_i];
  if (thisHref.includes(url)) {
    thisHref = thisHref.replace(url, toUrl);
    console.log('new url', thisHref);
    window.location.href = thisHref;
    break;
  }
}
var mainurl = onlineLiveUrl; // FALLBACK!!
if (thisurl.includes(':7777')) {
  isDebug = true;
  mainurl = localDevUrl;
} else if (thisurl.includes(':8181')) {
  isDebug = true;
  // mainurl = onlineLiveUrl
  mainurl = localLiveUrl;
} else if (thisurl.includes('consense-dev.web.app')) {
  // mainurl = onlineDevUrl
  mainurl = 'https://propellercat.com/consense-dev/';
  isDebug = true;
} else if (thisurl.includes('consensewp.web.app')) {
  mainurl = onlineLiveUrl;
} else if (thisurl.includes('app.consense.at')) {
  mainurl = onlineLiveUrl;
}
import Debug from '@/libs/debugger';
var version = require("../package.json").version;
var result = {
  version: "" + version,
  mainurl: mainurl,
  loc: thisurl,
  state: process.env.VUE_APP_ENV || "-"
};
Debug.INIT(isDebug);
window.env = result;
export default result;