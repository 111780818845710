var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal', {
    staticClass: "noselect",
    attrs: {
      "name": "ChooseImagesForBlock",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "1420",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpenModal
    }
  }, [_vm.content ? [_c('image-chooser', {
    attrs: {
      "content": _vm.content,
      "maxImages": _vm.maxImages,
      "blockedImages": _vm.blockedImages
    },
    on: {
      "close": function close($event) {
        return _vm.$modal.hide('ChooseImagesForBlock');
      }
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };