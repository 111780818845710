var _typeof = require("/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/typeof.js").default;
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.sort.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.array.find-index.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.string.split.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.string.search.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.global-this.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.array-buffer.constructor.js");
require("core-js/modules/es.array-buffer.slice.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.get-prototype-of.js");
!function (e, t) {
  "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) && "undefined" != typeof module ? t(exports) : "function" == typeof define && define.amd ? define(["exports"], t) : t((e = e || self)["v-tooltip"] = {});
}(this, function (e) {
  "use strict";

  function t(e) {
    return (t = "function" == typeof Symbol && "symbol" == _typeof(Symbol.iterator) ? function (e) {
      return _typeof(e);
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : _typeof(e);
    })(e);
  }
  function n(e, t) {
    for (var n = 0; n < t.length; n++) {
      var o = t[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
    }
  }
  function o(e, t, n) {
    return t in e ? Object.defineProperty(e, t, {
      value: n,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = n, e;
  }
  function r(e, t) {
    var n = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
      var o = Object.getOwnPropertySymbols(e);
      t && (o = o.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      })), n.push.apply(n, o);
    }
    return n;
  }
  function i(e) {
    for (var t = 1; t < arguments.length; t++) {
      var n = null != arguments[t] ? arguments[t] : {};
      t % 2 ? r(Object(n), !0).forEach(function (t) {
        o(e, t, n[t]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n)) : r(Object(n)).forEach(function (t) {
        Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t));
      });
    }
    return e;
  }
  var s = "undefined" != typeof window && "undefined" != typeof document && "undefined" != typeof navigator,
    a = function () {
      for (var e = ["Edge", "Trident", "Firefox"], t = 0; t < e.length; t += 1) if (s && navigator.userAgent.indexOf(e[t]) >= 0) return 1;
      return 0;
    }();
  var u = s && window.Promise ? function (e) {
    var t = !1;
    return function () {
      t || (t = !0, window.Promise.resolve().then(function () {
        t = !1, e();
      }));
    };
  } : function (e) {
    var t = !1;
    return function () {
      t || (t = !0, setTimeout(function () {
        t = !1, e();
      }, a));
    };
  };
  function p(e) {
    return e && "[object Function]" === {}.toString.call(e);
  }
  function l(e, t) {
    if (1 !== e.nodeType) return [];
    var n = e.ownerDocument.defaultView.getComputedStyle(e, null);
    return t ? n[t] : n;
  }
  function f(e) {
    return "HTML" === e.nodeName ? e : e.parentNode || e.host;
  }
  function c(e) {
    if (!e) return document.body;
    switch (e.nodeName) {
      case "HTML":
      case "BODY":
        return e.ownerDocument.body;
      case "#document":
        return e.body;
    }
    var t = l(e),
      n = t.overflow,
      o = t.overflowX,
      r = t.overflowY;
    return /(auto|scroll|overlay)/.test(n + r + o) ? e : c(f(e));
  }
  function d(e) {
    return e && e.referenceNode ? e.referenceNode : e;
  }
  var h = s && !(!window.MSInputMethodContext || !document.documentMode),
    v = s && /MSIE 10/.test(navigator.userAgent);
  function m(e) {
    return 11 === e ? h : 10 === e ? v : h || v;
  }
  function g(e) {
    if (!e) return document.documentElement;
    for (var t = m(10) ? document.body : null, n = e.offsetParent || null; n === t && e.nextElementSibling;) n = (e = e.nextElementSibling).offsetParent;
    var o = n && n.nodeName;
    return o && "BODY" !== o && "HTML" !== o ? -1 !== ["TH", "TD", "TABLE"].indexOf(n.nodeName) && "static" === l(n, "position") ? g(n) : n : e ? e.ownerDocument.documentElement : document.documentElement;
  }
  function b(e) {
    return null !== e.parentNode ? b(e.parentNode) : e;
  }
  function y(e, t) {
    if (!(e && e.nodeType && t && t.nodeType)) return document.documentElement;
    var n = e.compareDocumentPosition(t) & Node.DOCUMENT_POSITION_FOLLOWING,
      o = n ? e : t,
      r = n ? t : e,
      i = document.createRange();
    i.setStart(o, 0), i.setEnd(r, 0);
    var s,
      a,
      u = i.commonAncestorContainer;
    if (e !== u && t !== u || o.contains(r)) return "BODY" === (a = (s = u).nodeName) || "HTML" !== a && g(s.firstElementChild) !== s ? g(u) : u;
    var p = b(e);
    return p.host ? y(p.host, t) : y(e, b(t).host);
  }
  function _(e) {
    var t = "top" === (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "top") ? "scrollTop" : "scrollLeft",
      n = e.nodeName;
    if ("BODY" === n || "HTML" === n) {
      var o = e.ownerDocument.documentElement;
      return (e.ownerDocument.scrollingElement || o)[t];
    }
    return e[t];
  }
  function w(e, t) {
    var n = "x" === t ? "Left" : "Top",
      o = "Left" === n ? "Right" : "Bottom";
    return parseFloat(e["border" + n + "Width"]) + parseFloat(e["border" + o + "Width"]);
  }
  function O(e, t, n, o) {
    return Math.max(t["offset" + e], t["scroll" + e], n["client" + e], n["offset" + e], n["scroll" + e], m(10) ? parseInt(n["offset" + e]) + parseInt(o["margin" + ("Height" === e ? "Top" : "Left")]) + parseInt(o["margin" + ("Height" === e ? "Bottom" : "Right")]) : 0);
  }
  function E(e) {
    var t = e.body,
      n = e.documentElement,
      o = m(10) && getComputedStyle(n);
    return {
      height: O("Height", t, n, o),
      width: O("Width", t, n, o)
    };
  }
  var C = function C(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    },
    j = function () {
      function e(e, t) {
        for (var n = 0; n < t.length; n++) {
          var o = t[n];
          o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, o.key, o);
        }
      }
      return function (t, n, o) {
        return n && e(t.prototype, n), o && e(t, o), t;
      };
    }(),
    T = function T(e, t, n) {
      return t in e ? Object.defineProperty(e, t, {
        value: n,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[t] = n, e;
    },
    $ = Object.assign || function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var n = arguments[t];
        for (var o in n) Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
      }
      return e;
    };
  function x(e) {
    return $({}, e, {
      right: e.left + e.width,
      bottom: e.top + e.height
    });
  }
  function S(e) {
    var t = {};
    try {
      if (m(10)) {
        t = e.getBoundingClientRect();
        var n = _(e, "top"),
          o = _(e, "left");
        t.top += n, t.left += o, t.bottom += n, t.right += o;
      } else t = e.getBoundingClientRect();
    } catch (e) {}
    var r = {
        left: t.left,
        top: t.top,
        width: t.right - t.left,
        height: t.bottom - t.top
      },
      i = "HTML" === e.nodeName ? E(e.ownerDocument) : {},
      s = i.width || e.clientWidth || r.width,
      a = i.height || e.clientHeight || r.height,
      u = e.offsetWidth - s,
      p = e.offsetHeight - a;
    if (u || p) {
      var f = l(e);
      u -= w(f, "x"), p -= w(f, "y"), r.width -= u, r.height -= p;
    }
    return x(r);
  }
  function k(e, t) {
    var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
      o = m(10),
      r = "HTML" === t.nodeName,
      i = S(e),
      s = S(t),
      a = c(e),
      u = l(t),
      p = parseFloat(u.borderTopWidth),
      f = parseFloat(u.borderLeftWidth);
    n && r && (s.top = Math.max(s.top, 0), s.left = Math.max(s.left, 0));
    var d = x({
      top: i.top - s.top - p,
      left: i.left - s.left - f,
      width: i.width,
      height: i.height
    });
    if (d.marginTop = 0, d.marginLeft = 0, !o && r) {
      var h = parseFloat(u.marginTop),
        v = parseFloat(u.marginLeft);
      d.top -= p - h, d.bottom -= p - h, d.left -= f - v, d.right -= f - v, d.marginTop = h, d.marginLeft = v;
    }
    return (o && !n ? t.contains(a) : t === a && "BODY" !== a.nodeName) && (d = function (e, t) {
      var n = arguments.length > 2 && void 0 !== arguments[2] && arguments[2],
        o = _(t, "top"),
        r = _(t, "left"),
        i = n ? -1 : 1;
      return e.top += o * i, e.bottom += o * i, e.left += r * i, e.right += r * i, e;
    }(d, t)), d;
  }
  function N(e) {
    if (!e || !e.parentElement || m()) return document.documentElement;
    for (var t = e.parentElement; t && "none" === l(t, "transform");) t = t.parentElement;
    return t || document.documentElement;
  }
  function L(e, t, n, o) {
    var r = arguments.length > 4 && void 0 !== arguments[4] && arguments[4],
      i = {
        top: 0,
        left: 0
      },
      s = r ? N(e) : y(e, d(t));
    if ("viewport" === o) i = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
        n = e.ownerDocument.documentElement,
        o = k(e, n),
        r = Math.max(n.clientWidth, window.innerWidth || 0),
        i = Math.max(n.clientHeight, window.innerHeight || 0),
        s = t ? 0 : _(n),
        a = t ? 0 : _(n, "left");
      return x({
        top: s - o.top + o.marginTop,
        left: a - o.left + o.marginLeft,
        width: r,
        height: i
      });
    }(s, r);else {
      var a = void 0;
      "scrollParent" === o ? "BODY" === (a = c(f(t))).nodeName && (a = e.ownerDocument.documentElement) : a = "window" === o ? e.ownerDocument.documentElement : o;
      var u = k(a, s, r);
      if ("HTML" !== a.nodeName || function e(t) {
        var n = t.nodeName;
        if ("BODY" === n || "HTML" === n) return !1;
        if ("fixed" === l(t, "position")) return !0;
        var o = f(t);
        return !!o && e(o);
      }(s)) i = u;else {
        var p = E(e.ownerDocument),
          h = p.height,
          v = p.width;
        i.top += u.top - u.marginTop, i.bottom = h + u.top, i.left += u.left - u.marginLeft, i.right = v + u.left;
      }
    }
    var m = "number" == typeof (n = n || 0);
    return i.left += m ? n : n.left || 0, i.top += m ? n : n.top || 0, i.right -= m ? n : n.right || 0, i.bottom -= m ? n : n.bottom || 0, i;
  }
  function A(e, t, n, o, r) {
    var i = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : 0;
    if (-1 === e.indexOf("auto")) return e;
    var s = L(n, o, i, r),
      a = {
        top: {
          width: s.width,
          height: t.top - s.top
        },
        right: {
          width: s.right - t.right,
          height: s.height
        },
        bottom: {
          width: s.width,
          height: s.bottom - t.bottom
        },
        left: {
          width: t.left - s.left,
          height: s.height
        }
      },
      u = Object.keys(a).map(function (e) {
        return $({
          key: e
        }, a[e], {
          area: (t = a[e], t.width * t.height)
        });
        var t;
      }).sort(function (e, t) {
        return t.area - e.area;
      }),
      p = u.filter(function (e) {
        var t = e.width,
          o = e.height;
        return t >= n.clientWidth && o >= n.clientHeight;
      }),
      l = p.length > 0 ? p[0].key : u[0].key,
      f = e.split("-")[1];
    return l + (f ? "-" + f : "");
  }
  function P(e, t, n) {
    var o = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : null;
    return k(n, o ? N(t) : y(t, d(n)), o);
  }
  function D(e) {
    var t = e.ownerDocument.defaultView.getComputedStyle(e),
      n = parseFloat(t.marginTop || 0) + parseFloat(t.marginBottom || 0),
      o = parseFloat(t.marginLeft || 0) + parseFloat(t.marginRight || 0);
    return {
      width: e.offsetWidth + o,
      height: e.offsetHeight + n
    };
  }
  function I(e) {
    var t = {
      left: "right",
      right: "left",
      bottom: "top",
      top: "bottom"
    };
    return e.replace(/left|right|bottom|top/g, function (e) {
      return t[e];
    });
  }
  function M(e, t, n) {
    n = n.split("-")[0];
    var o = D(e),
      r = {
        width: o.width,
        height: o.height
      },
      i = -1 !== ["right", "left"].indexOf(n),
      s = i ? "top" : "left",
      a = i ? "left" : "top",
      u = i ? "height" : "width",
      p = i ? "width" : "height";
    return r[s] = t[s] + t[u] / 2 - o[u] / 2, r[a] = n === a ? t[a] - o[p] : t[I(a)], r;
  }
  function z(e, t) {
    return Array.prototype.find ? e.find(t) : e.filter(t)[0];
  }
  function H(e, t, n) {
    return (void 0 === n ? e : e.slice(0, function (e, t, n) {
      if (Array.prototype.findIndex) return e.findIndex(function (e) {
        return e[t] === n;
      });
      var o = z(e, function (e) {
        return e[t] === n;
      });
      return e.indexOf(o);
    }(e, "name", n))).forEach(function (e) {
      e.function && console.warn("`modifier.function` is deprecated, use `modifier.fn`!");
      var n = e.function || e.fn;
      e.enabled && p(n) && (t.offsets.popper = x(t.offsets.popper), t.offsets.reference = x(t.offsets.reference), t = n(t, e));
    }), t;
  }
  function F(e, t) {
    return e.some(function (e) {
      var n = e.name;
      return e.enabled && n === t;
    });
  }
  function B(e) {
    for (var t = [!1, "ms", "Webkit", "Moz", "O"], n = e.charAt(0).toUpperCase() + e.slice(1), o = 0; o < t.length; o++) {
      var r = t[o],
        i = r ? "" + r + n : e;
      if (void 0 !== document.body.style[i]) return i;
    }
    return null;
  }
  function R(e) {
    var t = e.ownerDocument;
    return t ? t.defaultView : window;
  }
  function W(e, t, n, o) {
    n.updateBound = o, R(e).addEventListener("resize", n.updateBound, {
      passive: !0
    });
    var r = c(e);
    return function e(t, n, o, r) {
      var i = "BODY" === t.nodeName,
        s = i ? t.ownerDocument.defaultView : t;
      s.addEventListener(n, o, {
        passive: !0
      }), i || e(c(s.parentNode), n, o, r), r.push(s);
    }(r, "scroll", n.updateBound, n.scrollParents), n.scrollElement = r, n.eventsEnabled = !0, n;
  }
  function V() {
    var e, t;
    this.state.eventsEnabled && (cancelAnimationFrame(this.scheduleUpdate), this.state = (e = this.reference, t = this.state, R(e).removeEventListener("resize", t.updateBound), t.scrollParents.forEach(function (e) {
      e.removeEventListener("scroll", t.updateBound);
    }), t.updateBound = null, t.scrollParents = [], t.scrollElement = null, t.eventsEnabled = !1, t));
  }
  function U(e) {
    return "" !== e && !isNaN(parseFloat(e)) && isFinite(e);
  }
  function q(e, t) {
    Object.keys(t).forEach(function (n) {
      var o = "";
      -1 !== ["width", "height", "top", "right", "bottom", "left"].indexOf(n) && U(t[n]) && (o = "px"), e.style[n] = t[n] + o;
    });
  }
  var G = s && /Firefox/i.test(navigator.userAgent);
  function Y(e, t, n) {
    var o = z(e, function (e) {
        return e.name === t;
      }),
      r = !!o && e.some(function (e) {
        return e.name === n && e.enabled && e.order < o.order;
      });
    if (!r) {
      var i = "`" + t + "`",
        s = "`" + n + "`";
      console.warn(s + " modifier is required by " + i + " modifier in order to work, be sure to include it before " + i + "!");
    }
    return r;
  }
  var X = ["auto-start", "auto", "auto-end", "top-start", "top", "top-end", "right-start", "right", "right-end", "bottom-end", "bottom", "bottom-start", "left-end", "left", "left-start"],
    K = X.slice(3);
  function J(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      n = K.indexOf(e),
      o = K.slice(n + 1).concat(K.slice(0, n));
    return t ? o.reverse() : o;
  }
  var Q = {
    FLIP: "flip",
    CLOCKWISE: "clockwise",
    COUNTERCLOCKWISE: "counterclockwise"
  };
  function Z(e, t, n, o) {
    var r = [0, 0],
      i = -1 !== ["right", "left"].indexOf(o),
      s = e.split(/(\+|\-)/).map(function (e) {
        return e.trim();
      }),
      a = s.indexOf(z(s, function (e) {
        return -1 !== e.search(/,|\s/);
      }));
    s[a] && -1 === s[a].indexOf(",") && console.warn("Offsets separated by white space(s) are deprecated, use a comma (,) instead.");
    var u = /\s*,\s*|\s+/,
      p = -1 !== a ? [s.slice(0, a).concat([s[a].split(u)[0]]), [s[a].split(u)[1]].concat(s.slice(a + 1))] : [s];
    return (p = p.map(function (e, o) {
      var r = (1 === o ? !i : i) ? "height" : "width",
        s = !1;
      return e.reduce(function (e, t) {
        return "" === e[e.length - 1] && -1 !== ["+", "-"].indexOf(t) ? (e[e.length - 1] = t, s = !0, e) : s ? (e[e.length - 1] += t, s = !1, e) : e.concat(t);
      }, []).map(function (e) {
        return function (e, t, n, o) {
          var r = e.match(/((?:\-|\+)?\d*\.?\d*)(.*)/),
            i = +r[1],
            s = r[2];
          if (!i) return e;
          if (0 === s.indexOf("%")) {
            var a = void 0;
            switch (s) {
              case "%p":
                a = n;
                break;
              case "%":
              case "%r":
              default:
                a = o;
            }
            return x(a)[t] / 100 * i;
          }
          if ("vh" === s || "vw" === s) return ("vh" === s ? Math.max(document.documentElement.clientHeight, window.innerHeight || 0) : Math.max(document.documentElement.clientWidth, window.innerWidth || 0)) / 100 * i;
          return i;
        }(e, r, t, n);
      });
    })).forEach(function (e, t) {
      e.forEach(function (n, o) {
        U(n) && (r[t] += n * ("-" === e[o - 1] ? -1 : 1));
      });
    }), r;
  }
  var ee = {
      placement: "bottom",
      positionFixed: !1,
      eventsEnabled: !0,
      removeOnDestroy: !1,
      onCreate: function onCreate() {},
      onUpdate: function onUpdate() {},
      modifiers: {
        shift: {
          order: 100,
          enabled: !0,
          fn: function fn(e) {
            var t = e.placement,
              n = t.split("-")[0],
              o = t.split("-")[1];
            if (o) {
              var r = e.offsets,
                i = r.reference,
                s = r.popper,
                a = -1 !== ["bottom", "top"].indexOf(n),
                u = a ? "left" : "top",
                p = a ? "width" : "height",
                l = {
                  start: T({}, u, i[u]),
                  end: T({}, u, i[u] + i[p] - s[p])
                };
              e.offsets.popper = $({}, s, l[o]);
            }
            return e;
          }
        },
        offset: {
          order: 200,
          enabled: !0,
          fn: function fn(e, t) {
            var n = t.offset,
              o = e.placement,
              r = e.offsets,
              i = r.popper,
              s = r.reference,
              a = o.split("-")[0],
              u = void 0;
            return u = U(+n) ? [+n, 0] : Z(n, i, s, a), "left" === a ? (i.top += u[0], i.left -= u[1]) : "right" === a ? (i.top += u[0], i.left += u[1]) : "top" === a ? (i.left += u[0], i.top -= u[1]) : "bottom" === a && (i.left += u[0], i.top += u[1]), e.popper = i, e;
          },
          offset: 0
        },
        preventOverflow: {
          order: 300,
          enabled: !0,
          fn: function fn(e, t) {
            var n = t.boundariesElement || g(e.instance.popper);
            e.instance.reference === n && (n = g(n));
            var o = B("transform"),
              r = e.instance.popper.style,
              i = r.top,
              s = r.left,
              a = r[o];
            r.top = "", r.left = "", r[o] = "";
            var u = L(e.instance.popper, e.instance.reference, t.padding, n, e.positionFixed);
            r.top = i, r.left = s, r[o] = a, t.boundaries = u;
            var p = t.priority,
              l = e.offsets.popper,
              f = {
                primary: function primary(e) {
                  var n = l[e];
                  return l[e] < u[e] && !t.escapeWithReference && (n = Math.max(l[e], u[e])), T({}, e, n);
                },
                secondary: function secondary(e) {
                  var n = "right" === e ? "left" : "top",
                    o = l[n];
                  return l[e] > u[e] && !t.escapeWithReference && (o = Math.min(l[n], u[e] - ("right" === e ? l.width : l.height))), T({}, n, o);
                }
              };
            return p.forEach(function (e) {
              var t = -1 !== ["left", "top"].indexOf(e) ? "primary" : "secondary";
              l = $({}, l, f[t](e));
            }), e.offsets.popper = l, e;
          },
          priority: ["left", "right", "top", "bottom"],
          padding: 5,
          boundariesElement: "scrollParent"
        },
        keepTogether: {
          order: 400,
          enabled: !0,
          fn: function fn(e) {
            var t = e.offsets,
              n = t.popper,
              o = t.reference,
              r = e.placement.split("-")[0],
              i = Math.floor,
              s = -1 !== ["top", "bottom"].indexOf(r),
              a = s ? "right" : "bottom",
              u = s ? "left" : "top",
              p = s ? "width" : "height";
            return n[a] < i(o[u]) && (e.offsets.popper[u] = i(o[u]) - n[p]), n[u] > i(o[a]) && (e.offsets.popper[u] = i(o[a])), e;
          }
        },
        arrow: {
          order: 500,
          enabled: !0,
          fn: function fn(e, t) {
            var n;
            if (!Y(e.instance.modifiers, "arrow", "keepTogether")) return e;
            var o = t.element;
            if ("string" == typeof o) {
              if (!(o = e.instance.popper.querySelector(o))) return e;
            } else if (!e.instance.popper.contains(o)) return console.warn("WARNING: `arrow.element` must be child of its popper element!"), e;
            var r = e.placement.split("-")[0],
              i = e.offsets,
              s = i.popper,
              a = i.reference,
              u = -1 !== ["left", "right"].indexOf(r),
              p = u ? "height" : "width",
              f = u ? "Top" : "Left",
              c = f.toLowerCase(),
              d = u ? "left" : "top",
              h = u ? "bottom" : "right",
              v = D(o)[p];
            a[h] - v < s[c] && (e.offsets.popper[c] -= s[c] - (a[h] - v)), a[c] + v > s[h] && (e.offsets.popper[c] += a[c] + v - s[h]), e.offsets.popper = x(e.offsets.popper);
            var m = a[c] + a[p] / 2 - v / 2,
              g = l(e.instance.popper),
              b = parseFloat(g["margin" + f]),
              y = parseFloat(g["border" + f + "Width"]),
              _ = m - e.offsets.popper[c] - b - y;
            return _ = Math.max(Math.min(s[p] - v, _), 0), e.arrowElement = o, e.offsets.arrow = (T(n = {}, c, Math.round(_)), T(n, d, ""), n), e;
          },
          element: "[x-arrow]"
        },
        flip: {
          order: 600,
          enabled: !0,
          fn: function fn(e, t) {
            if (F(e.instance.modifiers, "inner")) return e;
            if (e.flipped && e.placement === e.originalPlacement) return e;
            var n = L(e.instance.popper, e.instance.reference, t.padding, t.boundariesElement, e.positionFixed),
              o = e.placement.split("-")[0],
              r = I(o),
              i = e.placement.split("-")[1] || "",
              s = [];
            switch (t.behavior) {
              case Q.FLIP:
                s = [o, r];
                break;
              case Q.CLOCKWISE:
                s = J(o);
                break;
              case Q.COUNTERCLOCKWISE:
                s = J(o, !0);
                break;
              default:
                s = t.behavior;
            }
            return s.forEach(function (a, u) {
              if (o !== a || s.length === u + 1) return e;
              o = e.placement.split("-")[0], r = I(o);
              var p = e.offsets.popper,
                l = e.offsets.reference,
                f = Math.floor,
                c = "left" === o && f(p.right) > f(l.left) || "right" === o && f(p.left) < f(l.right) || "top" === o && f(p.bottom) > f(l.top) || "bottom" === o && f(p.top) < f(l.bottom),
                d = f(p.left) < f(n.left),
                h = f(p.right) > f(n.right),
                v = f(p.top) < f(n.top),
                m = f(p.bottom) > f(n.bottom),
                g = "left" === o && d || "right" === o && h || "top" === o && v || "bottom" === o && m,
                b = -1 !== ["top", "bottom"].indexOf(o),
                y = !!t.flipVariations && (b && "start" === i && d || b && "end" === i && h || !b && "start" === i && v || !b && "end" === i && m),
                _ = !!t.flipVariationsByContent && (b && "start" === i && h || b && "end" === i && d || !b && "start" === i && m || !b && "end" === i && v),
                w = y || _;
              (c || g || w) && (e.flipped = !0, (c || g) && (o = s[u + 1]), w && (i = function (e) {
                return "end" === e ? "start" : "start" === e ? "end" : e;
              }(i)), e.placement = o + (i ? "-" + i : ""), e.offsets.popper = $({}, e.offsets.popper, M(e.instance.popper, e.offsets.reference, e.placement)), e = H(e.instance.modifiers, e, "flip"));
            }), e;
          },
          behavior: "flip",
          padding: 5,
          boundariesElement: "viewport",
          flipVariations: !1,
          flipVariationsByContent: !1
        },
        inner: {
          order: 700,
          enabled: !1,
          fn: function fn(e) {
            var t = e.placement,
              n = t.split("-")[0],
              o = e.offsets,
              r = o.popper,
              i = o.reference,
              s = -1 !== ["left", "right"].indexOf(n),
              a = -1 === ["top", "left"].indexOf(n);
            return r[s ? "left" : "top"] = i[n] - (a ? r[s ? "width" : "height"] : 0), e.placement = I(t), e.offsets.popper = x(r), e;
          }
        },
        hide: {
          order: 800,
          enabled: !0,
          fn: function fn(e) {
            if (!Y(e.instance.modifiers, "hide", "preventOverflow")) return e;
            var t = e.offsets.reference,
              n = z(e.instance.modifiers, function (e) {
                return "preventOverflow" === e.name;
              }).boundaries;
            if (t.bottom < n.top || t.left > n.right || t.top > n.bottom || t.right < n.left) {
              if (!0 === e.hide) return e;
              e.hide = !0, e.attributes["x-out-of-boundaries"] = "";
            } else {
              if (!1 === e.hide) return e;
              e.hide = !1, e.attributes["x-out-of-boundaries"] = !1;
            }
            return e;
          }
        },
        computeStyle: {
          order: 850,
          enabled: !0,
          fn: function fn(e, t) {
            var n = t.x,
              o = t.y,
              r = e.offsets.popper,
              i = z(e.instance.modifiers, function (e) {
                return "applyStyle" === e.name;
              }).gpuAcceleration;
            void 0 !== i && console.warn("WARNING: `gpuAcceleration` option moved to `computeStyle` modifier and will not be supported in future versions of Popper.js!");
            var s = void 0 !== i ? i : t.gpuAcceleration,
              a = g(e.instance.popper),
              u = S(a),
              p = {
                position: r.position
              },
              l = function (e, t) {
                var n = e.offsets,
                  o = n.popper,
                  r = n.reference,
                  i = Math.round,
                  s = Math.floor,
                  a = function a(e) {
                    return e;
                  },
                  u = i(r.width),
                  p = i(o.width),
                  l = -1 !== ["left", "right"].indexOf(e.placement),
                  f = -1 !== e.placement.indexOf("-"),
                  c = t ? l || f || u % 2 == p % 2 ? i : s : a,
                  d = t ? i : a;
                return {
                  left: c(u % 2 == 1 && p % 2 == 1 && !f && t ? o.left - 1 : o.left),
                  top: d(o.top),
                  bottom: d(o.bottom),
                  right: c(o.right)
                };
              }(e, window.devicePixelRatio < 2 || !G),
              f = "bottom" === n ? "top" : "bottom",
              c = "right" === o ? "left" : "right",
              d = B("transform"),
              h = void 0,
              v = void 0;
            if (v = "bottom" === f ? "HTML" === a.nodeName ? -a.clientHeight + l.bottom : -u.height + l.bottom : l.top, h = "right" === c ? "HTML" === a.nodeName ? -a.clientWidth + l.right : -u.width + l.right : l.left, s && d) p[d] = "translate3d(" + h + "px, " + v + "px, 0)", p[f] = 0, p[c] = 0, p.willChange = "transform";else {
              var m = "bottom" === f ? -1 : 1,
                b = "right" === c ? -1 : 1;
              p[f] = v * m, p[c] = h * b, p.willChange = f + ", " + c;
            }
            var y = {
              "x-placement": e.placement
            };
            return e.attributes = $({}, y, e.attributes), e.styles = $({}, p, e.styles), e.arrowStyles = $({}, e.offsets.arrow, e.arrowStyles), e;
          },
          gpuAcceleration: !0,
          x: "bottom",
          y: "right"
        },
        applyStyle: {
          order: 900,
          enabled: !0,
          fn: function fn(e) {
            var t, n;
            return q(e.instance.popper, e.styles), t = e.instance.popper, n = e.attributes, Object.keys(n).forEach(function (e) {
              !1 !== n[e] ? t.setAttribute(e, n[e]) : t.removeAttribute(e);
            }), e.arrowElement && Object.keys(e.arrowStyles).length && q(e.arrowElement, e.arrowStyles), e;
          },
          onLoad: function onLoad(e, t, n, o, r) {
            var i = P(r, t, e, n.positionFixed),
              s = A(n.placement, i, t, e, n.modifiers.flip.boundariesElement, n.modifiers.flip.padding);
            return t.setAttribute("x-placement", s), q(t, {
              position: n.positionFixed ? "fixed" : "absolute"
            }), n;
          },
          gpuAcceleration: void 0
        }
      }
    },
    te = function () {
      function e(t, n) {
        var o = this,
          r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
        C(this, e), this.scheduleUpdate = function () {
          return requestAnimationFrame(o.update);
        }, this.update = u(this.update.bind(this)), this.options = $({}, e.Defaults, r), this.state = {
          isDestroyed: !1,
          isCreated: !1,
          scrollParents: []
        }, this.reference = t && t.jquery ? t[0] : t, this.popper = n && n.jquery ? n[0] : n, this.options.modifiers = {}, Object.keys($({}, e.Defaults.modifiers, r.modifiers)).forEach(function (t) {
          o.options.modifiers[t] = $({}, e.Defaults.modifiers[t] || {}, r.modifiers ? r.modifiers[t] : {});
        }), this.modifiers = Object.keys(this.options.modifiers).map(function (e) {
          return $({
            name: e
          }, o.options.modifiers[e]);
        }).sort(function (e, t) {
          return e.order - t.order;
        }), this.modifiers.forEach(function (e) {
          e.enabled && p(e.onLoad) && e.onLoad(o.reference, o.popper, o.options, e, o.state);
        }), this.update();
        var i = this.options.eventsEnabled;
        i && this.enableEventListeners(), this.state.eventsEnabled = i;
      }
      return j(e, [{
        key: "update",
        value: function value() {
          return function () {
            if (!this.state.isDestroyed) {
              var e = {
                instance: this,
                styles: {},
                arrowStyles: {},
                attributes: {},
                flipped: !1,
                offsets: {}
              };
              e.offsets.reference = P(this.state, this.popper, this.reference, this.options.positionFixed), e.placement = A(this.options.placement, e.offsets.reference, this.popper, this.reference, this.options.modifiers.flip.boundariesElement, this.options.modifiers.flip.padding), e.originalPlacement = e.placement, e.positionFixed = this.options.positionFixed, e.offsets.popper = M(this.popper, e.offsets.reference, e.placement), e.offsets.popper.position = this.options.positionFixed ? "fixed" : "absolute", e = H(this.modifiers, e), this.state.isCreated ? this.options.onUpdate(e) : (this.state.isCreated = !0, this.options.onCreate(e));
            }
          }.call(this);
        }
      }, {
        key: "destroy",
        value: function value() {
          return function () {
            return this.state.isDestroyed = !0, F(this.modifiers, "applyStyle") && (this.popper.removeAttribute("x-placement"), this.popper.style.position = "", this.popper.style.top = "", this.popper.style.left = "", this.popper.style.right = "", this.popper.style.bottom = "", this.popper.style.willChange = "", this.popper.style[B("transform")] = ""), this.disableEventListeners(), this.options.removeOnDestroy && this.popper.parentNode.removeChild(this.popper), this;
          }.call(this);
        }
      }, {
        key: "enableEventListeners",
        value: function value() {
          return function () {
            this.state.eventsEnabled || (this.state = W(this.reference, this.options, this.state, this.scheduleUpdate));
          }.call(this);
        }
      }, {
        key: "disableEventListeners",
        value: function value() {
          return V.call(this);
        }
      }]), e;
    }();
  te.Utils = ("undefined" != typeof window ? window : global).PopperUtils, te.placements = X, te.Defaults = ee;
  var ne = function ne() {};
  function oe(e) {
    return "string" == typeof e && (e = e.split(" ")), e;
  }
  function re(e, t) {
    var n,
      o = oe(t);
    n = e.className instanceof ne ? oe(e.className.baseVal) : oe(e.className), o.forEach(function (e) {
      -1 === n.indexOf(e) && n.push(e);
    }), e instanceof SVGElement ? e.setAttribute("class", n.join(" ")) : e.className = n.join(" ");
  }
  function ie(e, t) {
    var n,
      o = oe(t);
    n = e.className instanceof ne ? oe(e.className.baseVal) : oe(e.className), o.forEach(function (e) {
      var t = n.indexOf(e);
      -1 !== t && n.splice(t, 1);
    }), e instanceof SVGElement ? e.setAttribute("class", n.join(" ")) : e.className = n.join(" ");
  }
  "undefined" != typeof window && (ne = window.SVGAnimatedString);
  var se = !1;
  if ("undefined" != typeof window) {
    se = !1;
    try {
      var ae = Object.defineProperty({}, "passive", {
        get: function get() {
          se = !0;
        }
      });
      window.addEventListener("test", null, ae);
    } catch (e) {}
  }
  var ue = function ue() {
    this.__data__ = [], this.size = 0;
  };
  var pe = function pe(e, t) {
    return e === t || e != e && t != t;
  };
  var le = function le(e, t) {
      for (var n = e.length; n--;) if (pe(e[n][0], t)) return n;
      return -1;
    },
    fe = Array.prototype.splice;
  var ce = function ce(e) {
    var t = this.__data__,
      n = le(t, e);
    return !(n < 0 || (n == t.length - 1 ? t.pop() : fe.call(t, n, 1), --this.size, 0));
  };
  var de = function de(e) {
    var t = this.__data__,
      n = le(t, e);
    return n < 0 ? void 0 : t[n][1];
  };
  var he = function he(e) {
    return le(this.__data__, e) > -1;
  };
  var ve = function ve(e, t) {
    var n = this.__data__,
      o = le(n, e);
    return o < 0 ? (++this.size, n.push([e, t])) : n[o][1] = t, this;
  };
  function me(e) {
    var t = -1,
      n = null == e ? 0 : e.length;
    for (this.clear(); ++t < n;) {
      var o = e[t];
      this.set(o[0], o[1]);
    }
  }
  me.prototype.clear = ue, me.prototype.delete = ce, me.prototype.get = de, me.prototype.has = he, me.prototype.set = ve;
  var ge = me;
  var be = function be() {
    this.__data__ = new ge(), this.size = 0;
  };
  var ye = function ye(e) {
    var t = this.__data__,
      n = t.delete(e);
    return this.size = t.size, n;
  };
  var _e = function _e(e) {
    return this.__data__.get(e);
  };
  var we = function we(e) {
      return this.__data__.has(e);
    },
    Oe = "undefined" != typeof globalThis ? globalThis : "undefined" != typeof window ? window : "undefined" != typeof global ? global : "undefined" != typeof self ? self : {};
  function Ee(e, t) {
    return e(t = {
      exports: {}
    }, t.exports), t.exports;
  }
  var Ce = "object" == _typeof(Oe) && Oe && Oe.Object === Object && Oe,
    je = "object" == (typeof self === "undefined" ? "undefined" : _typeof(self)) && self && self.Object === Object && self,
    Te = Ce || je || Function("return this")(),
    $e = Te.Symbol,
    xe = Object.prototype,
    Se = xe.hasOwnProperty,
    ke = xe.toString,
    Ne = $e ? $e.toStringTag : void 0;
  var Le = function Le(e) {
      var t = Se.call(e, Ne),
        n = e[Ne];
      try {
        e[Ne] = void 0;
        var o = !0;
      } catch (e) {}
      var r = ke.call(e);
      return o && (t ? e[Ne] = n : delete e[Ne]), r;
    },
    Ae = Object.prototype.toString;
  var Pe = function Pe(e) {
      return Ae.call(e);
    },
    De = "[object Null]",
    Ie = "[object Undefined]",
    Me = $e ? $e.toStringTag : void 0;
  var ze = function ze(e) {
    return null == e ? void 0 === e ? Ie : De : Me && Me in Object(e) ? Le(e) : Pe(e);
  };
  var He = function He(e) {
      var t = _typeof(e);
      return null != e && ("object" == t || "function" == t);
    },
    Fe = "[object AsyncFunction]",
    Be = "[object Function]",
    Re = "[object GeneratorFunction]",
    We = "[object Proxy]";
  var Ve,
    Ue = function Ue(e) {
      if (!He(e)) return !1;
      var t = ze(e);
      return t == Be || t == Re || t == Fe || t == We;
    },
    qe = Te["__core-js_shared__"],
    Ge = (Ve = /[^.]+$/.exec(qe && qe.keys && qe.keys.IE_PROTO || "")) ? "Symbol(src)_1." + Ve : "";
  var Ye = function Ye(e) {
      return !!Ge && Ge in e;
    },
    Xe = Function.prototype.toString;
  var Ke = function Ke(e) {
      if (null != e) {
        try {
          return Xe.call(e);
        } catch (e) {}
        try {
          return e + "";
        } catch (e) {}
      }
      return "";
    },
    Je = /^\[object .+?Constructor\]$/,
    Qe = Function.prototype,
    Ze = Object.prototype,
    et = Qe.toString,
    tt = Ze.hasOwnProperty,
    nt = RegExp("^" + et.call(tt).replace(/[\\^$.*+?()[\]{}|]/g, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$");
  var ot = function ot(e) {
    return !(!He(e) || Ye(e)) && (Ue(e) ? nt : Je).test(Ke(e));
  };
  var rt = function rt(e, t) {
    return null == e ? void 0 : e[t];
  };
  var it = function it(e, t) {
      var n = rt(e, t);
      return ot(n) ? n : void 0;
    },
    st = it(Te, "Map"),
    at = it(Object, "create");
  var ut = function ut() {
    this.__data__ = at ? at(null) : {}, this.size = 0;
  };
  var pt = function pt(e) {
      var t = this.has(e) && delete this.__data__[e];
      return this.size -= t ? 1 : 0, t;
    },
    lt = "__lodash_hash_undefined__",
    ft = Object.prototype.hasOwnProperty;
  var ct = function ct(e) {
      var t = this.__data__;
      if (at) {
        var n = t[e];
        return n === lt ? void 0 : n;
      }
      return ft.call(t, e) ? t[e] : void 0;
    },
    dt = Object.prototype.hasOwnProperty;
  var ht = function ht(e) {
      var t = this.__data__;
      return at ? void 0 !== t[e] : dt.call(t, e);
    },
    vt = "__lodash_hash_undefined__";
  var mt = function mt(e, t) {
    var n = this.__data__;
    return this.size += this.has(e) ? 0 : 1, n[e] = at && void 0 === t ? vt : t, this;
  };
  function gt(e) {
    var t = -1,
      n = null == e ? 0 : e.length;
    for (this.clear(); ++t < n;) {
      var o = e[t];
      this.set(o[0], o[1]);
    }
  }
  gt.prototype.clear = ut, gt.prototype.delete = pt, gt.prototype.get = ct, gt.prototype.has = ht, gt.prototype.set = mt;
  var bt = gt;
  var yt = function yt() {
    this.size = 0, this.__data__ = {
      hash: new bt(),
      map: new (st || ge)(),
      string: new bt()
    };
  };
  var _t = function _t(e) {
    var t = _typeof(e);
    return "string" == t || "number" == t || "symbol" == t || "boolean" == t ? "__proto__" !== e : null === e;
  };
  var wt = function wt(e, t) {
    var n = e.__data__;
    return _t(t) ? n["string" == typeof t ? "string" : "hash"] : n.map;
  };
  var Ot = function Ot(e) {
    var t = wt(this, e).delete(e);
    return this.size -= t ? 1 : 0, t;
  };
  var Et = function Et(e) {
    return wt(this, e).get(e);
  };
  var Ct = function Ct(e) {
    return wt(this, e).has(e);
  };
  var jt = function jt(e, t) {
    var n = wt(this, e),
      o = n.size;
    return n.set(e, t), this.size += n.size == o ? 0 : 1, this;
  };
  function Tt(e) {
    var t = -1,
      n = null == e ? 0 : e.length;
    for (this.clear(); ++t < n;) {
      var o = e[t];
      this.set(o[0], o[1]);
    }
  }
  Tt.prototype.clear = yt, Tt.prototype.delete = Ot, Tt.prototype.get = Et, Tt.prototype.has = Ct, Tt.prototype.set = jt;
  var $t = Tt,
    xt = 200;
  var St = function St(e, t) {
    var n = this.__data__;
    if (n instanceof ge) {
      var o = n.__data__;
      if (!st || o.length < xt - 1) return o.push([e, t]), this.size = ++n.size, this;
      n = this.__data__ = new $t(o);
    }
    return n.set(e, t), this.size = n.size, this;
  };
  function kt(e) {
    var t = this.__data__ = new ge(e);
    this.size = t.size;
  }
  kt.prototype.clear = be, kt.prototype.delete = ye, kt.prototype.get = _e, kt.prototype.has = we, kt.prototype.set = St;
  var Nt = kt,
    Lt = "__lodash_hash_undefined__";
  var At = function At(e) {
    return this.__data__.set(e, Lt), this;
  };
  var Pt = function Pt(e) {
    return this.__data__.has(e);
  };
  function Dt(e) {
    var t = -1,
      n = null == e ? 0 : e.length;
    for (this.__data__ = new $t(); ++t < n;) this.add(e[t]);
  }
  Dt.prototype.add = Dt.prototype.push = At, Dt.prototype.has = Pt;
  var It = Dt;
  var Mt = function Mt(e, t) {
    for (var n = -1, o = null == e ? 0 : e.length; ++n < o;) if (t(e[n], n, e)) return !0;
    return !1;
  };
  var zt = function zt(e, t) {
      return e.has(t);
    },
    Ht = 1,
    Ft = 2;
  var Bt = function Bt(e, t, n, o, r, i) {
      var s = n & Ht,
        a = e.length,
        u = t.length;
      if (a != u && !(s && u > a)) return !1;
      var p = i.get(e);
      if (p && i.get(t)) return p == t;
      var l = -1,
        f = !0,
        c = n & Ft ? new It() : void 0;
      for (i.set(e, t), i.set(t, e); ++l < a;) {
        var d = e[l],
          h = t[l];
        if (o) var v = s ? o(h, d, l, t, e, i) : o(d, h, l, e, t, i);
        if (void 0 !== v) {
          if (v) continue;
          f = !1;
          break;
        }
        if (c) {
          if (!Mt(t, function (e, t) {
            if (!zt(c, t) && (d === e || r(d, e, n, o, i))) return c.push(t);
          })) {
            f = !1;
            break;
          }
        } else if (d !== h && !r(d, h, n, o, i)) {
          f = !1;
          break;
        }
      }
      return i.delete(e), i.delete(t), f;
    },
    Rt = Te.Uint8Array;
  var Wt = function Wt(e) {
    var t = -1,
      n = Array(e.size);
    return e.forEach(function (e, o) {
      n[++t] = [o, e];
    }), n;
  };
  var Vt = function Vt(e) {
      var t = -1,
        n = Array(e.size);
      return e.forEach(function (e) {
        n[++t] = e;
      }), n;
    },
    Ut = 1,
    qt = 2,
    Gt = "[object Boolean]",
    Yt = "[object Date]",
    Xt = "[object Error]",
    Kt = "[object Map]",
    Jt = "[object Number]",
    Qt = "[object RegExp]",
    Zt = "[object Set]",
    en = "[object String]",
    tn = "[object Symbol]",
    nn = "[object ArrayBuffer]",
    on = "[object DataView]",
    rn = $e ? $e.prototype : void 0,
    sn = rn ? rn.valueOf : void 0;
  var an = function an(e, t, n, o, r, i, s) {
    switch (n) {
      case on:
        if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset) return !1;
        e = e.buffer, t = t.buffer;
      case nn:
        return !(e.byteLength != t.byteLength || !i(new Rt(e), new Rt(t)));
      case Gt:
      case Yt:
      case Jt:
        return pe(+e, +t);
      case Xt:
        return e.name == t.name && e.message == t.message;
      case Qt:
      case en:
        return e == t + "";
      case Kt:
        var a = Wt;
      case Zt:
        var u = o & Ut;
        if (a || (a = Vt), e.size != t.size && !u) return !1;
        var p = s.get(e);
        if (p) return p == t;
        o |= qt, s.set(e, t);
        var l = Bt(a(e), a(t), o, r, i, s);
        return s.delete(e), l;
      case tn:
        if (sn) return sn.call(e) == sn.call(t);
    }
    return !1;
  };
  var un = function un(e, t) {
      for (var n = -1, o = t.length, r = e.length; ++n < o;) e[r + n] = t[n];
      return e;
    },
    pn = Array.isArray;
  var ln = function ln(e, t, n) {
    var o = t(e);
    return pn(e) ? o : un(o, n(e));
  };
  var fn = function fn(e, t) {
    for (var n = -1, o = null == e ? 0 : e.length, r = 0, i = []; ++n < o;) {
      var s = e[n];
      t(s, n, e) && (i[r++] = s);
    }
    return i;
  };
  var cn = function cn() {
      return [];
    },
    dn = Object.prototype.propertyIsEnumerable,
    hn = Object.getOwnPropertySymbols,
    vn = hn ? function (e) {
      return null == e ? [] : (e = Object(e), fn(hn(e), function (t) {
        return dn.call(e, t);
      }));
    } : cn;
  var mn = function mn(e, t) {
    for (var n = -1, o = Array(e); ++n < e;) o[n] = t(n);
    return o;
  };
  var gn = function gn(e) {
      return null != e && "object" == _typeof(e);
    },
    bn = "[object Arguments]";
  var yn = function yn(e) {
      return gn(e) && ze(e) == bn;
    },
    _n = Object.prototype,
    wn = _n.hasOwnProperty,
    On = _n.propertyIsEnumerable,
    En = yn(function () {
      return arguments;
    }()) ? yn : function (e) {
      return gn(e) && wn.call(e, "callee") && !On.call(e, "callee");
    };
  var Cn = function Cn() {
      return !1;
    },
    jn = Ee(function (e, t) {
      var n = t && !t.nodeType && t,
        o = n && e && !e.nodeType && e,
        r = o && o.exports === n ? Te.Buffer : void 0,
        i = (r ? r.isBuffer : void 0) || Cn;
      e.exports = i;
    }),
    Tn = 9007199254740991,
    $n = /^(?:0|[1-9]\d*)$/;
  var xn = function xn(e, t) {
      var n = _typeof(e);
      return !!(t = null == t ? Tn : t) && ("number" == n || "symbol" != n && $n.test(e)) && e > -1 && e % 1 == 0 && e < t;
    },
    Sn = 9007199254740991;
  var kn = function kn(e) {
      return "number" == typeof e && e > -1 && e % 1 == 0 && e <= Sn;
    },
    Nn = {};
  Nn["[object Float32Array]"] = Nn["[object Float64Array]"] = Nn["[object Int8Array]"] = Nn["[object Int16Array]"] = Nn["[object Int32Array]"] = Nn["[object Uint8Array]"] = Nn["[object Uint8ClampedArray]"] = Nn["[object Uint16Array]"] = Nn["[object Uint32Array]"] = !0, Nn["[object Arguments]"] = Nn["[object Array]"] = Nn["[object ArrayBuffer]"] = Nn["[object Boolean]"] = Nn["[object DataView]"] = Nn["[object Date]"] = Nn["[object Error]"] = Nn["[object Function]"] = Nn["[object Map]"] = Nn["[object Number]"] = Nn["[object Object]"] = Nn["[object RegExp]"] = Nn["[object Set]"] = Nn["[object String]"] = Nn["[object WeakMap]"] = !1;
  var Ln = function Ln(e) {
    return gn(e) && kn(e.length) && !!Nn[ze(e)];
  };
  var An = function An(e) {
      return function (t) {
        return e(t);
      };
    },
    Pn = Ee(function (e, t) {
      var n = t && !t.nodeType && t,
        o = n && e && !e.nodeType && e,
        r = o && o.exports === n && Ce.process,
        i = function () {
          try {
            var e = o && o.require && o.require("util").types;
            return e || r && r.binding && r.binding("util");
          } catch (e) {}
        }();
      e.exports = i;
    }),
    Dn = Pn && Pn.isTypedArray,
    In = Dn ? An(Dn) : Ln,
    Mn = Object.prototype.hasOwnProperty;
  var zn = function zn(e, t) {
      var n = pn(e),
        o = !n && En(e),
        r = !n && !o && jn(e),
        i = !n && !o && !r && In(e),
        s = n || o || r || i,
        a = s ? mn(e.length, String) : [],
        u = a.length;
      for (var p in e) !t && !Mn.call(e, p) || s && ("length" == p || r && ("offset" == p || "parent" == p) || i && ("buffer" == p || "byteLength" == p || "byteOffset" == p) || xn(p, u)) || a.push(p);
      return a;
    },
    Hn = Object.prototype;
  var Fn = function Fn(e) {
    var t = e && e.constructor;
    return e === ("function" == typeof t && t.prototype || Hn);
  };
  var Bn = function Bn(e, t) {
      return function (n) {
        return e(t(n));
      };
    },
    Rn = Bn(Object.keys, Object),
    Wn = Object.prototype.hasOwnProperty;
  var Vn = function Vn(e) {
    if (!Fn(e)) return Rn(e);
    var t = [];
    for (var n in Object(e)) Wn.call(e, n) && "constructor" != n && t.push(n);
    return t;
  };
  var Un = function Un(e) {
    return null != e && kn(e.length) && !Ue(e);
  };
  var qn = function qn(e) {
    return Un(e) ? zn(e) : Vn(e);
  };
  var Gn = function Gn(e) {
      return ln(e, qn, vn);
    },
    Yn = 1,
    Xn = Object.prototype.hasOwnProperty;
  var Kn = function Kn(e, t, n, o, r, i) {
      var s = n & Yn,
        a = Gn(e),
        u = a.length;
      if (u != Gn(t).length && !s) return !1;
      for (var p = u; p--;) {
        var l = a[p];
        if (!(s ? l in t : Xn.call(t, l))) return !1;
      }
      var f = i.get(e);
      if (f && i.get(t)) return f == t;
      var c = !0;
      i.set(e, t), i.set(t, e);
      for (var d = s; ++p < u;) {
        var h = e[l = a[p]],
          v = t[l];
        if (o) var m = s ? o(v, h, l, t, e, i) : o(h, v, l, e, t, i);
        if (!(void 0 === m ? h === v || r(h, v, n, o, i) : m)) {
          c = !1;
          break;
        }
        d || (d = "constructor" == l);
      }
      if (c && !d) {
        var g = e.constructor,
          b = t.constructor;
        g != b && "constructor" in e && "constructor" in t && !("function" == typeof g && g instanceof g && "function" == typeof b && b instanceof b) && (c = !1);
      }
      return i.delete(e), i.delete(t), c;
    },
    Jn = it(Te, "DataView"),
    Qn = it(Te, "Promise"),
    Zn = it(Te, "Set"),
    eo = it(Te, "WeakMap"),
    to = Ke(Jn),
    no = Ke(st),
    oo = Ke(Qn),
    ro = Ke(Zn),
    io = Ke(eo),
    so = ze;
  (Jn && "[object DataView]" != so(new Jn(new ArrayBuffer(1))) || st && "[object Map]" != so(new st()) || Qn && "[object Promise]" != so(Qn.resolve()) || Zn && "[object Set]" != so(new Zn()) || eo && "[object WeakMap]" != so(new eo())) && (so = function so(e) {
    var t = ze(e),
      n = "[object Object]" == t ? e.constructor : void 0,
      o = n ? Ke(n) : "";
    if (o) switch (o) {
      case to:
        return "[object DataView]";
      case no:
        return "[object Map]";
      case oo:
        return "[object Promise]";
      case ro:
        return "[object Set]";
      case io:
        return "[object WeakMap]";
    }
    return t;
  });
  var ao = so,
    uo = 1,
    po = "[object Arguments]",
    lo = "[object Array]",
    fo = "[object Object]",
    co = Object.prototype.hasOwnProperty;
  var ho = function ho(e, t, n, o, r, i) {
    var s = pn(e),
      a = pn(t),
      u = s ? lo : ao(e),
      p = a ? lo : ao(t),
      l = (u = u == po ? fo : u) == fo,
      f = (p = p == po ? fo : p) == fo,
      c = u == p;
    if (c && jn(e)) {
      if (!jn(t)) return !1;
      s = !0, l = !1;
    }
    if (c && !l) return i || (i = new Nt()), s || In(e) ? Bt(e, t, n, o, r, i) : an(e, t, u, n, o, r, i);
    if (!(n & uo)) {
      var d = l && co.call(e, "__wrapped__"),
        h = f && co.call(t, "__wrapped__");
      if (d || h) {
        var v = d ? e.value() : e,
          m = h ? t.value() : t;
        return i || (i = new Nt()), r(v, m, n, o, i);
      }
    }
    return !!c && (i || (i = new Nt()), Kn(e, t, n, o, r, i));
  };
  var vo = function e(t, n, o, r, i) {
    return t === n || (null == t || null == n || !gn(t) && !gn(n) ? t != t && n != n : ho(t, n, o, r, e, i));
  };
  var mo = function mo(e, t) {
      return vo(e, t);
    },
    go = {
      container: !1,
      delay: 0,
      html: !1,
      placement: "top",
      title: "",
      template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
      trigger: "hover focus",
      offset: 0
    },
    bo = [],
    yo = function () {
      function e(t, n) {
        var r = this;
        !function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this, e), o(this, "_events", []), o(this, "_setTooltipNodeEvent", function (e, t, n, o) {
          var i = e.relatedreference || e.toElement || e.relatedTarget;
          return !!r._tooltipNode.contains(i) && (r._tooltipNode.addEventListener(e.type, function n(i) {
            var s = i.relatedreference || i.toElement || i.relatedTarget;
            r._tooltipNode.removeEventListener(e.type, n), t.contains(s) || r._scheduleHide(t, o.delay, o, i);
          }), !0);
        }), n = i(i({}, go), n), t.jquery && (t = t[0]), this.show = this.show.bind(this), this.hide = this.hide.bind(this), this.reference = t, this.options = n, this._isOpen = !1, this._init();
      }
      var t, r, s;
      return t = e, (r = [{
        key: "show",
        value: function value() {
          this._show(this.reference, this.options);
        }
      }, {
        key: "hide",
        value: function value() {
          this._hide();
        }
      }, {
        key: "dispose",
        value: function value() {
          this._dispose();
        }
      }, {
        key: "toggle",
        value: function value() {
          return this._isOpen ? this.hide() : this.show();
        }
      }, {
        key: "setClasses",
        value: function value(e) {
          this._classes = e;
        }
      }, {
        key: "setContent",
        value: function value(e) {
          this.options.title = e, this._tooltipNode && this._setContent(e, this.options);
        }
      }, {
        key: "setOptions",
        value: function value(e) {
          var t = !1,
            n = e && e.classes || So.options.defaultClass;
          mo(this._classes, n) || (this.setClasses(n), t = !0), e = Eo(e);
          var o = !1,
            r = !1;
          for (var i in this.options.offset === e.offset && this.options.placement === e.placement || (o = !0), (this.options.template !== e.template || this.options.trigger !== e.trigger || this.options.container !== e.container || t) && (r = !0), e) this.options[i] = e[i];
          if (this._tooltipNode) if (r) {
            var s = this._isOpen;
            this.dispose(), this._init(), s && this.show();
          } else o && this.popperInstance.update();
        }
      }, {
        key: "_init",
        value: function value() {
          var e = "string" == typeof this.options.trigger ? this.options.trigger.split(" ") : [];
          this._isDisposed = !1, this._enableDocumentTouch = -1 === e.indexOf("manual"), e = e.filter(function (e) {
            return -1 !== ["click", "hover", "focus"].indexOf(e);
          }), this._setEventListeners(this.reference, e, this.options), this.$_originalTitle = this.reference.getAttribute("title"), this.reference.removeAttribute("title"), this.reference.setAttribute("data-original-title", this.$_originalTitle);
        }
      }, {
        key: "_create",
        value: function value(e, t) {
          var n = window.document.createElement("div");
          n.innerHTML = t.trim();
          var o = n.childNodes[0];
          return o.id = "tooltip_".concat(Math.random().toString(36).substr(2, 10)), o.setAttribute("aria-hidden", "true"), this.options.autoHide && -1 !== this.options.trigger.indexOf("hover") && (o.addEventListener("mouseenter", this.hide), o.addEventListener("click", this.hide)), o;
        }
      }, {
        key: "_setContent",
        value: function value(e, t) {
          var n = this;
          this.asyncContent = !1, this._applyContent(e, t).then(function () {
            n.popperInstance.update();
          });
        }
      }, {
        key: "_applyContent",
        value: function value(e, t) {
          var n = this;
          return new Promise(function (o, r) {
            var i = t.html,
              s = n._tooltipNode;
            if (s) {
              var a = s.querySelector(n.options.innerSelector);
              if (1 === e.nodeType) {
                if (i) {
                  for (; a.firstChild;) a.removeChild(a.firstChild);
                  a.appendChild(e);
                }
              } else {
                if ("function" == typeof e) {
                  var u = e();
                  return void (u && "function" == typeof u.then ? (n.asyncContent = !0, t.loadingClass && re(s, t.loadingClass), t.loadingContent && n._applyContent(t.loadingContent, t), u.then(function (e) {
                    return t.loadingClass && ie(s, t.loadingClass), n._applyContent(e, t);
                  }).then(o).catch(r)) : n._applyContent(u, t).then(o).catch(r));
                }
                i ? a.innerHTML = e : a.innerText = e;
              }
              o();
            }
          });
        }
      }, {
        key: "_show",
        value: function value(e, t) {
          if (t && "string" == typeof t.container && !document.querySelector(t.container)) return;
          clearTimeout(this._disposeTimer), delete (t = Object.assign({}, t)).offset;
          var n = !0;
          this._tooltipNode && (re(this._tooltipNode, this._classes), n = !1);
          var o = this._ensureShown(e, t);
          return n && this._tooltipNode && re(this._tooltipNode, this._classes), re(e, ["v-tooltip-open"]), o;
        }
      }, {
        key: "_ensureShown",
        value: function value(e, t) {
          var n = this;
          if (this._isOpen) return this;
          if (this._isOpen = !0, bo.push(this), this._tooltipNode) return this._tooltipNode.style.display = "", this._tooltipNode.setAttribute("aria-hidden", "false"), this.popperInstance.enableEventListeners(), this.popperInstance.update(), this.asyncContent && this._setContent(t.title, t), this;
          var o = e.getAttribute("title") || t.title;
          if (!o) return this;
          var r = this._create(e, t.template);
          this._tooltipNode = r, e.setAttribute("aria-describedby", r.id);
          var s = this._findContainer(t.container, e);
          this._append(r, s);
          var a = i(i({}, t.popperOptions), {}, {
            placement: t.placement
          });
          return a.modifiers = i(i({}, a.modifiers), {}, {
            arrow: {
              element: this.options.arrowSelector
            }
          }), t.boundariesElement && (a.modifiers.preventOverflow = {
            boundariesElement: t.boundariesElement
          }), this.popperInstance = new te(e, r, a), this._setContent(o, t), requestAnimationFrame(function () {
            !n._isDisposed && n.popperInstance ? (n.popperInstance.update(), requestAnimationFrame(function () {
              n._isDisposed ? n.dispose() : n._isOpen && r.setAttribute("aria-hidden", "false");
            })) : n.dispose();
          }), this;
        }
      }, {
        key: "_noLongerOpen",
        value: function value() {
          var e = bo.indexOf(this);
          -1 !== e && bo.splice(e, 1);
        }
      }, {
        key: "_hide",
        value: function value() {
          var e = this;
          if (!this._isOpen) return this;
          this._isOpen = !1, this._noLongerOpen(), this._tooltipNode.style.display = "none", this._tooltipNode.setAttribute("aria-hidden", "true"), this.popperInstance.disableEventListeners(), clearTimeout(this._disposeTimer);
          var t = So.options.disposeTimeout;
          return null !== t && (this._disposeTimer = setTimeout(function () {
            e._tooltipNode && (e._tooltipNode.removeEventListener("mouseenter", e.hide), e._tooltipNode.removeEventListener("click", e.hide), e._removeTooltipNode());
          }, t)), ie(this.reference, ["v-tooltip-open"]), this;
        }
      }, {
        key: "_removeTooltipNode",
        value: function value() {
          if (this._tooltipNode) {
            var e = this._tooltipNode.parentNode;
            e && (e.removeChild(this._tooltipNode), this.reference.removeAttribute("aria-describedby")), this._tooltipNode = null;
          }
        }
      }, {
        key: "_dispose",
        value: function value() {
          var e = this;
          return this._isDisposed = !0, this.reference.removeAttribute("data-original-title"), this.$_originalTitle && this.reference.setAttribute("title", this.$_originalTitle), this._events.forEach(function (t) {
            var n = t.func,
              o = t.event;
            e.reference.removeEventListener(o, n);
          }), this._events = [], this._tooltipNode ? (this._hide(), this._tooltipNode.removeEventListener("mouseenter", this.hide), this._tooltipNode.removeEventListener("click", this.hide), this.popperInstance.destroy(), this.popperInstance.options.removeOnDestroy || this._removeTooltipNode()) : this._noLongerOpen(), this;
        }
      }, {
        key: "_findContainer",
        value: function value(e, t) {
          return "string" == typeof e ? e = window.document.querySelector(e) : !1 === e && (e = t.parentNode), e;
        }
      }, {
        key: "_append",
        value: function value(e, t) {
          t.appendChild(e);
        }
      }, {
        key: "_setEventListeners",
        value: function value(e, t, n) {
          var o = this,
            r = [],
            i = [];
          t.forEach(function (e) {
            switch (e) {
              case "hover":
                r.push("mouseenter"), i.push("mouseleave"), o.options.hideOnTargetClick && i.push("click");
                break;
              case "focus":
                r.push("focus"), i.push("blur"), o.options.hideOnTargetClick && i.push("click");
                break;
              case "click":
                r.push("click"), i.push("click");
            }
          }), r.forEach(function (t) {
            var r = function r(t) {
              !0 !== o._isOpen && (t.usedByTooltip = !0, o._scheduleShow(e, n.delay, n, t));
            };
            o._events.push({
              event: t,
              func: r
            }), e.addEventListener(t, r);
          }), i.forEach(function (t) {
            var r = function r(t) {
              !0 !== t.usedByTooltip && o._scheduleHide(e, n.delay, n, t);
            };
            o._events.push({
              event: t,
              func: r
            }), e.addEventListener(t, r);
          });
        }
      }, {
        key: "_onDocumentTouch",
        value: function value(e) {
          this._enableDocumentTouch && this._scheduleHide(this.reference, this.options.delay, this.options, e);
        }
      }, {
        key: "_scheduleShow",
        value: function value(e, t, n) {
          var o = this,
            r = t && t.show || t || 0;
          clearTimeout(this._scheduleTimer), this._scheduleTimer = window.setTimeout(function () {
            return o._show(e, n);
          }, r);
        }
      }, {
        key: "_scheduleHide",
        value: function value(e, t, n, o) {
          var r = this,
            i = t && t.hide || t || 0;
          clearTimeout(this._scheduleTimer), this._scheduleTimer = window.setTimeout(function () {
            if (!1 !== r._isOpen && r._tooltipNode.ownerDocument.body.contains(r._tooltipNode)) {
              if ("mouseleave" === o.type) if (r._setTooltipNodeEvent(o, e, t, n)) return;
              r._hide(e, n);
            }
          }, i);
        }
      }]) && n(t.prototype, r), s && n(t, s), e;
    }();
  "undefined" != typeof document && document.addEventListener("touchstart", function (e) {
    for (var t = 0; t < bo.length; t++) bo[t]._onDocumentTouch(e);
  }, !se || {
    passive: !0,
    capture: !0
  });
  var _o = {
      enabled: !0
    },
    wo = ["top", "top-start", "top-end", "right", "right-start", "right-end", "bottom", "bottom-start", "bottom-end", "left", "left-start", "left-end"],
    Oo = {
      defaultPlacement: "top",
      defaultClass: "vue-tooltip-theme",
      defaultTargetClass: "has-tooltip",
      defaultHtml: !0,
      defaultTemplate: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
      defaultArrowSelector: ".tooltip-arrow, .tooltip__arrow",
      defaultInnerSelector: ".tooltip-inner, .tooltip__inner",
      defaultDelay: 0,
      defaultTrigger: "hover focus",
      defaultOffset: 0,
      defaultContainer: "body",
      defaultBoundariesElement: void 0,
      defaultPopperOptions: {},
      defaultLoadingClass: "tooltip-loading",
      defaultLoadingContent: "...",
      autoHide: !0,
      defaultHideOnTargetClick: !0,
      disposeTimeout: 5e3,
      popover: {
        defaultPlacement: "bottom",
        defaultClass: "vue-popover-theme",
        defaultBaseClass: "tooltip popover",
        defaultWrapperClass: "wrapper",
        defaultInnerClass: "tooltip-inner popover-inner",
        defaultArrowClass: "tooltip-arrow popover-arrow",
        defaultOpenClass: "open",
        defaultDelay: 0,
        defaultTrigger: "click",
        defaultOffset: 0,
        defaultContainer: "body",
        defaultBoundariesElement: void 0,
        defaultPopperOptions: {},
        defaultAutoHide: !0,
        defaultHandleResize: !0
      }
    };
  function Eo(e) {
    var n = {
      placement: void 0 !== e.placement ? e.placement : So.options.defaultPlacement,
      delay: void 0 !== e.delay ? e.delay : So.options.defaultDelay,
      html: void 0 !== e.html ? e.html : So.options.defaultHtml,
      template: void 0 !== e.template ? e.template : So.options.defaultTemplate,
      arrowSelector: void 0 !== e.arrowSelector ? e.arrowSelector : So.options.defaultArrowSelector,
      innerSelector: void 0 !== e.innerSelector ? e.innerSelector : So.options.defaultInnerSelector,
      trigger: void 0 !== e.trigger ? e.trigger : So.options.defaultTrigger,
      offset: void 0 !== e.offset ? e.offset : So.options.defaultOffset,
      container: void 0 !== e.container ? e.container : So.options.defaultContainer,
      boundariesElement: void 0 !== e.boundariesElement ? e.boundariesElement : So.options.defaultBoundariesElement,
      autoHide: void 0 !== e.autoHide ? e.autoHide : So.options.autoHide,
      hideOnTargetClick: void 0 !== e.hideOnTargetClick ? e.hideOnTargetClick : So.options.defaultHideOnTargetClick,
      loadingClass: void 0 !== e.loadingClass ? e.loadingClass : So.options.defaultLoadingClass,
      loadingContent: void 0 !== e.loadingContent ? e.loadingContent : So.options.defaultLoadingContent,
      popperOptions: i({}, void 0 !== e.popperOptions ? e.popperOptions : So.options.defaultPopperOptions)
    };
    if (n.offset) {
      var o = t(n.offset),
        r = n.offset;
      ("number" === o || "string" === o && -1 === r.indexOf(",")) && (r = "0, ".concat(r)), n.popperOptions.modifiers || (n.popperOptions.modifiers = {}), n.popperOptions.modifiers.offset = {
        offset: r
      };
    }
    return n.trigger && -1 !== n.trigger.indexOf("click") && (n.hideOnTargetClick = !1), n;
  }
  function Co(e, t) {
    for (var n = e.placement, o = 0; o < wo.length; o++) {
      var r = wo[o];
      t[r] && (n = r);
    }
    return n;
  }
  function jo(e) {
    var n = t(e);
    return "string" === n ? e : !(!e || "object" !== n) && e.content;
  }
  function To(e, t) {
    var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
      o = jo(t),
      r = void 0 !== t.classes ? t.classes : So.options.defaultClass,
      s = i({
        title: o
      }, Eo(i(i({}, t), {}, {
        placement: Co(t, n)
      }))),
      a = e._tooltip = new yo(e, s);
    a.setClasses(r), a._vueEl = e;
    var u = void 0 !== t.targetClasses ? t.targetClasses : So.options.defaultTargetClass;
    return e._tooltipTargetClasses = u, re(e, u), a;
  }
  function $o(e) {
    e._tooltip && (e._tooltip.dispose(), delete e._tooltip, delete e._tooltipOldShow), e._tooltipTargetClasses && (ie(e, e._tooltipTargetClasses), delete e._tooltipTargetClasses);
  }
  function xo(e, t) {
    var n,
      o = t.value,
      r = (t.oldValue, t.modifiers),
      s = jo(o);
    s && _o.enabled ? (e._tooltip ? ((n = e._tooltip).setContent(s), n.setOptions(i(i({}, o), {}, {
      placement: Co(o, r)
    }))) : n = To(e, o, r), void 0 !== o.show && o.show !== e._tooltipOldShow && (e._tooltipOldShow = o.show, o.show ? n.show() : n.hide())) : $o(e);
  }
  var So = {
    options: Oo,
    bind: xo,
    update: xo,
    unbind: function unbind(e) {
      $o(e);
    }
  };
  function ko(e) {
    e.addEventListener("click", Lo), e.addEventListener("touchstart", Ao, !!se && {
      passive: !0
    });
  }
  function No(e) {
    e.removeEventListener("click", Lo), e.removeEventListener("touchstart", Ao), e.removeEventListener("touchend", Po), e.removeEventListener("touchcancel", Do);
  }
  function Lo(e) {
    var t = e.currentTarget;
    e.closePopover = !t.$_vclosepopover_touch, e.closeAllPopover = t.$_closePopoverModifiers && !!t.$_closePopoverModifiers.all;
  }
  function Ao(e) {
    if (1 === e.changedTouches.length) {
      var t = e.currentTarget;
      t.$_vclosepopover_touch = !0;
      var n = e.changedTouches[0];
      t.$_vclosepopover_touchPoint = n, t.addEventListener("touchend", Po), t.addEventListener("touchcancel", Do);
    }
  }
  function Po(e) {
    var t = e.currentTarget;
    if (t.$_vclosepopover_touch = !1, 1 === e.changedTouches.length) {
      var n = e.changedTouches[0],
        o = t.$_vclosepopover_touchPoint;
      e.closePopover = Math.abs(n.screenY - o.screenY) < 20 && Math.abs(n.screenX - o.screenX) < 20, e.closeAllPopover = t.$_closePopoverModifiers && !!t.$_closePopoverModifiers.all;
    }
  }
  function Do(e) {
    e.currentTarget.$_vclosepopover_touch = !1;
  }
  var Io = {
    bind: function bind(e, t) {
      var n = t.value,
        o = t.modifiers;
      e.$_closePopoverModifiers = o, (void 0 === n || n) && ko(e);
    },
    update: function update(e, t) {
      var n = t.value,
        o = t.oldValue,
        r = t.modifiers;
      e.$_closePopoverModifiers = r, n !== o && (void 0 === n || n ? ko(e) : No(e));
    },
    unbind: function unbind(e) {
      No(e);
    }
  };
  var Mo = void 0;
  function zo() {
    zo.init || (zo.init = !0, Mo = -1 !== function () {
      var e = window.navigator.userAgent,
        t = e.indexOf("MSIE ");
      if (t > 0) return parseInt(e.substring(t + 5, e.indexOf(".", t)), 10);
      if (e.indexOf("Trident/") > 0) {
        var n = e.indexOf("rv:");
        return parseInt(e.substring(n + 3, e.indexOf(".", n)), 10);
      }
      var o = e.indexOf("Edge/");
      return o > 0 ? parseInt(e.substring(o + 5, e.indexOf(".", o)), 10) : -1;
    }());
  }
  var Ho = {
    render: function render() {
      var e = this.$createElement;
      return (this._self._c || e)("div", {
        staticClass: "resize-observer",
        attrs: {
          tabindex: "-1"
        }
      });
    },
    staticRenderFns: [],
    _scopeId: "data-v-b329ee4c",
    name: "resize-observer",
    methods: {
      compareAndNotify: function compareAndNotify() {
        this._w === this.$el.offsetWidth && this._h === this.$el.offsetHeight || (this._w = this.$el.offsetWidth, this._h = this.$el.offsetHeight, this.$emit("notify"));
      },
      addResizeHandlers: function addResizeHandlers() {
        this._resizeObject.contentDocument.defaultView.addEventListener("resize", this.compareAndNotify), this.compareAndNotify();
      },
      removeResizeHandlers: function removeResizeHandlers() {
        this._resizeObject && this._resizeObject.onload && (!Mo && this._resizeObject.contentDocument && this._resizeObject.contentDocument.defaultView.removeEventListener("resize", this.compareAndNotify), delete this._resizeObject.onload);
      }
    },
    mounted: function mounted() {
      var e = this;
      zo(), this.$nextTick(function () {
        e._w = e.$el.offsetWidth, e._h = e.$el.offsetHeight;
      });
      var t = document.createElement("object");
      this._resizeObject = t, t.setAttribute("aria-hidden", "true"), t.setAttribute("tabindex", -1), t.onload = this.addResizeHandlers, t.type = "text/html", Mo && this.$el.appendChild(t), t.data = "about:blank", Mo || this.$el.appendChild(t);
    },
    beforeDestroy: function beforeDestroy() {
      this.removeResizeHandlers();
    }
  };
  var Fo = {
      version: "0.4.5",
      install: function install(e) {
        e.component("resize-observer", Ho), e.component("ResizeObserver", Ho);
      }
    },
    Bo = null;
  function Ro(e) {
    var t = So.options.popover[e];
    return void 0 === t ? So.options[e] : t;
  }
  "undefined" != typeof window ? Bo = window.Vue : "undefined" != typeof global && (Bo = global.Vue), Bo && Bo.use(Fo);
  var Wo = !1;
  "undefined" != typeof window && "undefined" != typeof navigator && (Wo = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  var Vo = [],
    Uo = function Uo() {};
  "undefined" != typeof window && (Uo = window.Element);
  var qo = {
    name: "VPopover",
    components: {
      ResizeObserver: Ho
    },
    props: {
      open: {
        type: Boolean,
        default: !1
      },
      disabled: {
        type: Boolean,
        default: !1
      },
      placement: {
        type: String,
        default: function _default() {
          return Ro("defaultPlacement");
        }
      },
      delay: {
        type: [String, Number, Object],
        default: function _default() {
          return Ro("defaultDelay");
        }
      },
      offset: {
        type: [String, Number],
        default: function _default() {
          return Ro("defaultOffset");
        }
      },
      trigger: {
        type: String,
        default: function _default() {
          return Ro("defaultTrigger");
        }
      },
      container: {
        type: [String, Object, Uo, Boolean],
        default: function _default() {
          return Ro("defaultContainer");
        }
      },
      boundariesElement: {
        type: [String, Uo],
        default: function _default() {
          return Ro("defaultBoundariesElement");
        }
      },
      popperOptions: {
        type: Object,
        default: function _default() {
          return Ro("defaultPopperOptions");
        }
      },
      popoverClass: {
        type: [String, Array],
        default: function _default() {
          return Ro("defaultClass");
        }
      },
      popoverBaseClass: {
        type: [String, Array],
        default: function _default() {
          return So.options.popover.defaultBaseClass;
        }
      },
      popoverInnerClass: {
        type: [String, Array],
        default: function _default() {
          return So.options.popover.defaultInnerClass;
        }
      },
      popoverWrapperClass: {
        type: [String, Array],
        default: function _default() {
          return So.options.popover.defaultWrapperClass;
        }
      },
      popoverArrowClass: {
        type: [String, Array],
        default: function _default() {
          return So.options.popover.defaultArrowClass;
        }
      },
      autoHide: {
        type: Boolean,
        default: function _default() {
          return So.options.popover.defaultAutoHide;
        }
      },
      handleResize: {
        type: Boolean,
        default: function _default() {
          return So.options.popover.defaultHandleResize;
        }
      },
      openGroup: {
        type: String,
        default: null
      },
      openClass: {
        type: [String, Array],
        default: function _default() {
          return So.options.popover.defaultOpenClass;
        }
      }
    },
    data: function data() {
      return {
        isOpen: !1,
        id: Math.random().toString(36).substr(2, 10)
      };
    },
    computed: {
      cssClass: function cssClass() {
        return o({}, this.openClass, this.isOpen);
      },
      popoverId: function popoverId() {
        return "popover_".concat(this.id);
      }
    },
    watch: {
      open: function open(e) {
        e ? this.show() : this.hide();
      },
      disabled: function disabled(e, t) {
        e !== t && (e ? this.hide() : this.open && this.show());
      },
      container: function container(e) {
        if (this.isOpen && this.popperInstance) {
          var t = this.$refs.popover,
            n = this.$refs.trigger,
            o = this.$_findContainer(this.container, n);
          if (!o) return void console.warn("No container for popover", this);
          o.appendChild(t), this.popperInstance.scheduleUpdate();
        }
      },
      trigger: function trigger(e) {
        this.$_removeEventListeners(), this.$_addEventListeners();
      },
      placement: function placement(e) {
        var t = this;
        this.$_updatePopper(function () {
          t.popperInstance.options.placement = e;
        });
      },
      offset: "$_restartPopper",
      boundariesElement: "$_restartPopper",
      popperOptions: {
        handler: "$_restartPopper",
        deep: !0
      }
    },
    created: function created() {
      this.$_isDisposed = !1, this.$_mounted = !1, this.$_events = [], this.$_preventOpen = !1;
    },
    mounted: function mounted() {
      var e = this.$refs.popover;
      e.parentNode && e.parentNode.removeChild(e), this.$_init(), this.open && this.show();
    },
    deactivated: function deactivated() {
      this.hide();
    },
    beforeDestroy: function beforeDestroy() {
      this.dispose();
    },
    methods: {
      show: function show() {
        var e = this,
          t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
          n = t.event,
          o = (t.skipDelay, t.force);
        !(void 0 !== o && o) && this.disabled || (this.$_scheduleShow(n), this.$emit("show")), this.$emit("update:open", !0), this.$_beingShowed = !0, requestAnimationFrame(function () {
          e.$_beingShowed = !1;
        });
      },
      hide: function hide() {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
          t = e.event;
        e.skipDelay;
        this.$_scheduleHide(t), this.$emit("hide"), this.$emit("update:open", !1);
      },
      dispose: function dispose() {
        if (this.$_isDisposed = !0, this.$_removeEventListeners(), this.hide({
          skipDelay: !0
        }), this.popperInstance && (this.popperInstance.destroy(), !this.popperInstance.options.removeOnDestroy)) {
          var e = this.$refs.popover;
          e.parentNode && e.parentNode.removeChild(e);
        }
        this.$_mounted = !1, this.popperInstance = null, this.isOpen = !1, this.$emit("dispose");
      },
      $_init: function $_init() {
        -1 === this.trigger.indexOf("manual") && this.$_addEventListeners();
      },
      $_show: function $_show() {
        var e = this,
          t = this.$refs.trigger,
          n = this.$refs.popover;
        if (clearTimeout(this.$_disposeTimer), !this.isOpen) {
          if (this.popperInstance && (this.isOpen = !0, this.popperInstance.enableEventListeners(), this.popperInstance.scheduleUpdate()), !this.$_mounted) {
            var o = this.$_findContainer(this.container, t);
            if (!o) return void console.warn("No container for popover", this);
            o.appendChild(n), this.$_mounted = !0;
          }
          if (!this.popperInstance) {
            var r = i(i({}, this.popperOptions), {}, {
              placement: this.placement
            });
            if (r.modifiers = i(i({}, r.modifiers), {}, {
              arrow: i(i({}, r.modifiers && r.modifiers.arrow), {}, {
                element: this.$refs.arrow
              })
            }), this.offset) {
              var s = this.$_getOffset();
              r.modifiers.offset = i(i({}, r.modifiers && r.modifiers.offset), {}, {
                offset: s
              });
            }
            this.boundariesElement && (r.modifiers.preventOverflow = i(i({}, r.modifiers && r.modifiers.preventOverflow), {}, {
              boundariesElement: this.boundariesElement
            })), this.popperInstance = new te(t, n, r), requestAnimationFrame(function () {
              if (e.hidden) return e.hidden = !1, void e.$_hide();
              !e.$_isDisposed && e.popperInstance ? (e.popperInstance.scheduleUpdate(), requestAnimationFrame(function () {
                if (e.hidden) return e.hidden = !1, void e.$_hide();
                e.$_isDisposed ? e.dispose() : e.isOpen = !0;
              })) : e.dispose();
            });
          }
          var a = this.openGroup;
          if (a) for (var u, p = 0; p < Vo.length; p++) (u = Vo[p]).openGroup !== a && (u.hide(), u.$emit("close-group"));
          Vo.push(this), this.$emit("apply-show");
        }
      },
      $_hide: function $_hide() {
        var e = this;
        if (this.isOpen) {
          var t = Vo.indexOf(this);
          -1 !== t && Vo.splice(t, 1), this.isOpen = !1, this.popperInstance && this.popperInstance.disableEventListeners(), clearTimeout(this.$_disposeTimer);
          var n = So.options.popover.disposeTimeout || So.options.disposeTimeout;
          null !== n && (this.$_disposeTimer = setTimeout(function () {
            var t = e.$refs.popover;
            t && (t.parentNode && t.parentNode.removeChild(t), e.$_mounted = !1);
          }, n)), this.$emit("apply-hide");
        }
      },
      $_findContainer: function $_findContainer(e, t) {
        return "string" == typeof e ? e = window.document.querySelector(e) : !1 === e && (e = t.parentNode), e;
      },
      $_getOffset: function $_getOffset() {
        var e = t(this.offset),
          n = this.offset;
        return ("number" === e || "string" === e && -1 === n.indexOf(",")) && (n = "0, ".concat(n)), n;
      },
      $_addEventListeners: function $_addEventListeners() {
        var e = this,
          t = this.$refs.trigger,
          n = [],
          o = [];
        ("string" == typeof this.trigger ? this.trigger.split(" ").filter(function (e) {
          return -1 !== ["click", "hover", "focus"].indexOf(e);
        }) : []).forEach(function (e) {
          switch (e) {
            case "hover":
              n.push("mouseenter"), o.push("mouseleave");
              break;
            case "focus":
              n.push("focus"), o.push("blur");
              break;
            case "click":
              n.push("click"), o.push("click");
          }
        }), n.forEach(function (n) {
          var o = function o(t) {
            e.isOpen || (t.usedByTooltip = !0, !e.$_preventOpen && e.show({
              event: t
            }), e.hidden = !1);
          };
          e.$_events.push({
            event: n,
            func: o
          }), t.addEventListener(n, o);
        }), o.forEach(function (n) {
          var o = function o(t) {
            t.usedByTooltip || (e.hide({
              event: t
            }), e.hidden = !0);
          };
          e.$_events.push({
            event: n,
            func: o
          }), t.addEventListener(n, o);
        });
      },
      $_scheduleShow: function $_scheduleShow() {
        var e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        if (clearTimeout(this.$_scheduleTimer), e) this.$_show();else {
          var t = parseInt(this.delay && this.delay.show || this.delay || 0);
          this.$_scheduleTimer = setTimeout(this.$_show.bind(this), t);
        }
      },
      $_scheduleHide: function $_scheduleHide() {
        var e = this,
          t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        if (clearTimeout(this.$_scheduleTimer), n) this.$_hide();else {
          var o = parseInt(this.delay && this.delay.hide || this.delay || 0);
          this.$_scheduleTimer = setTimeout(function () {
            if (e.isOpen) {
              if (t && "mouseleave" === t.type) if (e.$_setTooltipNodeEvent(t)) return;
              e.$_hide();
            }
          }, o);
        }
      },
      $_setTooltipNodeEvent: function $_setTooltipNodeEvent(e) {
        var t = this,
          n = this.$refs.trigger,
          o = this.$refs.popover,
          r = e.relatedreference || e.toElement || e.relatedTarget;
        return !!o.contains(r) && (o.addEventListener(e.type, function r(i) {
          var s = i.relatedreference || i.toElement || i.relatedTarget;
          o.removeEventListener(e.type, r), n.contains(s) || t.hide({
            event: i
          });
        }), !0);
      },
      $_removeEventListeners: function $_removeEventListeners() {
        var e = this.$refs.trigger;
        this.$_events.forEach(function (t) {
          var n = t.func,
            o = t.event;
          e.removeEventListener(o, n);
        }), this.$_events = [];
      },
      $_updatePopper: function $_updatePopper(e) {
        this.popperInstance && (e(), this.isOpen && this.popperInstance.scheduleUpdate());
      },
      $_restartPopper: function $_restartPopper() {
        if (this.popperInstance) {
          var e = this.isOpen;
          this.dispose(), this.$_isDisposed = !1, this.$_init(), e && this.show({
            skipDelay: !0,
            force: !0
          });
        }
      },
      $_handleGlobalClose: function $_handleGlobalClose(e) {
        var t = this,
          n = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        this.$_beingShowed || (this.hide({
          event: e
        }), e.closePopover ? this.$emit("close-directive") : this.$emit("auto-hide"), n && (this.$_preventOpen = !0, setTimeout(function () {
          t.$_preventOpen = !1;
        }, 300)));
      },
      $_handleResize: function $_handleResize() {
        this.isOpen && this.popperInstance && (this.popperInstance.scheduleUpdate(), this.$emit("resize"));
      }
    }
  };
  function Go(e) {
    for (var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1], n = function n(_n2) {
        var o = Vo[_n2];
        if (o.$refs.popover) {
          var r = o.$refs.popover.contains(e.target);
          requestAnimationFrame(function () {
            (e.closeAllPopover || e.closePopover && r || o.autoHide && !r) && o.$_handleGlobalClose(e, t);
          });
        }
      }, o = 0; o < Vo.length; o++) n(o);
  }
  function Yo(e, t, n, o, r, i, s, a, u, p) {
    "boolean" != typeof s && (u = a, a = s, s = !1);
    var l = "function" == typeof n ? n.options : n;
    var f;
    if (e && e.render && (l.render = e.render, l.staticRenderFns = e.staticRenderFns, l._compiled = !0, r && (l.functional = !0)), o && (l._scopeId = o), i ? (f = function f(e) {
      (e = e || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext) || "undefined" == typeof __VUE_SSR_CONTEXT__ || (e = __VUE_SSR_CONTEXT__), t && t.call(this, u(e)), e && e._registeredComponents && e._registeredComponents.add(i);
    }, l._ssrRegister = f) : t && (f = s ? function (e) {
      t.call(this, p(e, this.$root.$options.shadowRoot));
    } : function (e) {
      t.call(this, a(e));
    }), f) if (l.functional) {
      var _e2 = l.render;
      l.render = function (t, n) {
        return f.call(n), _e2(t, n);
      };
    } else {
      var _e3 = l.beforeCreate;
      l.beforeCreate = _e3 ? [].concat(_e3, f) : [f];
    }
    return n;
  }
  "undefined" != typeof document && "undefined" != typeof window && (Wo ? document.addEventListener("touchend", function (e) {
    Go(e, !0);
  }, !se || {
    passive: !0,
    capture: !0
  }) : window.addEventListener("click", function (e) {
    Go(e);
  }, !0));
  var Xo = qo,
    Ko = function Ko() {
      var e = this,
        t = e.$createElement,
        n = e._self._c || t;
      return n("div", {
        staticClass: "v-popover",
        class: e.cssClass
      }, [n("div", {
        ref: "trigger",
        staticClass: "trigger",
        staticStyle: {
          display: "inline-block"
        },
        attrs: {
          "aria-describedby": e.popoverId,
          tabindex: -1 !== e.trigger.indexOf("focus") ? 0 : void 0
        }
      }, [e._t("default")], 2), e._v(" "), n("div", {
        ref: "popover",
        class: [e.popoverBaseClass, e.popoverClass, e.cssClass],
        style: {
          visibility: e.isOpen ? "visible" : "hidden"
        },
        attrs: {
          id: e.popoverId,
          "aria-hidden": e.isOpen ? "false" : "true",
          tabindex: e.autoHide ? 0 : void 0
        },
        on: {
          keyup: function keyup(t) {
            if (!t.type.indexOf("key") && e._k(t.keyCode, "esc", 27, t.key, ["Esc", "Escape"])) return null;
            e.autoHide && e.hide();
          }
        }
      }, [n("div", {
        class: e.popoverWrapperClass
      }, [n("div", {
        ref: "inner",
        class: e.popoverInnerClass,
        staticStyle: {
          position: "relative"
        }
      }, [n("div", [e._t("popover")], 2), e._v(" "), e.handleResize ? n("ResizeObserver", {
        on: {
          notify: e.$_handleResize
        }
      }) : e._e()], 1), e._v(" "), n("div", {
        ref: "arrow",
        class: e.popoverArrowClass
      })])])]);
    };
  Ko._withStripped = !0;
  var Jo = Yo({
      render: Ko,
      staticRenderFns: []
    }, void 0, Xo, void 0, !1, void 0, !1, void 0, void 0, void 0),
    Qo = function () {
      try {
        var e = it(Object, "defineProperty");
        return e({}, "", {}), e;
      } catch (e) {}
    }();
  var Zo = function Zo(e, t, n) {
    "__proto__" == t && Qo ? Qo(e, t, {
      configurable: !0,
      enumerable: !0,
      value: n,
      writable: !0
    }) : e[t] = n;
  };
  var er = function er(e, t, n) {
    (void 0 === n || pe(e[t], n)) && (void 0 !== n || t in e) || Zo(e, t, n);
  };
  var tr = function (e) {
      return function (t, n, o) {
        for (var r = -1, i = Object(t), s = o(t), a = s.length; a--;) {
          var u = s[e ? a : ++r];
          if (!1 === n(i[u], u, i)) break;
        }
        return t;
      };
    }(),
    nr = Ee(function (e, t) {
      var n = t && !t.nodeType && t,
        o = n && e && !e.nodeType && e,
        r = o && o.exports === n ? Te.Buffer : void 0,
        i = r ? r.allocUnsafe : void 0;
      e.exports = function (e, t) {
        if (t) return e.slice();
        var n = e.length,
          o = i ? i(n) : new e.constructor(n);
        return e.copy(o), o;
      };
    });
  var or = function or(e) {
    var t = new e.constructor(e.byteLength);
    return new Rt(t).set(new Rt(e)), t;
  };
  var rr = function rr(e, t) {
    var n = t ? or(e.buffer) : e.buffer;
    return new e.constructor(n, e.byteOffset, e.length);
  };
  var ir = function ir(e, t) {
      var n = -1,
        o = e.length;
      for (t || (t = Array(o)); ++n < o;) t[n] = e[n];
      return t;
    },
    sr = Object.create,
    ar = function () {
      function e() {}
      return function (t) {
        if (!He(t)) return {};
        if (sr) return sr(t);
        e.prototype = t;
        var n = new e();
        return e.prototype = void 0, n;
      };
    }(),
    ur = Bn(Object.getPrototypeOf, Object);
  var pr = function pr(e) {
    return "function" != typeof e.constructor || Fn(e) ? {} : ar(ur(e));
  };
  var lr = function lr(e) {
      return gn(e) && Un(e);
    },
    fr = "[object Object]",
    cr = Function.prototype,
    dr = Object.prototype,
    hr = cr.toString,
    vr = dr.hasOwnProperty,
    mr = hr.call(Object);
  var gr = function gr(e) {
    if (!gn(e) || ze(e) != fr) return !1;
    var t = ur(e);
    if (null === t) return !0;
    var n = vr.call(t, "constructor") && t.constructor;
    return "function" == typeof n && n instanceof n && hr.call(n) == mr;
  };
  var br = function br(e, t) {
      if (("constructor" !== t || "function" != typeof e[t]) && "__proto__" != t) return e[t];
    },
    yr = Object.prototype.hasOwnProperty;
  var _r = function _r(e, t, n) {
    var o = e[t];
    yr.call(e, t) && pe(o, n) && (void 0 !== n || t in e) || Zo(e, t, n);
  };
  var wr = function wr(e, t, n, o) {
    var r = !n;
    n || (n = {});
    for (var i = -1, s = t.length; ++i < s;) {
      var a = t[i],
        u = o ? o(n[a], e[a], a, n, e) : void 0;
      void 0 === u && (u = e[a]), r ? Zo(n, a, u) : _r(n, a, u);
    }
    return n;
  };
  var Or = function Or(e) {
      var t = [];
      if (null != e) for (var n in Object(e)) t.push(n);
      return t;
    },
    Er = Object.prototype.hasOwnProperty;
  var Cr = function Cr(e) {
    if (!He(e)) return Or(e);
    var t = Fn(e),
      n = [];
    for (var o in e) ("constructor" != o || !t && Er.call(e, o)) && n.push(o);
    return n;
  };
  var jr = function jr(e) {
    return Un(e) ? zn(e, !0) : Cr(e);
  };
  var Tr = function Tr(e) {
    return wr(e, jr(e));
  };
  var $r = function $r(e, t, n, o, r, i, s) {
    var a = br(e, n),
      u = br(t, n),
      p = s.get(u);
    if (p) er(e, n, p);else {
      var l = i ? i(a, u, n + "", e, t, s) : void 0,
        f = void 0 === l;
      if (f) {
        var c = pn(u),
          d = !c && jn(u),
          h = !c && !d && In(u);
        l = u, c || d || h ? pn(a) ? l = a : lr(a) ? l = ir(a) : d ? (f = !1, l = nr(u, !0)) : h ? (f = !1, l = rr(u, !0)) : l = [] : gr(u) || En(u) ? (l = a, En(a) ? l = Tr(a) : He(a) && !Ue(a) || (l = pr(u))) : f = !1;
      }
      f && (s.set(u, l), r(l, u, o, i, s), s.delete(u)), er(e, n, l);
    }
  };
  var xr = function e(t, n, o, r, i) {
    t !== n && tr(n, function (s, a) {
      if (i || (i = new Nt()), He(s)) $r(t, n, a, o, e, r, i);else {
        var u = r ? r(br(t, a), s, a + "", t, n, i) : void 0;
        void 0 === u && (u = s), er(t, a, u);
      }
    }, jr);
  };
  var Sr = function Sr(e) {
    return e;
  };
  var kr = function kr(e, t, n) {
      switch (n.length) {
        case 0:
          return e.call(t);
        case 1:
          return e.call(t, n[0]);
        case 2:
          return e.call(t, n[0], n[1]);
        case 3:
          return e.call(t, n[0], n[1], n[2]);
      }
      return e.apply(t, n);
    },
    Nr = Math.max;
  var Lr = function Lr(e, t, n) {
    return t = Nr(void 0 === t ? e.length - 1 : t, 0), function () {
      for (var o = arguments, r = -1, i = Nr(o.length - t, 0), s = Array(i); ++r < i;) s[r] = o[t + r];
      r = -1;
      for (var a = Array(t + 1); ++r < t;) a[r] = o[r];
      return a[t] = n(s), kr(e, this, a);
    };
  };
  var Ar = function Ar(e) {
      return function () {
        return e;
      };
    },
    Pr = Qo ? function (e, t) {
      return Qo(e, "toString", {
        configurable: !0,
        enumerable: !1,
        value: Ar(t),
        writable: !0
      });
    } : Sr,
    Dr = 800,
    Ir = 16,
    Mr = Date.now;
  var zr = function (e) {
    var t = 0,
      n = 0;
    return function () {
      var o = Mr(),
        r = Ir - (o - n);
      if (n = o, r > 0) {
        if (++t >= Dr) return arguments[0];
      } else t = 0;
      return e.apply(void 0, arguments);
    };
  }(Pr);
  var Hr = function Hr(e, t) {
    return zr(Lr(e, t, Sr), e + "");
  };
  var Fr = function Fr(e, t, n) {
    if (!He(n)) return !1;
    var o = _typeof(t);
    return !!("number" == o ? Un(n) && xn(t, n.length) : "string" == o && t in n) && pe(n[t], e);
  };
  var Br = function (e) {
    return Hr(function (t, n) {
      var o = -1,
        r = n.length,
        i = r > 1 ? n[r - 1] : void 0,
        s = r > 2 ? n[2] : void 0;
      for (i = e.length > 3 && "function" == typeof i ? (r--, i) : void 0, s && Fr(n[0], n[1], s) && (i = r < 3 ? void 0 : i, r = 1), t = Object(t); ++o < r;) {
        var a = n[o];
        a && e(t, a, o, i);
      }
      return t;
    });
  }(function (e, t, n) {
    xr(e, t, n);
  });
  function Rr(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (!Rr.installed) {
      Rr.installed = !0;
      var n = {};
      Br(n, Oo, t), qr.options = n, So.options = n, e.directive("tooltip", So), e.directive("close-popover", Io), e.component("v-popover", Jo);
    }
  }
  !function (e, t) {
    void 0 === t && (t = {});
    var n = t.insertAt;
    if (e && "undefined" != typeof document) {
      var o = document.head || document.getElementsByTagName("head")[0],
        r = document.createElement("style");
      r.type = "text/css", "top" === n && o.firstChild ? o.insertBefore(r, o.firstChild) : o.appendChild(r), r.styleSheet ? r.styleSheet.cssText = e : r.appendChild(document.createTextNode(e));
    }
  }(".resize-observer[data-v-b329ee4c]{position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;border:none;background-color:transparent;pointer-events:none;display:block;overflow:hidden;opacity:0}.resize-observer[data-v-b329ee4c] object{display:block;position:absolute;top:0;left:0;height:100%;width:100%;overflow:hidden;pointer-events:none;z-index:-1}");
  var Wr = So,
    Vr = Io,
    Ur = Jo,
    qr = {
      install: Rr,
      get enabled() {
        return _o.enabled;
      },
      set enabled(e) {
        _o.enabled = e;
      }
    },
    Gr = null;
  "undefined" != typeof window ? Gr = window.Vue : "undefined" != typeof global && (Gr = global.Vue), Gr && Gr.use(qr), e.VClosePopover = Vr, e.VPopover = Ur, e.VTooltip = Wr, e.createTooltip = To, e.default = qr, e.destroyTooltip = $o, e.install = Rr, Object.defineProperty(e, "__esModule", {
    value: !0
  });
});