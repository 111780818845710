import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card AllianzAktRawData"
  }, [_c('div', {
    staticClass: "card-header noselect"
  }, [_c('h4', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        _vm.isShowingAllianz = !_vm.isShowingAllianz;
      }
    }
  }, [_vm._v("Allianz Daten")]), _vm._m(0)]), _vm.isShowingAllianz && _vm.allianzData ? _c('div', {
    staticClass: "card-content"
  }, [_c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Allgemein")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Polizzennummer")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.polnr,
      expression: "allianzData.polnr"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "polnr"
    },
    domProps: {
      "value": _vm.allianzData.polnr
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "polnr", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Versicherungsobjekt")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Risiko Adresse")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.adresse_risiko,
      expression: "allianzData.adresse_risiko"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Risiko Adresse"
    },
    domProps: {
      "value": _vm.allianzData.adresse_risiko
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "adresse_risiko", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Risiko Adresse Zusatz ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.adresse_risiko_zusatz,
      expression: "allianzData.adresse_risiko_zusatz"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Risiko Adresse Zusatz"
    },
    domProps: {
      "value": _vm.allianzData.adresse_risiko_zusatz
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "adresse_risiko_zusatz", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Risiko PLZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.plz_risiko,
      expression: "allianzData.plz_risiko"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Risiko PLZ"
    },
    domProps: {
      "value": _vm.allianzData.plz_risiko
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "plz_risiko", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Risiko Ort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.ortschaft_risiko,
      expression: "allianzData.ortschaft_risiko"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Risiko Ort"
    },
    domProps: {
      "value": _vm.allianzData.ortschaft_risiko
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "ortschaft_risiko", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("VS Feuer")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.vs_feuer,
      expression: "allianzData.vs_feuer"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "VS Feuer"
    },
    domProps: {
      "value": _vm.allianzData.vs_feuer
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "vs_feuer", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("LD")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.ld,
      expression: "allianzData.ld"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Landesdirektion"
    },
    domProps: {
      "value": _vm.allianzData.ld
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "ld", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Inspektion")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.insp,
      expression: "allianzData.insp"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Landesdirektion"
    },
    domProps: {
      "value": _vm.allianzData.insp
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "insp", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Koordinaten")]), _c('div', {
    staticClass: "input"
  }, [_c('div', {
    staticClass: "btn _green _small",
    on: {
      "click": function click($event) {
        return _vm.prepareGettingLocationForText();
      }
    }
  }, [_vm._v("Koordinaten bestimmen")]), _c('span', [_vm._v(_vm._s((_vm.currentCoordinates || {}).lat) + "/" + _vm._s((_vm.currentCoordinates || {}).lng))])])]), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Versicherungsnehmer")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("VN Vorname")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.vorname,
      expression: "allianzData.vorname"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Vorname"
    },
    domProps: {
      "value": _vm.allianzData.vorname
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "vorname", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("VN Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.name,
      expression: "allianzData.name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.allianzData.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "name", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("VN Adresse")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.adresse,
      expression: "allianzData.adresse"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Adresse"
    },
    domProps: {
      "value": _vm.allianzData.adresse
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "adresse", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("VN PLZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.plz,
      expression: "allianzData.plz"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "PLZ"
    },
    domProps: {
      "value": _vm.allianzData.plz
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "plz", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("VN Ort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.city,
      expression: "allianzData.city"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Ort"
    },
    domProps: {
      "value": _vm.allianzData.city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "spacer _vertical _small"
  }), _c('h5', {
    staticClass: "color-blue"
  }, [_vm._v("Kontakt (v.Allianz)")]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Kontakt Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.contact_name,
      expression: "allianzData.contact_name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "contact_name",
      "name": "contact_name"
    },
    domProps: {
      "value": _vm.allianzData.contact_name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "contact_name", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Kontakt Telefon")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.contact_phone,
      expression: "allianzData.contact_phone"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "contact_phone",
      "name": "contact_phone"
    },
    domProps: {
      "value": _vm.allianzData.contact_phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "contact_phone", $event.target.value);
      }
    }
  })]), _c('label', {
    staticClass: "input-field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Kontakt Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.allianzData.contact_email,
      expression: "allianzData.contact_email"
    }],
    staticClass: "input",
    attrs: {
      "type": "email",
      "placeholder": "contact_email",
      "name": "contact_email"
    },
    domProps: {
      "value": _vm.allianzData.contact_email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.allianzData, "contact_email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "clear"
  })]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "fr"
  }, [_c('small', [_vm._v("(Reine Rohdaten der Allianz)")])]);
}];
export { render, staticRenderFns };