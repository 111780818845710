import _defineProperty from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { mapGetters, mapState, mapActions } from "vuex";
var vu;
export default {
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  data: function data() {
    return {
      zoom: 11,
      center: {
        lat: 48.284987,
        lng: 16.367482
      },
      maincenter: {
        lat: 48.284987,
        lng: 16.367482
      },
      CurrentTermine: {},
      CurrentTermineWeek: ""
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["user", "projects", "folder", "sparten", "maplist", "status"])), {}, {
    filteredList: function filteredList() {
      var list = {
        todo: [],
        done: [],
        over: []
      };
      var maplist = vu.maplist;
      for (var i in maplist) {
        var item = maplist[i];
        // if(item.stid == 100000003 || item.stid == 100000012){
        if (item.stid == 100000003 && !item.dayover) {
          list.todo.push(i);
        }
        if (item.stid == 100000003 && item.dayover) {
          list.over.push(i);
        }
      }
      return list;
    },
    week: function week() {
      var _weekdays, _weekdays2;
      var weekdays = (_weekdays = {}, _defineProperty(_weekdays, "" + vu.$dayjs().startOf("week").add(1, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays, "" + vu.$dayjs().startOf("week").add(2, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays, "" + vu.$dayjs().startOf("week").add(3, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays, "" + vu.$dayjs().startOf("week").add(4, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays, "" + vu.$dayjs().startOf("week").add(5, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays, "" + vu.$dayjs().startOf("week").add(6, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays, "" + vu.$dayjs().startOf("week").add(7, "day").format("YYYYMMDD"), []), _weekdays);
      var weekdays2 = (_weekdays2 = {}, _defineProperty(_weekdays2, "" + vu.$dayjs().startOf("week").add(1 + 7, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays2, "" + vu.$dayjs().startOf("week").add(2 + 7, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays2, "" + vu.$dayjs().startOf("week").add(3 + 7, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays2, "" + vu.$dayjs().startOf("week").add(4 + 7, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays2, "" + vu.$dayjs().startOf("week").add(5 + 7, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays2, "" + vu.$dayjs().startOf("week").add(6 + 7, "day").format("YYYYMMDD"), []), _defineProperty(_weekdays2, "" + vu.$dayjs().startOf("week").add(7 + 7, "day").format("YYYYMMDD"), []), _weekdays2);
      var list = vu.maplist;
      for (var i in list) {
        var item = list[i];
        if (item.stid == 100000003 && !item.dayover) {
          var slot = vu.$dayjs(item.besdatum).format("YYYYMMDD");
          if (weekdays[slot]) weekdays[slot].push(i);
          if (weekdays2[slot]) weekdays2[slot].push(i);
        }
      }
      // let arr = ['mo','di','mi','do','fr','sa','so']
      return [weekdays, weekdays2];
    }
  })
};