import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import { mapGetters, mapState, mapActions, createNamespacedHelpers } from "vuex";
import { mapFields } from "vuex-map-fields";
import images from "@/libs/images";
import _ from "lodash";
var notifications = createNamespacedHelpers('notifications');
var that = null;
export default {
  beforeCreate: function beforeCreate() {
    that = this;
  },
  name: "App",
  data: function data() {
    return {
      isToggeldSidebar: false,
      firstUserMetaDataCall: true,
      wait25: false,
      // just to wait for triggering if there is internet
      CallInterval: null,
      isShowingInternetError: false,
      triggeredfirsttime: false,
      isCompInternet: true,
      startloaderTimeOut: null,
      reLoadAfterMinutes: 15,
      excludeFromKeepingAlive: ["AllianzAkt", "AllianzList", "AllianzReports", "AllianzListId", "AllianzHVW", "AllianzHvwId", "AllianzLinkHvw", "AllianzLinkHvw", "AllianzLinkObjekt"

      // "DateienAllianz"
      ]
      // isWsConnected: false
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapFields(["isUploadingOfflineFiles", "uploadInfo", "isLoading", "isInternet", "isWsConnected", "isShowingOnlySp", "isAllowToFetchData"])), mapState([
  // "isShowingOnlySp",
  "userLogin", "user", "connected", "timer", "heresince", "mobile", "SaveLaterFS"])), mapGetters(["_fbGeneral", "_currentUser", "_userLoggedIn"])), notifications.mapGetters(['ownPendingNotificationsCount'])), {}, {
    generalUserInfo: function generalUserInfo() {
      if (that.isFb) {
        var generalUserInfo = that._fbGeneral.userInfo || "";
        return generalUserInfo;
      } else {
        return "";
      }
    },
    hasNuVersion: function hasNuVersion() {
      if (that.isFb) {
        var FBV = that._fbGeneral.version || "0.0.0";
        var THISV = that.$env.version;
        if (THISV.indexOf("V ")) THISV.substring(2, THISV.length);
        if (FBV.indexOf("V ")) FBV.substring(2, FBV.length);
        return that.CompareV(THISV, FBV) < 0;
      } else {
        return false;
      }
    },
    userName: function userName() {
      return that.$store.state.userDataCrm.userName;
    },
    isShowingSidebar: function isShowingSidebar() {
      return that.isFb || that.isCrm ? true : false;
    },
    isShowingInfoToUseOnlyOwn: function isShowingInfoToUseOnlyOwn() {
      return ((that.mobile || {}).os || {}).name == "Android" && (that._isAdmin || that._isSv && that.svRights.allianzReadWriteAll) && that._currentUser.loadOnlyMy == false;
    },
    __isShowingOnlySp: function __isShowingOnlySp() {
      return (that.$route.query || {}).onlysp == "true";
    },
    __isAllowToFetchData: function __isAllowToFetchData() {
      return (that.$route.query || {}).noload == "true";
    }
  }),
  // GLOBAL WATCHERS:

  watch: {
    '_fbGeneral.version': {
      immediate: true,
      handler: function handler() {
        var nv = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
        var ov = that.$env.version;
        if (nv.indexOf("V ")) nv = nv.substring(2, nv.length);
        if (ov.indexOf("V ")) ov = ov.substring(2, ov.length);
        if (that.CompareV(nv, ov) > 0) window.newVersionAvailable(nv);
      }
    },
    __isShowingOnlySp: {
      immediate: true,
      handler: function handler(nv) {
        that.isShowingOnlySp = nv;
      }
    },
    __isAllowToFetchData: {
      immediate: true,
      handler: function handler(nv) {
        that.isAllowToFetchData = nv;
      }
    },
    // _userLoggedIn: {
    //   immediate: true,
    //   handler(nv) {
    //     if (nv) {
    //       that.$socket.io.opts.query = `token=${nv}`;
    //     }
    //   },
    // },

    $route: {
      deep: true,
      immediate: true,
      handler: function handler(nv) {
        window.LOG.blue("$route", (nv.meta.load || []).length > 0);
        if ((nv.meta.load || []).length > 0) that._combinedApiCalls();
      }
    },
    isCompInternet: {
      handler: _.debounce(function (nv) {
        console.log("that.isShowingInternetError", that.isShowingInternetError);
        that.isInternet = nv;
      }, 3000)
    },
    ownPendingNotificationsCount: {
      handler: _.debounce(function (nv, ov) {
        // console.log("_countNotifications", nv, ov);
        if (nv > ov) {
          that.$notify({
            group: "notifications",
            text: "Neue Nachrichten verfügbar!",
            type: "success"
          });
        }
      }, 500)
    }
  },
  created: function created() {
    that.$root.img = images;
    that.SET(["userLogin", "isUserCurrentlySigningIn", false]);
    that.SET(["mobile", that.$_currentDevice()]);
    setTimeout(function () {
      var timer = null;
      var connectedRef = that.$fb.database().ref(".info/connected");
      connectedRef.on("value", function (snap) {
        window.LOG.red("isInternet", snap.val());
        that.isShowingInternetError = !snap.val();
        that.isCompInternet = snap.val();
      });
    }, 3000);
    setTimeout(function () {
      console.log("window.updateAvailable", window.updateAvailable);
      if (window.updateAvailable) console.log("UPDATE AVAILABLE");
    }, 5000);
  },
  mounted: function mounted() {
    that.checkAncestor();
    setTimeout(function () {
      that.checkAncestor();
    }, 1000 * 10);
    setTimeout(function () {
      that.checkAncestor();
    }, 1000 * 20);
    setTimeout(function () {
      that.checkAncestor();
    }, 1000 * 30);
    setInterval(function () {
      that._combinedApiCalls();
    }, 1000 * 60 * that.reLoadAfterMinutes);
  },
  methods: _objectSpread(_objectSpread({}, mapActions(["_combinedApiCalls"])), {}, {
    checkAncestor: function checkAncestor() {
      if (window.location.ancestorOrigins && window.location.ancestorOrigins[0] === "https://consense.crm4.dynamics.com") {
        that.isShowingOnlySp = true;
        that.isAllowToFetchData = true;
      }
    },
    forceRefreshPage: function forceRefreshPage() {
      location.reload(true);
    },
    LoderStarts: function LoderStarts() {
      window.clearTimeout(that.startloaderTimeOut);
      that.startloaderTimeOut = setTimeout(function () {
        that.$modal.hide("Loader");
      }, 22000);
    },
    CompareV: function CompareV(a, b) {
      window.LOG.log(a, b);
      if (a === b) {
        return 0;
      }
      var aComponents = a.split(".");
      var bComponents = b.split(".");
      var len = Math.min(aComponents.length, bComponents.length);
      // loop while the components are equal
      if (a === b) {
        return 0;
      } else {
        for (var i = 0; i < len; i++) {
          // A bigger than B
          if (parseInt(aComponents[i]) > parseInt(bComponents[i])) {
            return Math.pow(10, len - i);
          }
          // B bigger than A
          if (parseInt(aComponents[i]) < parseInt(bComponents[i])) {
            return -Math.pow(10, len - i);
          } else {
            if (i == len - 1) {
              return 0;
            }
          }
        }
      }
    }
  })
};