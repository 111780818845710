import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.splice.js";
export var handleSnapshotUpdates = function handleSnapshotUpdates(ar) {
  return function (snapshot) {
    var _iterator = _createForOfIteratorHelper(snapshot.docChanges()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var change = _step.value;
        if (change.type === 'added') ar.splice(change.newIndex, 0, _objectSpread({
          id: change.doc.id
        }, change.doc.data()));
        if (change.type === 'modified') {
          ar.splice(change.oldIndex, 1);
          ar.splice(change.newIndex, 0, _objectSpread({
            id: change.doc.id
          }, change.doc.data()));
        }
        if (change.type === 'removed') ar.splice(change.oldIndex, 1);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  };
};