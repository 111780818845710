var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "OfflineImageStore"
  }, [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "showOflineImages",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.getOfflineImages
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Offline gespeicherte Bilder"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('showOflineImages');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('ul', {
    staticClass: "images-list"
  }, [_vm._l(_vm.offlineImages, function (projectId, projectIdx) {
    return _c('li', [_c('div', {
      staticClass: "bold",
      on: {
        "click": function click($event) {
          return _vm.$modal.hide('showOflineImages');
        }
      }
    }, _vm._l([_vm._fbProjects[projectId]], function (p) {
      return _c('span', [p ? _c('span', [_c('router-link', {
        staticClass: "link",
        attrs: {
          "to": '/allianz/list/' + projectId
        }
      }, [_vm._v(_vm._s(p.allianzData.adresse_risiko) + ", " + _vm._s(p.allianzData.plz_risiko) + " " + _vm._s(p.allianzData.ortschaft_risiko))])], 1) : _vm._e()]);
    }), 0)]);
  }), _vm.offlineImages.length == 0 ? _c('li', [_vm._v("Keine Daten vorhanden!")]) : _vm._e()], 2)]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('showOflineImages');
      }
    }
  }, [_vm._v("Schließen")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };