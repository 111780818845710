var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('drop-list', {
    staticClass: "flex",
    attrs: {
      "items": _vm.value,
      "mode": "cut"
    },
    on: {
      "reorder": function reorder($event) {
        return $event.apply(_vm.value);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
          reorder = _ref.reorder;
        return [_c('drag', {
          key: item.id,
          attrs: {
            "data": item
          },
          on: {
            "cut": function cut($event) {
              return _vm.removeImage(item.id);
            }
          }
        }, [_c('div', {
          staticClass: "chip"
        }, [_c('div', {
          staticClass: "caption"
        }, [_c('span', {
          staticClass: "input"
        }, [_vm._v(_vm._s(item.caption || item.path))])]), _c('div', {
          staticClass: "deleter _hover",
          on: {
            "click": function click($event) {
              return _vm.removeImage(item.id);
            }
          }
        }, [_c('i', {
          staticClass: "icon-times inline"
        })])])])];
      }
    }])
  }), _c('button', {
    staticClass: "btn _bordered",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('ChooseImagesForBlock', {
          content: _vm.popupContent
        });
      }
    }
  }, [_vm._v("Dateien aus Sharepoint anhängen")]), _c('image-chooser')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };