import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find-index.js";
import Vue from "vue";
import _ from "lodash";
// import self from "@/libs/utils";
import * as initialState from "@/store/initialState";
export default {
  setAuth: function setAuth(state, v) {
    var a, b, c, d;
    var _v = _slicedToArray(v, 4);
    a = _v[0];
    b = _v[1];
    c = _v[2];
    d = _v[3];
    if (typeof d != "undefined") {
      if (!state[a][b]) Vue.set(state[a], b, {});
      Vue.set(state[a][b], c, d);
    } else if (typeof c != "undefined") {
      Vue.set(state[a], b, c);
    } else {
      Vue.set(state, a, b);
    }
  },
  set: function set(state, v) {
    var a, b, c, d;
    var _v2 = _slicedToArray(v, 4);
    a = _v2[0];
    b = _v2[1];
    c = _v2[2];
    d = _v2[3];
    if (typeof d != "undefined") {
      if (!state[a][b]) Vue.set(state[a], b, {});
      Vue.set(state[a][b], c, d);
    } else if (typeof c != "undefined") {
      Vue.set(state[a], b, c);
    } else {
      Vue.set(state, a, b);
    }
  },
  update: function update(state, v) {
    var _v3 = _slicedToArray(v, 4),
      a = _v3[0],
      b = _v3[1],
      c = _v3[2],
      d = _v3[3];
    if (v.length == 4) {
      if (state[a][b] == undefined) {
        Vue.set(state[a], b, _defineProperty({}, c, d));
      } else if (state[a][b][c] == undefined) {
        Vue.set(state[a][b], c, d);
      } else {
        var e = _objectSpread(_objectSpread({}, state[a][b][c]), d);
        Vue.set(state[a][b], c, e);
      }
    } else if (v.length == 3) {
      if (state[a][b] == undefined) {
        Vue.set(state[a], b, c);
      } else {
        var _e = _.mergeWith(state[a][b], c, function (objValue, srcValue) {
          if (_.isArray(srcValue)) {
            return srcValue;
          }
        });
        Vue.set(state[a], b, _e);
      }
    } else Vue.set(state, a, Object.assign(state[a], b));
  },
  unset: function unset(state, v) {
    // window.LOG.log('UNSETUNSETUNSETUNSETUNSET');
    var a, b, c, d;
    var _v4 = _slicedToArray(v, 4);
    a = _v4[0];
    b = _v4[1];
    c = _v4[2];
    d = _v4[3];
    if (typeof d != "undefined") Vue.delete(state[a][b][c], d);else if (typeof c != "undefined") Vue.delete(state[a][b], c);else Vue.delete(state[a], b);
  },
  updateFB: function updateFB(state, v) {
    var _v5 = _slicedToArray(v, 3),
      collection = _v5[0],
      docId = _v5[1],
      data = _v5[2];
    // console.log('state[collection].data', state[collection].data[docId]);
    // console.log('updateFB', [collection, docId, data]);
    var newModel = _objectSpread(_objectSpread({}, state[collection].data[docId]), data);
    // console.log('newModel', newModel);
    Vue.set(state[collection].data, docId, newModel);
  },
  setusermeta: function setusermeta(s, v) {
    window.LOG.pink('------');
    s.usermetadata = v;
  },
  reset: function reset(s, key) {
    var initStateVal = initialState.default()[key];
    s[key] = initStateVal;
  },
  resetstate: function resetstate(state) {
    var exceptkeys = ["isUsingOffline"];
    var s = initialState.default();
    Object.keys(s).forEach(function (key) {
      if (!exceptkeys.includes(key)) state[key] = s[key];
    });
  },
  FETCH: function FETCH(state, data) {
    Vue.set(state[data.id], "data", data.entries);
  },
  FETCH_PROJECTS: function FETCH_PROJECTS(state, entries) {
    Vue.set(state.FB_projects, "data", entries);
  },
  FETCH_COLS: function FETCH_COLS(state, entries) {
    Vue.set(state.FB_cols, "data", _.keyBy(entries, "id"));
  },
  FETCH_REMINDER: function FETCH_REMINDER(state, entries) {
    Vue.set(state.FB_reminder, "data", entries);
  },
  FETCH_SVTEAM: function FETCH_SVTEAM(state, entries) {
    Vue.set(state.FB_svteam, "data", entries);
  },
  FETCH_HTML_DATA: function FETCH_HTML_DATA(state, entries) {
    Vue.set(state.FB_html_data, "data", _.keyBy(entries, "id"));
  },
  FETCH_HVW: function FETCH_HVW(state, entries) {
    Vue.set(state.FB_hvw, "data", _.keyBy(entries, "id"));
  },
  FETCH_STATUS: function FETCH_STATUS(state, entries) {
    Vue.set(state.FB_status, "data", entries.status);
  },
  FETCH_HVW_ITMAX: function FETCH_HVW_ITMAX(state, entries) {
    Vue.set(state, "hvw", _.keyBy(entries, "id"));
  },
  UPDATE_HVW: function UPDATE_HVW(state, entry) {
    var index = _.findIndex(state.hvw, {
      _id: entry._id
    });
    Vue.set(state.hvw, index, entry);
    Vue.set(state.FB_hvw.data, entry._id, entry);
  }
};