import "core-js/modules/es.string.small.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.localDate,
      expression: "localDate",
      modifiers: {
        "lazy": true
      }
    }, {
      name: "debounce",
      rawName: "v-debounce",
      value: 50,
      expression: "50"
    }],
    staticClass: "crm-date-native dateInput",
    class: {
      _smallerDateInput: !!_vm.small
    },
    attrs: {
      "type": _vm.whichType || 'datetime-local'
    },
    domProps: {
      "value": _vm.localDate
    },
    on: {
      "change": function change($event) {
        _vm.localDate = $event.target.value;
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };