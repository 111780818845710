import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.bold.js";
import "core-js/modules/es.string.italics.js";
import "core-js/modules/es.string.strike.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tiptap"
  }, [_c('div', {
    staticClass: "tiptap__editor tiptap__editor--border"
  }, [_c('editor-menu-bubble', {
    attrs: {
      "editor": _vm.editor
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var commands = _ref.commands,
          menu = _ref.menu,
          getNodeAttrs = _ref.getNodeAttrs;
        return [_c('div', {
          staticClass: "bubble-menu",
          class: {
            'is-active': menu.isActive
          },
          style: "left: ".concat(menu.left, "px; bottom: ").concat(menu.bottom, "px;")
        }, [_vm.selectedNode.type === 'sharepoint_image' ? [_c('button', {
          staticClass: "menubar__button",
          on: {
            "click": function click($event) {
              return _vm.showCaptionDialog(commands.setSharepointCaption, _vm.selectedNode.attrs.caption);
            }
          }
        }, [_vm._v("Bilduntertitel...")]), _c('span', {
          staticClass: "space"
        }), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': getNodeAttrs('sharepoint_image').size === 'low'
          },
          on: {
            "click": function click($event) {
              return commands.setSharepointImageSize('low');
            }
          }
        }, [_vm._v("S")]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': getNodeAttrs('sharepoint_image').size === 'medium'
          },
          on: {
            "click": function click($event) {
              return commands.setSharepointImageSize('medium');
            }
          }
        }, [_vm._v("M")]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': getNodeAttrs('sharepoint_image').size === 'high'
          },
          on: {
            "click": function click($event) {
              return commands.setSharepointImageSize('high');
            }
          }
        }, [_vm._v("L")])] : _vm._e()], 2)];
      }
    }])
  }), _c('editor-menu-bar', {
    attrs: {
      "editor": _vm.editor
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var commands = _ref2.commands,
          isActive = _ref2.isActive;
        return [_c('div', {
          staticClass: "menubar"
        }, [_c('button', {
          staticClass: "menubar__button",
          on: {
            "click": commands.undo
          }
        }, [_c('i', {
          staticClass: "icon-rotate-left"
        })]), _c('button', {
          staticClass: "menubar__button",
          on: {
            "click": commands.redo
          }
        }, [_c('i', {
          staticClass: "icon-rotate-right"
        })]), _c('span', {
          staticClass: "space"
        }), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': isActive.heading({
              level: 4
            })
          },
          on: {
            "click": function click($event) {
              return commands.heading({
                level: 4
              });
            }
          }
        }, [_vm._v("H1")]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': isActive.bold()
          },
          on: {
            "click": commands.bold
          }
        }, [_c('span', {
          staticClass: "bold"
        }, [_vm._v("F")])]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': isActive.italic()
          },
          on: {
            "click": commands.italic
          }
        }, [_c('span', {
          staticClass: "italic"
        }, [_vm._v("I")])]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': isActive.strike()
          },
          on: {
            "click": commands.strike
          }
        }, [_c('span', {
          staticClass: "color-red bold"
        }, [_vm._v("R")])]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': isActive.underline()
          },
          on: {
            "click": commands.underline
          }
        }, [_c('span', {
          staticClass: "underline"
        }, [_vm._v("U")])]), _c('button', {
          staticClass: "menubar__button",
          on: {
            "click": commands.page_break
          }
        }, [_vm._v("↵")]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': isActive.bullet_list()
          },
          on: {
            "click": commands.bullet_list
          }
        }, [_c('i', {
          staticClass: "icon-list-ul-solid"
        })]), _c('button', {
          staticClass: "menubar__button",
          class: {
            'is-active': isActive.ordered_list()
          },
          on: {
            "click": commands.ordered_list
          }
        }, [_c('i', {
          staticClass: "icon-list-ol-solid"
        })]), _c('button', {
          staticClass: "menubar__button",
          on: {
            "click": function click($event) {
              return _vm.openImageSelector(commands.createSharepointImage);
            }
          }
        }, [_c('i', {
          staticClass: "icon-image"
        })]), !_vm.noSnippets ? _c('button', {
          staticClass: "menubar__button",
          on: {
            "click": function click($event) {
              return _vm.openSnippetsSelector(commands.insertHTML);
            }
          }
        }, [_c('i', {
          staticClass: "icon-file"
        })]) : _vm._e()])];
      }
    }])
  }), _c('editor-content', {
    attrs: {
      "editor": _vm.editor
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };