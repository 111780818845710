var mapConfig = {};
if (process.env.VUE_APP_ENV === "dev") {
  mapConfig = {
    load: {
      key: "AIzaSyC3imzlIbYGLHCabM9TFY5usj20b4CPPKA",
      libraries: "places"
    }
  };
} else if (process.env.VUE_APP_ENV === "live") {
  mapConfig = {
    load: {
      key: "AIzaSyC3imzlIbYGLHCabM9TFY5usj20b4CPPKA",
      libraries: "places"
    }
  };
}
export default mapConfig;