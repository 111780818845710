import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "sidefilter"
    }
  }, [_c('div', {
    staticClass: "info-filed-filter shadow"
  }, [_c('div', {
    staticClass: "flex aic jcsa"
  }, [_c('div', {
    staticClass: "tac"
  }, [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Gefiltert: " + _vm._s(_vm.filtercounts))])]), _c('div', {
    staticClass: "tac"
  }, [_c('toggle-button', {
    attrs: {
      "width": 32,
      "height": 16,
      "color": "#67ab7b",
      "sync": true
    },
    model: {
      value: _vm.filterData.isLeftOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "isLeftOpen", $$v);
      },
      expression: "filterData.isLeftOpen"
    }
  })], 1)])]), _c('div', {
    staticClass: "sidefilter closed noselect condensed",
    class: {
      open: _vm.isShowingFilter || _vm.filterData.isLeftOpen
    },
    on: {
      "mouseover": function mouseover($event) {
        _vm.isShowingFilter = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.isShowingFilter = false;
      }
    }
  }, [_c('div', {
    staticClass: "spacer _vertical _med"
  }), _c('p'), _c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": _vm.saveMetaData
    }
  }, [_vm._v("Filter speichern")]), _c('p'), _c('div', {
    staticClass: "filter"
  }, [_c('div', {
    staticClass: "filter-head bold"
  }, [_vm._v("Besichtigungsdatum"), _c('v-checkbox', {
    attrs: {
      "label": 'filtern '
    },
    model: {
      value: _vm.filterData.isDateFilter,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "isDateFilter", $$v);
      },
      expression: "filterData.isDateFilter"
    }
  })], 1), _vm.filterData.isDateFilter ? _c('div', {
    staticClass: "dcenter",
    staticStyle: {
      "padding": "12px 0"
    }
  }, [_c('div', {
    staticClass: "inl"
  }, [_c('div', {
    staticClass: "inl w-min-2rem"
  }, [_vm._v("VON:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterData.dateFilterFrom,
      expression: "filterData.dateFilterFrom"
    }],
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filterData.dateFilterFrom
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filterData, "dateFilterFrom", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "inl"
  }, [_c('div', {
    staticClass: "inl w-min-2rem"
  }, [_vm._v("BIS:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterData.dateFilterTo,
      expression: "filterData.dateFilterTo"
    }],
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filterData.dateFilterTo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filterData, "dateFilterTo", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Angezeigt: " + _vm._s(_vm.$dayjs(_vm.filterData.dateFilterTo).diff(_vm.$dayjs(_vm.filterData.dateFilterFrom), 'day')) + " Tage")]), _vm.$dayjs(_vm.filterData.dateFilterTo) - _vm.$dayjs(_vm.filterData.dateFilterFrom) < 0 ? _c('div', {
    staticClass: "warn"
  }, [_vm._v("Achtung, bitte die Eingabe kontrollieren")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "filter"
  }, [_c('div', {
    staticClass: "filter-head bold"
  }, [_vm._v("SV Wohnorte"), _c('v-checkbox', {
    attrs: {
      "label": 'anzeigen'
    },
    model: {
      value: _vm.filterData.isShowingSvInMap,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "isShowingSvInMap", $$v);
      },
      expression: "filterData.isShowingSvInMap"
    }
  })], 1)]), _c('div', {
    staticClass: "filter"
  }, [_c('div', {
    staticClass: "filter-head bold flex aic jcsb"
  }, [_c('span', [_vm._v("Status")]), _c('div', [_c('a', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        return _vm.checkall('combinedStatusFilter');
      }
    }
  }, [_vm._v("Alle")]), _c('a', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        return _vm.checkall('combinedStatusFilter', true);
      }
    }
  }, [_vm._v("Keine")])])]), _c('ul', _vm._l(_vm.combinedStatus, function (statusData, idx) {
    return _c('li', {
      staticClass: "flex aic jcsb"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: _vm.extendStatusHtml(statusData.options),
          placement: 'bottom-start',
          offset: 1,
          delay: {
            show: 300,
            hide: 300
          }
        },
        expression: "{content: extendStatusHtml(statusData.options) ,placement: 'bottom-start', offset:1, delay: {show: 300,hide: 300}}"
      }]
    }, [_c('div', {
      staticClass: "wz-tag cirlce",
      class: statusData.color
    }), _c('v-checkbox', {
      attrs: {
        "item": idx,
        "label": statusData.name
      },
      model: {
        value: _vm.filterData.combinedStatusFilter,
        callback: function callback($$v) {
          _vm.$set(_vm.filterData, "combinedStatusFilter", $$v);
        },
        expression: "filterData.combinedStatusFilter"
      }
    })], 1), _c('div', [_c('small', {
      staticClass: "inl w-min-2rem"
    }, [_vm._v("(" + _vm._s(_vm.countFiltered.cStatus[idx] || 0) + ")")])])]);
  }), 0)]), _vm._isAdmin ? _c('div', {
    staticClass: "filter"
  }, [_c('div', {
    staticClass: "filter-head bold flex aic jcsb"
  }, [_c('span', [_vm._v("SV's")]), _c('div', [_c('a', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        return _vm.checkall('svInMapList');
      }
    }
  }, [_vm._v("Alle")]), _c('a', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        return _vm.checkall('svInMapList', true);
      }
    }
  }, [_vm._v("Keine")])])]), _c('ul', _vm._l(_vm.svlist, function (sv, index) {
    return _c('li', {
      staticClass: "flex aic jcsb"
    }, [_c('div', [_c('v-checkbox', {
      attrs: {
        "item": sv.crmContactId,
        "label": (sv.lastname || '') + ' ' + (sv.firstname || '')
      },
      model: {
        value: _vm.filterData.svInMapList,
        callback: function callback($$v) {
          _vm.$set(_vm.filterData, "svInMapList", $$v);
        },
        expression: "filterData.svInMapList"
      }
    }), !sv.loc.lat ? _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: 'Adresse feherhaft',
        expression: "'Adresse feherhaft'"
      }]
    }, [_c('a', {
      attrs: {
        "target": "_blank",
        "href": 'https://consense.crm4.dynamics.com/main.aspx?etc=2&id=' + sv.crmContactId + '&Pagetype=entityrecord'
      }
    }, [_vm._v("ohne Addresse!")])]) : _vm._e()], 1), _c('div', [_c('small', {
      staticClass: "inl w-min-2rem"
    }, [_vm._v("(" + _vm._s(_vm.countFiltered.sv[sv.crmContactId] || 0) + ")")])])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "filter"
  }, [_c('div', {
    staticClass: "filter-head bold flex aic jcsb"
  }, [_c('span', [_vm._v("Art")]), _c('div', [_c('a', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        return _vm.checkall('artFilter');
      }
    }
  }, [_vm._v("Alle")]), _c('a', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        return _vm.checkall('artFilter', true);
      }
    }
  }, [_vm._v("Keine")])])]), _c('ul', _vm._l(_vm.kindOptList, function (kindOpt, idx) {
    return _c('li', {
      staticClass: "flex aic jcsb"
    }, [_c('div', [_c('v-checkbox', {
      attrs: {
        "item": kindOpt,
        "label": _vm.arten[kindOpt + ''] || kindOpt
      },
      model: {
        value: _vm.filterData.artFilter,
        callback: function callback($$v) {
          _vm.$set(_vm.filterData, "artFilter", $$v);
        },
        expression: "filterData.artFilter"
      }
    })], 1), _c('div', [_c('small', {
      staticClass: "inl w-min-2rem"
    }, [_vm._v("(" + _vm._s(_vm.countFiltered.kind[kindOpt] || 0) + ")")])])]);
  }), 0)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };