import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('modal', {
    staticClass: "modal",
    attrs: {
      "name": _vm.ownName,
      "adaptive": true,
      "draggable": false,
      "scrollable": false,
      "reset": true,
      "width": "99%",
      "height": "99%",
      "maxHeight": 1280,
      "maxWidth": 1920
    },
    on: {
      "before-open": _vm.beforeOpenPreview
    }
  }, [_vm._l([_vm.files[_vm.currentIdx]], function (item) {
    return item ? _c('div', {
      staticClass: "iframeheader"
    }, [_c('h5', [_vm._v("(" + _vm._s(_vm.currentIdx + 1) + "/" + _vm._s(_vm.files.length) + ")  "), _c('a', {
      staticClass: "link",
      attrs: {
        "target": "_blank",
        "href": item.downloadUrl
      }
    }, [_vm._v("Download (" + _vm._s(_vm.$_toHumanFileSize(item.size)) + ")")]), _c('span', [_vm._v("- " + _vm._s(item.name) + "  ")])]), _c('span', {
      staticClass: "closer _hover",
      on: {
        "click": function click($event) {
          return _vm.$modal.hide(_vm.ownName);
        }
      }
    }, [_c('i', {
      staticClass: "icon-times inlin"
    })])]) : _vm._e();
  }), _c('div', {
    staticClass: "crmfilepreview"
  }, _vm._l([_vm.files[_vm.currentIdx]], function (item) {
    return item ? _c('div', [['jpg', 'png', 'jpeg'].includes(_vm.$_extension(item.name)) ? _c('div', {
      staticClass: "embeded-preview"
    }, [_c('Printer', {
      attrs: {
        "showimg": item.downloadUrl,
        "preview": item.downloadUrl
      }
    })], 1) : ['pdf'].includes(_vm.$_extension(item.name)) ? _c('div', {
      staticClass: "embeded-preview"
    }, [!_vm.doesPdfFileExist ? _c('div', {
      staticClass: "padding _large"
    }, [_c('div', {
      staticClass: "tac"
    }, [_c('div', {
      staticClass: "loading-spinner _large"
    })])]) : _c('embed', {
      attrs: {
        "src": _vm.makePdfLink(item),
        "width": "100%",
        "height": "100%",
        "type": "application/pdf"
      }
    })]) : ['doc', 'xls', 'docx', 'xlsx'].includes(_vm.$_extension(item.name)) ? _c('div', {
      staticClass: "embeded full-size"
    }, [_c('iframe', {
      attrs: {
        "srcdoc": _vm.previeDocFileResp,
        "width": "100%",
        "height": "100%",
        "frameborder": "0"
      }
    })]) : ['html', 'htm'].includes(_vm.$_extension(item.name)) ? _c('div', {
      staticClass: "embeded-preview bg-white"
    }, [_vm.theHtmlContent ? _c('div', {
      staticClass: "tal"
    }, [_c('div', {
      staticClass: "theemail",
      domProps: {
        "innerHTML": _vm._s(_vm.theHtmlContent)
      }
    })]) : _c('div', {
      staticClass: "padding _large"
    }, [_c('div', {
      staticClass: "tac"
    }, [_c('div', {
      staticClass: "loading-spinner _large"
    })])])]) : ['txt'].includes(_vm.$_extension(item.name)) ? _c('div', {
      staticClass: "embeded-preview bg-white"
    }, [_vm.theHtmlContent ? _c('div', {
      staticClass: "tal white-space-pre"
    }, [_c('div', {
      staticClass: "theemail",
      domProps: {
        "innerHTML": _vm._s(_vm.theHtmlContent)
      }
    })]) : _c('div', {
      staticClass: "padding _large"
    }, [_c('div', {
      staticClass: "tac"
    }, [_c('div', {
      staticClass: "loading-spinner _large"
    })])])]) : ['msg', 'eml'].includes(_vm.$_extension(item.name)) ? _c('div', {
      staticClass: "padding"
    }, [_vm.theHtmlContent ? _c('div', {
      staticClass: "padding"
    }, [_c('div', {
      staticClass: "theemail",
      domProps: {
        "innerHTML": _vm._s(_vm.theHtmlContent)
      }
    })]) : _c('div', {
      staticClass: "padding _large"
    }, [_c('div', {
      staticClass: "tac"
    }, [_c('div', {
      staticClass: "loading-spinner _large"
    })])])]) : _c('div', {
      staticClass: "padding _large tac"
    }, [_c('h4', [_vm._v("Dateiformat in der Vorschau nicht unterstützt.")]), _c('p', [_vm._v("Derzeit unterstützte Deteiformate: .pdf .jpg .png .txt .doc .docx .xls .xlsx")]), _c('a', {
      staticClass: "link",
      attrs: {
        "target": "_blank",
        "href": item.downloadUrl
      }
    }, [_vm._v("Datei runterladen")])])]) : _vm._e();
  }), 0), _c('div', {
    staticClass: "clickArrows __left",
    on: {
      "click": function click($event) {
        _vm.currentIdx--;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-left"
  })]), _c('div', {
    staticClass: "clickArrows __right",
    on: {
      "click": function click($event) {
        _vm.currentIdx++;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-right"
  })])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };