var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "sidefilter"
    }
  }, [_c('div', {
    staticClass: "sidefilter closed noselect condensed",
    class: {
      open: _vm.isShowingFilter || _vm.filterData.isLeftOpen
    },
    on: {
      "mouseover": function mouseover($event) {
        _vm.isShowingFilter = true;
      },
      "mouseleave": function mouseleave($event) {
        _vm.isShowingFilter = false;
      }
    }
  }, [_c('div', {
    staticClass: "spacer _vertical _large"
  }), _c('p', [_c('br'), _c('toggle-button', {
    attrs: {
      "width": 32,
      "height": 16,
      "color": "#67ab7b",
      "sync": true
    },
    model: {
      value: _vm.filterData.isLeftOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "isLeftOpen", $$v);
      },
      expression: "filterData.isLeftOpen"
    }
  }), _vm._v("  ausgeklappt lassen  "), _c('br'), _c('br')], 1), _c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": _vm.saveMetaData
    }
  }, [_vm._v("Filter speichern")]), _c('p'), _c('div', {
    staticClass: "filter"
  }, [_c('h4', [_vm._v("Filtern Besichtigungsdatum")]), _c('p', [_c('v-checkbox', {
    attrs: {
      "label": 'Besichtigungsdatum filtern '
    },
    model: {
      value: _vm.filterData.isDateFilter,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "isDateFilter", $$v);
      },
      expression: "filterData.isDateFilter"
    }
  })], 1), _vm.filterData.isDateFilter ? _c('div', {
    staticClass: "dcenter",
    staticStyle: {
      "padding": "12px 0"
    }
  }, [_c('span', [_vm._v("VON:"), _c('br'), _c('DatePick', {
    attrs: {
      "label": 'Datum VON'
    },
    model: {
      value: _vm.filterData.dateFilterFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "dateFilterFrom", $$v);
      },
      expression: "filterData.dateFilterFrom"
    }
  }), _c('br')], 1), _c('span', [_vm._v("BIS:"), _c('br'), _c('DatePick', {
    attrs: {
      "label": 'Datum BIS'
    },
    model: {
      value: _vm.filterData.dateFilterTo,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "dateFilterTo", $$v);
      },
      expression: "filterData.dateFilterTo"
    }
  })], 1), _c('div', {
    staticClass: "bold"
  }, [_vm._v("Angezeigt: " + _vm._s(_vm.$dayjs(_vm.filterData.dateFilterTo).diff(_vm.$dayjs(_vm.filterData.dateFilterFrom), "day")) + " Tage")]), _vm.$dayjs(_vm.filterData.dateFilterTo) - _vm.$dayjs(_vm.filterData.dateFilterFrom) < 0 ? _c('div', {
    staticClass: "warn"
  }, [_vm._v("Achtung, bitte die Eingabe kontrollieren")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "filter"
  }, [_c('h4', [_vm._v(" Filtern nach Status")]), _c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.checkall('statusFilter');
      }
    }
  }, [_vm._v("Alle (" + _vm._s(_vm.countAllStatus.all) + ")")]), _c('div', {
    staticClass: "btn _small _red",
    on: {
      "click": function click($event) {
        return _vm.checkall('statusFilter', true);
      }
    }
  }, [_vm._v("Keine")]), _vm._l(_vm.allStatusIcons, function (statusObj, statusId) {
    return _c('div', {
      staticClass: "filter-status-item"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.statusTranslator[statusId],
        expression: "statusTranslator[statusId]"
      }],
      class: {
        _dsel: !_vm.filterData.statusFilter[statusId]
      }
    }, [_c('img', {
      staticClass: "smallIcon",
      attrs: {
        "src": _vm.$root.img[statusObj.icon]
      }
    }), _c('v-checkbox', {
      attrs: {
        "item": statusId,
        "label": _vm.cutToLessStrings(_vm.statusTranslator[statusId])
      },
      model: {
        value: _vm.filterData.statusFilter,
        callback: function callback($$v) {
          _vm.$set(_vm.filterData, "statusFilter", $$v);
        },
        expression: "filterData.statusFilter"
      }
    })], 1)]);
  }), _c('div', {
    staticClass: "filter-status-item"
  }, [_c('img', {
    staticClass: "smallIcon",
    attrs: {
      "src": _vm.$root.img['icon19']
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": 'SV Wohnorte'
    },
    model: {
      value: _vm.filterData.isShowingSvInMap,
      callback: function callback($$v) {
        _vm.$set(_vm.filterData, "isShowingSvInMap", $$v);
      },
      expression: "filterData.isShowingSvInMap"
    }
  })], 1)], 2), _vm._isAdmin ? _c('div', {
    staticClass: "filter"
  }, [_c('h4', [_vm._v("SV-Akte in map anzeigen")]), _c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.checkall('svInMapList');
      }
    }
  }, [_vm._v("Alle")]), _c('div', {
    staticClass: "btn _small _red",
    on: {
      "click": function click($event) {
        return _vm.checkall('svInMapList', true);
      }
    }
  }, [_vm._v("Keine")]), _vm._l(_vm.svlist, function (sv, index) {
    return _c('div', [sv.loc.lat ? _c('v-checkbox', {
      attrs: {
        "item": sv.crmContactId,
        "label": (sv.lastname || "") + " " + (sv.firstname || "")
      },
      model: {
        value: _vm.filterData.svInMapList,
        callback: function callback($$v) {
          _vm.$set(_vm.filterData, "svInMapList", $$v);
        },
        expression: "filterData.svInMapList"
      }
    }) : !sv.loc.lat ? _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: 'Adresse feherhaft',
        expression: "'Adresse feherhaft'"
      }],
      staticClass: "noaddress"
    }, [_c('a', {
      attrs: {
        "target": "_blank",
        "href": 'https://consense.crm4.dynamics.com/main.aspx?etc=2&id=' + sv.crmContactId + '&Pagetype=entityrecord'
      }
    }, [_vm._v(_vm._s(sv.lastname) + " " + _vm._s(sv.firstname))])]) : _vm._e()], 1);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "filter"
  }, [_c('h4', [_vm._v(" Filtern nach Art")]), _c('div', {
    staticClass: "btn _small _green",
    on: {
      "click": function click($event) {
        return _vm.checkall('artFilter');
      }
    }
  }, [_vm._v("Alle (" + _vm._s(_vm.countAllArt.all) + ")")]), _c('div', {
    staticClass: "btn _small _red",
    on: {
      "click": function click($event) {
        return _vm.checkall('artFilter', true);
      }
    }
  }, [_vm._v("Keine")]), _c('div', {
    staticClass: "filterul"
  }, _vm._l(_vm.countAllArt, function (count, art) {
    return _c('div', [art != 'all' ? _c('v-checkbox', {
      attrs: {
        "item": art,
        "label": art + ' (' + count + ')'
      },
      model: {
        value: _vm.filterData.artFilter,
        callback: function callback($$v) {
          _vm.$set(_vm.filterData, "artFilter", $$v);
        },
        expression: "filterData.artFilter"
      }
    }) : _vm._e()], 1);
  }), 0)]), _c('div', {
    staticClass: "info-filed-filter shadow"
  }, [_c('div', {
    staticClass: "padding tac"
  }, [_c('div', {
    staticClass: "bold"
  }, [_vm._v("Gefiltert: " + _vm._s(_vm.filtercounts))]), _vm.filtercounts == 0 ? _c('div', {
    staticClass: "warn"
  }, [_vm._v("Bitte Filter anpasssen")]) : _vm._e()])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };