var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('th', {
    staticClass: "v-tablehead",
    on: {
      "click": _vm.setSorter
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.label) + " "), _vm.sortby == _vm.sortvalue && _vm.reverse ? _c('i', {
    staticClass: "icon-arrow-down"
  }) : _vm.sortby == _vm.sortvalue && !_vm.reverse ? _c('i', {
    staticClass: "icon-arrow-top"
  }) : _c('i', {
    staticClass: "icon-sortarrows"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };