var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "block-editor image-block"
  }, [_c('div', [!_vm.content.$dontShowImageConfig ? _c('div', {
    staticClass: "g-flex jcsb"
  }, [_c('div', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Bilder pro A4 Seite:")]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": '1'
    },
    model: {
      value: _vm.content.images.value.layout,
      callback: function callback($$v) {
        _vm.$set(_vm.content.images.value, "layout", $$v);
      },
      expression: "content.images.value.layout"
    }
  }), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": '2'
    },
    model: {
      value: _vm.content.images.value.layout,
      callback: function callback($$v) {
        _vm.$set(_vm.content.images.value, "layout", $$v);
      },
      expression: "content.images.value.layout"
    }
  }), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": '4'
    },
    model: {
      value: _vm.content.images.value.layout,
      callback: function callback($$v) {
        _vm.$set(_vm.content.images.value, "layout", $$v);
      },
      expression: "content.images.value.layout"
    }
  }), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": '6'
    },
    model: {
      value: _vm.content.images.value.layout,
      callback: function callback($$v) {
        _vm.$set(_vm.content.images.value, "layout", $$v);
      },
      expression: "content.images.value.layout"
    }
  })], 1), _c('div', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Bilderauflösung:")]), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": 'high',
      "label": 'hoch'
    },
    model: {
      value: _vm.content.images.value.quality,
      callback: function callback($$v) {
        _vm.$set(_vm.content.images.value, "quality", $$v);
      },
      expression: "content.images.value.quality"
    }
  }), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": 'mid',
      "label": 'mittel'
    },
    model: {
      value: _vm.content.images.value.quality,
      callback: function callback($$v) {
        _vm.$set(_vm.content.images.value, "quality", $$v);
      },
      expression: "content.images.value.quality"
    }
  }), _c('v-checkbox', {
    attrs: {
      "rctype": "radio",
      "item": 'low',
      "label": 'niedrig'
    },
    model: {
      value: _vm.content.images.value.quality,
      callback: function callback($$v) {
        _vm.$set(_vm.content.images.value, "quality", $$v);
      },
      expression: "content.images.value.quality"
    }
  })], 1)]) : _vm._e(), _c('ImagesSelector', {
    attrs: {
      "content": _vm.content,
      "blockedImages": _vm.blockedImages
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };