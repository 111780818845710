var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pagination"
  }, [_c('div', {
    staticClass: "wz-pagination noselect"
  }, [_c('div', {
    staticClass: "prevpage _max",
    class: {
      _disable: _vm.onpageLOC <= 1
    },
    on: {
      "click": function click($event) {
        _vm.onpageLOC = 1;
      }
    }
  }, [_c('i', {
    staticClass: "icon-doublearrow-left"
  })]), _c('div', {
    staticClass: "prevpage _norm",
    class: {
      _disable: _vm.onpageLOC <= 1
    },
    on: {
      "click": function click($event) {
        _vm.onpageLOC--;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-left"
  })]), _vm.fPages.length == 0 ? _c('div', {
    staticClass: "pagelist"
  }, [_vm._m(0)]) : _vm._e(), _vm.fPages.length > 0 ? _c('div', {
    staticClass: "pagelist"
  }, _vm._l(_vm.PaginationList(_vm.onpage, _vm.fPages.length), function (p) {
    return _c('span', [p > 0 && p <= _vm.fPages.length ? _c('div', {
      staticClass: "_page",
      class: {
        _sel: p === _vm.onpageLOC
      },
      on: {
        "click": function click($event) {
          _vm.onpageLOC = p;
        }
      }
    }, [_vm._v(_vm._s(p))]) : _vm._e()]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "nextpage _norm",
    class: {
      _disable: _vm.onpageLOC > _vm.fPages.length - 1
    },
    on: {
      "click": function click($event) {
        _vm.onpageLOC++;
      }
    }
  }, [_c('i', {
    staticClass: "icon-arrow-right"
  })]), _c('div', {
    staticClass: "nextpage _max",
    class: {
      _disable: _vm.onpageLOC > _vm.fPages.length - 1
    },
    on: {
      "click": function click($event) {
        _vm.onpageLOC = _vm.fPages.length;
      }
    }
  }, [_c('i', {
    staticClass: "icon-doublearrow-right"
  })])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('div', {
    staticClass: "_page _sel"
  }, [_vm._v("1")])]);
}];
export { render, staticRenderFns };