var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('article', {
    staticClass: "dynamic-modal"
  }, [_c('header', {
    staticClass: "header"
  }, [_c('h1', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('section', [_vm._t("default")], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };