import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
var vu = null;
var MAXZOOM = 50;
var MINZOOM = 0.25;
var MAXBORDER = 900;
var MINBORDER = -900;
var mainBridgeSvg;
// const mixinSvg = {
var mixinSvg = {
  // install(Vue, options) {
  //   Vue.mixin({
  beforeCreate: function beforeCreate() {
    vu = this;
  },
  data: function data() {
    return {
      // innerwidth: 720,
      // innerheight: 720,
      svg_style_bogie: {
        transform: "translateX(40px) translateY(34px)"
      },
      mousePos: {
        x: 240,
        y: 240
      },
      mainBoard: {
        x: 240,
        y: 240
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // console.log('vu.inited', vu.inited)
    vu.init();
    // if (typeof window.orientation === "undefined") {
    document.getElementById("mainBridgeSvg").addEventListener("mousedown", function (e) {
      if (e.buttons === 4 || e.buttons === 1 || e.buttons === 1 && vu.spacekeydown) {
        vu.middleclick = true;
        vu.DC += 1;
        vu.DC_timer = setTimeout(function () {
          vu.DC = 0;
        }, 600);
        if (vu.DC === 3) {
          vu.svg_zoom = 1;
          vu.svg_moveX = 0;
          vu.svg_moveY = 0;
        }
      }
    });
    // PREVENT ZOOM FROM MACBOOKS
    document.getElementById("app").addEventListener("mousewheel", function (e) {
      if (e.ctrlKey) {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
    document.addEventListener("keydown", function (e) {
      if (e.code === "Space") {
        vu.spacekeydown = true;
        vu.middleclick = true;
      }
    });
    document.addEventListener("keyup", function (e) {
      if (e.code === "Space") {
        vu.spacekeydown = false;
        vu.middleclick = false;
      }
    });
    window.addEventListener("resize", function (e) {
      vu.init();
    });
    document.addEventListener("mouseup", function (e) {
      vu.middleclick = false;
    });
    if (!vu.inited) {
      vu.inited = true;
      document.addEventListener("mousemove", function (e) {
        if (vu.middleclick) {
          vu.svg_moveX = vu.svg_moveX + e.movementX / vu.svg_zoom * 1;
          vu.svg_moveY = vu.svg_moveY + e.movementY / vu.svg_zoom * 1;
          vu.BorderCheck();
        }
      });
    }
    mainBridgeSvg = document.getElementById("mainBridgeSvg");
    var clientX, clientY, zoomStart;
    mainBridgeSvg.addEventListener('touchstart', function (e) {
      if (e.touches.length == 1) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else if (e.touches.length == 2) {
        clientX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
        clientY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
        var pytagoras = Math.pow(Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2) + Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2), 1 / 2);
        zoomStart = pytagoras;
      }
    }, false);
    mainBridgeSvg.addEventListener('touchend', function (e) {
      if (e.touches.length == 1) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else if (e.touches.length == 2) {
        clientX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
        clientY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
      }
    }, false);
    mainBridgeSvg.addEventListener("touchmove", function (e) {
      if (e.touches.length == 1) {
        e.deltaX = -e.changedTouches[0].clientX + clientX;
        e.deltaY = -e.changedTouches[0].clientY + clientY;
        vu.moveFkt(e, 1);
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else if (e.touches.length == 2) {
        var pytagoras = Math.pow(Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2) + Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2), 1 / 2);
        e.deltaY = zoomStart - pytagoras;
        vu.zoomFkt(e, 200);
        zoomStart = pytagoras;
        var deltaX1 = -((e.touches[0].clientX + e.touches[1].clientX) / 2) + clientX;
        var deltaY1 = -((e.touches[0].clientY + e.touches[1].clientY) / 2) + clientY;
        var a = {
          deltaX: deltaX1,
          deltaY: deltaY1
        };
        vu.moveFkt(a, 1);
        clientX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
        clientY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
      }
    });
    mainBridgeSvg.addEventListener("mousewheel", function (e) {
      if (e.ctrlKey) {
        // CTRL // MACBOOK
        vu.zoomFkt(e, 50); // MACBOOK TRACKPAD ZOOM:
      } else if (e.altKey) {
        vu.zoomFkt(e, 500);
        // vu.moveFkt(e, 1)
      } else if (e.shiftKey) {
        vu.moveFkt(e, 1);
      } else if (e.metaKey) {
        vu.zoomFkt(e, 500);
      } else {
        // console.log(e.altKey);
        vu.moveFkt(e, 1);
        //   NORMAL MOVE!
        // vu.zoomFkt(e, 500)
        // vu.moveFkt(e, 1)
      }
    });
    // }
  },
  computed: _objectSpread(_objectSpread({}, mapFields(['inited'])), {}, {
    // svg_style() { return { transform: "scale(" + vu.svg_zoom + ") translateX(" + (vu.innerwidth / 2 + vu.svg_moveX) + "px) translateY(" + (vu.innerheight / 2 + vu.svg_moveY) + "px)", "transform-origin": "50% 50%" }; },
    svg_style2: function svg_style2() {
      return {
        transform: "scale(" + vu.svg_zoom + ") translateX(" + (vu.innerwidth / 2 + vu.svg_moveX) + "px) translateY(" + (vu.innerheight / 2 + vu.svg_moveY) + "px)",
        "transform-origin": "50% 50%"
      };
    }
  }),
  watch: {},
  methods: _objectSpread(_objectSpread({}, mapActions(["SET"])), {}, {
    init: function init() {
      // console.log('init')
      vu.innerheight = document.getElementById("PrinterSVG") ? document.getElementById("PrinterSVG").getBoundingClientRect().height : 0;
      vu.innerwidth = document.getElementById("PrinterSVG") ? document.getElementById("PrinterSVG").getBoundingClientRect().width : 0;
      vu.offY = window.innerHeight / 2;
      vu.offX = window.innerWidth / 2;
      setTimeout(function () {
        mainBridgeSvg = document.getElementById("mainBridgeSvg");
        vu.mainBoard = mainBridgeSvg.getBoundingClientRect();
      }, 250);
    },
    zoomFkt: function zoomFkt(e, zfactor) {
      // console.log('zoomFkt');
      // console.log(e.deltaY)
      var oldzoom = vu.svg_zoom;
      vu.svg_zoom += -(e.deltaY / zfactor * vu.svg_zoom);
      if (vu.svg_zoom <= MINZOOM) {
        vu.svg_zoom = MINZOOM;
      } else if (vu.svg_zoom >= MAXZOOM) {
        vu.svg_zoom = MAXZOOM;
      }
      var xxx = vu.mousePos.x - vu.mainBoard.x - vu.mainBoard.width / 2;
      var yyy = vu.mousePos.y - vu.mainBoard.y - vu.mainBoard.height / 2;
      vu.svg_moveX += (xxx - xxx / oldzoom * vu.svg_zoom) / vu.svg_zoom;
      vu.svg_moveY += (yyy - yyy / oldzoom * vu.svg_zoom) / vu.svg_zoom;
      vu.BorderCheck();
    },
    moveFkt: function moveFkt(e, mfactor) {
      // console.log('moveFkt');
      vu.svg_moveX = vu.svg_moveX + -e.deltaX / vu.svg_zoom * mfactor;
      vu.svg_moveY = vu.svg_moveY + -e.deltaY / vu.svg_zoom * mfactor;
      vu.BorderCheck();
    },
    BorderCheck: function BorderCheck() {
      if (vu.svg_moveX > MAXBORDER) vu.svg_moveX = MAXBORDER;else if (vu.svg_moveX < MINBORDER) vu.svg_moveX = MINBORDER;
      if (vu.svg_moveY > MAXBORDER) vu.svg_moveY = MAXBORDER;else if (vu.svg_moveY < MINBORDER) vu.svg_moveY = MINBORDER;
    },
    MD: function MD(e, id) {
      if (e.buttons === 1) {
        vu.click = true;
        vu.selectedId = id;
        var c = vu.points[vu.selectedId];
        vu.MoveStarter = {
          x: c.x,
          y: c.y
        };
      }
    },
    MM: function MM(e) {
      // console.log(e.clientX,e.clientY);
      if (vu.click) {
        // console.log(vu.MoveStarter,e.shiftKey);
        var c = vu.points[vu.selectedId];
        var cut = e.altKey ? 1 : 10;
        // console.log(vu.svg_moveX,vu.svg_moveY,cut);
        var cx = vu.n((e.x - vu.offX) / vu.svg_zoom - vu.svg_moveX, cut); // + window.pageXOffset
        var cy = vu.n((e.y - vu.offY) / vu.svg_zoom - vu.svg_moveY, cut); // + window.pageYOffset
        if (e.shiftKey) {
          c.x = vu.MoveStarter.x;
          c.y = vu.MoveStarter.y;
          if (Math.abs((vu.MoveStarter.y - cy) / (vu.MoveStarter.x - cx)) > 1) {
            c.y = cy;
          }
          if (Math.abs((vu.MoveStarter.y - cy) / (vu.MoveStarter.x - cx)) < 1) {
            c.x = cx;
          }
        } else {
          c.x = cx;
          c.y = cy;
        }
      }
      vu.mousePos = {
        x: e.clientX,
        y: e.clientY
      };
    },
    TM: function TM(e) {
      // console.log(e);
      if (e.touches.length == 1) {
        vu.mousePos = {
          x: e.touches[0].clientX,
          y: e.touches[0].clientY
        };
      } else if (e.touches.length == 2) {
        vu.mousePos = {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2
        };
      }
    },
    ME: function ME(e) {
      if (vu.selectedId) {
        var c = vu.points[vu.selectedId];
        c.color = "#eeeeee";
        c.r = 3;
      }
      vu.click = false;
      vu.selectedId = "";
    },
    n: function n(val, cut) {
      var dif = cut;
      return Math.round(val / dif) * dif;
    }
  })
  //   })
  // }
};
export default mixinSvg;