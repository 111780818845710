var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', {
    staticClass: "crmDate"
  }, [_c('DatePick', {
    attrs: {
      "format": _vm.format,
      "displayFormat": _vm.dispFormat,
      "pickTime": true,
      "hasInputElement": true
    },
    model: {
      value: _vm.localDate,
      callback: function callback($$v) {
        _vm.localDate = $$v;
      },
      expression: "localDate"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };