import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tabs"
  }, _vm._l(_vm.tabs.data, function (opt) {
    return _c('div', {
      staticClass: "tabs__tab",
      class: {
        '-sel': _vm.tabs.selected == opt.key
      },
      on: {
        "click": function click($event) {
          _vm.tabs.selected = opt.key;
        }
      }
    }, [_vm._v(_vm._s(opt.name))]);
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };