import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('dynamic-modal-container', {
    attrs: {
      "title": "Textbausteine"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "selection"
  }, [_c('div', {
    staticClass: "selection--wrapper"
  }, [_c('v-select', {
    attrs: {
      "options": _vm.sortedSnippets,
      "label": "name"
    },
    model: {
      value: _vm.selectedSnippet,
      callback: function callback($$v) {
        _vm.selectedSnippet = $$v;
      },
      expression: "selectedSnippet"
    }
  })], 1), _c('button', {
    staticClass: "btn _clear nomargin",
    on: {
      "click": _vm.newSnippet
    }
  }, [_c('i', {
    staticClass: "icon-add2"
  })])]), _vm.selectedSnippet ? _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "editor"
  }, [_c('div', {
    staticClass: "editor-header"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedSnippet.name,
      expression: "selectedSnippet.name"
    }],
    staticClass: "input",
    domProps: {
      "value": _vm.selectedSnippet.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.selectedSnippet, "name", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "btn _clear nomargin",
    on: {
      "click": _vm.removeSnippet
    }
  }, [_c('i', {
    staticClass: "icon-trashbin-thin"
  })])]), _c('tip-tap', {
    attrs: {
      "content": _vm.selectedSnippet,
      "ckey": "template",
      "no-snippets": ""
    }
  })], 1), _c('div', {
    staticClass: "controls"
  }, [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.updateSelectedSnippet
    }
  }, [_vm._v("Speichern")]), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.insertSelectedSnippet
    }
  }, [_vm._v("Einfügen")])])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };