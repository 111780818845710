import Vue from 'vue';
import money from 'v-money';
import vSelect from "vue-select";
import CCard from "@/components/CCard.vue";
Vue.component('VueSelect', vSelect);
Vue.component('v-select', vSelect);
Vue.component('c-card', CCard);
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: '€ ',
  suffix: '',
  precision: 2,
  masked: false /* doesn't work with directive */
});