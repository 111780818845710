import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "grid-crmlist-container"
  }, [_c('div', {
    staticClass: "grid-crmlist-header __header"
  }, [_c('div', {
    staticClass: "flex __topline"
  }, [_c('div', [_c('small', [_c('span', {
    staticClass: "bold"
  }, [_vm._v("Nicht angezeigt:")]), _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.extendStatusHtml(_vm.combinedStatus['s14'].options),
        placement: 'bottom-start',
        offset: 1,
        delay: {
          show: 300,
          hide: 300
        }
      },
      expression: "{ content: extendStatusHtml(combinedStatus['s14'].options), placement: 'bottom-start', offset: 1, delay: { show: 300, hide: 300 } }"
    }]
  }, [_c('v-checkbox', {
    attrs: {
      "item": "s14",
      "label": "fertige Akte"
    },
    model: {
      value: _vm.combinedStatusFilterList,
      callback: function callback($$v) {
        _vm.combinedStatusFilterList = $$v;
      },
      expression: "combinedStatusFilterList"
    }
  })], 1), _c('span', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.extendStatusHtml(_vm.combinedStatus['s06'].options),
        placement: 'bottom-start',
        offset: 1,
        delay: {
          show: 300,
          hide: 300
        }
      },
      expression: "{ content: extendStatusHtml(combinedStatus['s06'].options), placement: 'bottom-start', offset: 1, delay: { show: 300, hide: 300 } }"
    }]
  }, [_c('v-checkbox', {
    attrs: {
      "item": "s06",
      "label": "Akte in Fertigstellung"
    },
    model: {
      value: _vm.combinedStatusFilterList,
      callback: function callback($$v) {
        _vm.combinedStatusFilterList = $$v;
      },
      expression: "combinedStatusFilterList"
    }
  })], 1)])]), _c('div', [_c('v-pagination', {
    attrs: {
      "fPages": _vm.fPages
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = _vm._n($$v);
      },
      expression: "currentPage"
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "wz-tag"
  }, [_c('span', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        _vm.itemsPerPage = 30;
      }
    }
  }, [_vm._v("30/S")]), _c('span', {
    staticClass: "link padding s",
    on: {
      "click": function click($event) {
        _vm.itemsPerPage = 100;
      }
    }
  }, [_vm._v("100/S")]), _c('a', {
    staticClass: "link padding s",
    on: {
      "click": _vm.downloadXls
    }
  }, [_vm._v("XLSX")]), _c('a', {
    staticClass: "link padding s",
    on: {
      "click": _vm._fetchOlderCommissions
    }
  }, [_vm._v("Alle Akte"), _vm.crmDataLoading ? _c('div', {
    staticClass: "loading-spinner inline"
  }) : _vm._e()])])])])]), _c('div', {
    staticClass: "grid-crmlist-main condensed"
  }, [_c('table', {
    staticClass: "crm-table nobreak"
  }, [_c('thead', [_c('tr', {
    staticClass: "first-line"
  }, [_c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('customName');
      }
    }
  }, [_vm._v("Bezeichnung")])]), _c('th', {
    staticClass: "sticky-column"
  }, [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('consenseNr');
      }
    }
  }, [_vm._v("Akt")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('statusOpt');
      }
    }
  }, [_vm._v("Status")])]), _vm._m(0), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('visitDate');
      }
    }
  }, [_vm._v(" Besichtigungsdat.")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('insureeName');
      }
    }
  }, [_vm._v("VN Name")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('assignedAppraiserContactId');
      }
    }
  }, [_vm._v("Bearbeiter")]), _c('v-checkbox', {
    attrs: {
      "label": ''
    },
    model: {
      value: _vm.loadOnlyMy,
      callback: function callback($$v) {
        _vm.loadOnlyMy = $$v;
      },
      expression: "loadOnlyMy"
    }
  })], 1), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('insurantName');
      }
    }
  }, [_vm._v("Auftraggeber")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('refereeName');
      }
    }
  }, [_vm._v("Referent")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('damageNumber');
      }
    }
  }, [_vm._v("Schadennummer")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('orderNumber');
      }
    }
  }, [_vm._v("Auftragsnummer")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('insuranceLineOpt');
      }
    }
  }, [_vm._v("Sparte")]), _c('span', [_vm._v("/")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('insuranceLineDetailsOpt');
      }
    }
  }, [_vm._v("Details")]), _c('span', [_vm._v("/")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('damageKindOpt');
      }
    }
  }, [_vm._v("Art")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('orderDate');
      }
    }
  }, [_vm._v("Auftragsdat.")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('damageDate');
      }
    }
  }, [_vm._v("Schadendat.")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('urgent');
      }
    }
  }, [_vm._v("dr.Ktk.")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('unrgency');
      }
    }
  }, [_vm._v("Prio.")])]), _c('th', [_c('span', {
    staticClass: "link",
    on: {
      "click": function click($event) {
        return _vm.sorter('modifiedon');
      }
    }
  }, [_vm._v("geändert")])])]), _c('tr', {
    staticClass: "second-line"
  }, [_c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.customName,
      expression: "customFilter.customName"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "110px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.customName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "customName", $event.target.value);
      }
    }
  })]), _c('th', {
    staticClass: "sticky-column"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.commissionNr,
      expression: "customFilter.commissionNr"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "120px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.commissionNr
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "commissionNr", $event.target.value);
      }
    }
  })]), _c('th', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.statusOpt,
      expression: "customFilter.statusOpt"
    }],
    staticStyle: {
      "max-width": "110px"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.customFilter, "statusOpt", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": ''
    }
  }, [_vm._v("Alle")]), _vm._l(_vm.status, function (val, key) {
    return _c('option', {
      domProps: {
        "value": key
      }
    }, [_vm._v(_vm._s(val))]);
  })], 2)]), _c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.visitAddress,
      expression: "customFilter.visitAddress"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "110px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.visitAddress
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "visitAddress", $event.target.value);
      }
    }
  })]), _c('th'), _c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.insureeName,
      expression: "customFilter.insureeName"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "110px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.insureeName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "insureeName", $event.target.value);
      }
    }
  })]), _c('th', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.assignedAppraiserContactId,
      expression: "customFilter.assignedAppraiserContactId"
    }],
    staticStyle: {
      "max-width": "110px"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.customFilter, "assignedAppraiserContactId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": ''
    }
  }, [_vm._v("Alle")]), _vm._l(_vm._svNamesByCrmContactId, function (val, key) {
    return _c('option', {
      domProps: {
        "value": val.crmContactId
      }
    }, [_vm._v(_vm._s(val.lastname) + " " + _vm._s(val.firstname))]);
  })], 2)]), _c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.insurantName,
      expression: "customFilter.insurantName"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "110px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.insurantName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "insurantName", $event.target.value);
      }
    }
  })]), _c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.refereeName,
      expression: "customFilter.refereeName"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "110px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.refereeName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "refereeName", $event.target.value);
      }
    }
  })]), _c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.damageNumber,
      expression: "customFilter.damageNumber"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "110px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.damageNumber
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "damageNumber", $event.target.value);
      }
    }
  })]), _c('th', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customFilter.orderNumber,
      expression: "customFilter.orderNumber"
    }],
    staticClass: "search",
    staticStyle: {
      "max-width": "110px"
    },
    attrs: {
      "type": "search"
    },
    domProps: {
      "value": _vm.customFilter.orderNumber
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.customFilter, "orderNumber", $event.target.value);
      }
    }
  })]), _c('th'), _c('th'), _c('th'), _c('th', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customBooleanFilter.importantToEstablishContact,
      expression: "customBooleanFilter.importantToEstablishContact"
    }],
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.customBooleanFilter, "importantToEstablishContact", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": ''
    }
  }, [_vm._v("Alle")]), _c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("NEIN")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("JA")])])]), _c('th', [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.customBooleanFilter.urgency,
      expression: "customBooleanFilter.urgency"
    }],
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.customBooleanFilter, "urgency", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": ''
    }
  }, [_vm._v("Alle")]), _c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("NEIN")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("JA")])])]), _c('th')])]), _c('tbody', _vm._l(_vm.fPages[_vm.currentPage - 1], function (p) {
    return _c('tr', {
      staticClass: "list-line",
      class: _vm.trClass(p)
    }, [_c('td', {
      class: {
        important: p.importantToEstablishContact || p.urgent
      },
      style: {
        backgroundColor: p.marker
      }
    }, [_c('v-popover', {
      staticClass: "inl padding s",
      attrs: {
        "offset": "3",
        "placement": "bottom"
      }
    }, [_c('i', {
      staticClass: "icon-color-palette link padding s"
    }), _c('template', {
      slot: "popover"
    }, [_c('marker-selector', {
      staticClass: "color-picker-popout",
      attrs: {
        "commission-nr": p.commissionNr
      }
    })], 1), _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: p.customName,
          placement: 'bottom-end',
          offset: 1,
          delay: {
            show: 300,
            hide: 300
          }
        },
        expression: "{ content: p.customName, placement: 'bottom-end', offset: 1, delay: { show: 300, hide: 300 } }"
      }]
    }, [_vm._v(_vm._s(_vm.cutToLessStrings(p.customName)))])], 2)], 1), _c('td', {
      staticClass: "sticky-column"
    }, [_c('div', {
      staticClass: "box",
      class: {
        important: p.importantToEstablishContact || p.urgent
      },
      style: {
        backgroundColor: p.marker
      }
    }, [_vm.notificationCountByCommissionId[p.commissionNr] ? _c('span', {
      staticClass: "numberCount"
    }, [_vm._v(_vm._s(_vm.notificationCountByCommissionId[p.commissionNr]))]) : _vm._e(), !p.touched ? _c('i', {
      staticClass: "icon-email padding s"
    }) : _vm._e(), _c('router-link', {
      staticClass: "akt-link bold",
      attrs: {
        "to": '/crmakt/' + p.commissionNr
      }
    }, [_vm._v(_vm._s(p.commissionNr || 'keine Aktnummer'))])], 1)]), _c('td', [_c('span', {
      staticClass: "padding s"
    }, [p.urgency ? _c('i', {
      staticClass: "icon-exclamation color-red"
    }) : _vm._e(), p.importantToEstablishContact ? _c('i', {
      staticClass: "icon-phone-outgoing-exclamation color-red"
    }) : _vm._e(), !p.importantToEstablishContact && !p.urgency ? _c('div', {
      staticClass: "wz-tag cirlce",
      class: _vm.trCircleClass(p)
    }) : _vm._e()]), _c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: (_vm.status || {})[p.statusOpt] || p.statusOpt,
          placement: 'bottom-end',
          offset: 1,
          delay: {
            show: 300,
            hide: 300
          }
        },
        expression: "{ content: ((status || {})[p.statusOpt] || p.statusOpt), placement: 'bottom-end', offset: 1, delay: { show: 300, hide: 300 } }"
      }],
      staticClass: "bold",
      class: {
        'color-red': p.urgency || p.importantToEstablishContact
      }
    }, [_vm._v(_vm._s(((_vm.status || {})[p.statusOpt] || '').substring(0, 16)) + "...")])]), _c('td', [(p.loc || {}).hasCoords !== 100000002 ? _c('i', {
      staticClass: "icon-mapmarker"
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(p.visitStreet))]), _c('br'), _c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(p.visitPostal) + " " + _vm._s(p.visitCity))])]), _c('td', [p.visitDate ? _c('small', [_vm._v(_vm._s(_vm.$dayjs(p.visitDate, 'YYYY-MM-DDTHH:mm:ssZ').format(_vm.MAIN_DATE_FORMAT)))]) : _vm._e()]), _c('td', {
      staticClass: "white-space-pre-line"
    }, [_vm._v(_vm._s(p.insureeName))]), _c('td', [_vm._v(_vm._s(p.assignedAppraiserName)), _c('br'), _vm._l(p.assignedUsers, function (u) {
      return _c('small', [_vm._v(_vm._s(((_vm._svNamesByCrmContactId[u] || {}).lastname || '-') + ', '))]);
    }), _c('br'), _vm._l(p.sharedUsers, function (u) {
      return _c('small', [_vm._v(_vm._s(((_vm._svNamesByCrmContactId[u] || {}).lastname || '-') + ' '))]);
    })], 2), _c('td', [_c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: p.insurantName,
          placement: 'bottom-end',
          offset: 1,
          delay: {
            show: 300,
            hide: 300
          }
        },
        expression: "{ content: p.insurantName, placement: 'bottom-end', offset: 1, delay: { show: 300, hide: 300 } }"
      }]
    }, [_vm._v(_vm._s(_vm.cutToLessStrings(p.insurantName)))])]), _c('td', [_c('span', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: {
          content: p.refereeName,
          placement: 'bottom-end',
          offset: 1,
          delay: {
            show: 300,
            hide: 300
          }
        },
        expression: "{ content: p.refereeName, placement: 'bottom-end', offset: 1, delay: { show: 300, hide: 300 } }"
      }]
    }, [_vm._v(_vm._s(_vm.cutToLessStrings(p.refereeName)))]), _c('br'), _c('small', [_vm._v(_vm._s(p.refereeEmail))])]), _c('td', {
      staticClass: "white-space-pre-line"
    }, [_vm._v(_vm._s(p.damageNumber))]), _c('td', {
      staticClass: "white-space-pre-line"
    }, [_vm._v(_vm._s(p.orderNumber))]), _c('td', [_c('small', [_vm._v(_vm._s(_vm.sparten.sparten[p.insuranceLineOpt])), _c('br'), _vm._v(_vm._s(_vm.sparten.details[p.insuranceLineDetailsOpt])), _c('br'), _vm._v(_vm._s(_vm.sparten.art[p.damageKindOpt]))])]), _c('td', [p.orderDate ? _c('small', [_vm._v(_vm._s(_vm.$dayjs(p.orderDate, 'YYYY-MM-DDTHH:mm:ssZ').format(_vm.MAIN_DATE_FORMAT)))]) : _vm._e()]), _c('td', [p.damageDate ? _c('small', [_vm._v(_vm._s(_vm.$dayjs(p.damageDate, 'YYYY-MM-DDTHH:mm:ssZ').format(_vm.MAIN_DATE_FORMAT)))]) : _vm._e()]), _c('td', [p.urgent === true ? _c('div', {
      staticClass: "wz-tag cirlce _red"
    }) : _vm._e(), _vm._v(_vm._s(p.urgent === true ? 'JA' : p.urgent === false ? 'NEIN' : '-'))]), _c('td', [p.urgency === true ? _c('div', {
      staticClass: "wz-tag cirlce _red"
    }) : _vm._e(), _vm._v(_vm._s(p.urgency === true ? 'JA' : p.urgency === false ? 'NEIN' : '-'))]), _c('td', [_c('small', [_vm._v(" " + _vm._s(_vm.$dayjs(p.modifiedon, 'YYYY-MM-DDTHH:mm:ssZ').format(_vm.MAIN_DATE_FORMAT))), _c('br'), _c('a', {
      attrs: {
        "target": "_blank",
        "href": 'https://consense.crm4.dynamics.com/main.aspx?etc=10009&id=' + p._id + '&Pagetype=entityrecord'
      }
    }, [_vm._v("CRM Link")])])])]);
  }), 0)]), !_vm.crmDataLoaded ? _c('div', {
    staticClass: "padding"
  }, [_vm._m(1)]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('th', [_c('span', [_vm._v("Besichtigungsadresse")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "tac"
  }, [_c('div', {
    staticClass: "loading-spinner _large"
  })]);
}];
export { render, staticRenderFns };