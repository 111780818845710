var images = {
  logo: require("@/assets/img/portallogo.png"),
  icon1: require("@/assets/marker/marker10.svg"),
  icon10: require("@/assets/marker/marker10.svg"),
  icon11: require("@/assets/marker/marker11.svg"),
  icon12: require("@/assets/marker/marker12.svg"),
  icon13: require("@/assets/marker/marker13.svg"),
  icon14: require("@/assets/marker/marker14.svg"),
  icon15: require("@/assets/marker/marker15.svg"),
  icon16: require("@/assets/marker/marker16.svg"),
  icon16png: require("@/assets/marker/marker16.png"),
  icon17: require("@/assets/marker/marker17.svg"),
  icon18: require("@/assets/marker/marker18.svg"),
  icon19: require("@/assets/marker/blackmark.svg"),
  icon19png: require("@/assets/marker/marker19.png"),
  icon3: require("@/assets/marker/marker3.svg"),
  icon4: require("@/assets/marker/marker4.svg"),
  nicon: require("@/assets/marker/nomarker.png"),
  largemarker: require("@/assets/marker/largemarker.svg")
};
export default images;