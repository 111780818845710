import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
/// * eslint-disable */
import dayjs from "dayjs";
import { mapGetters, mapState, mapActions } from "vuex";
import utils from '@/libs/utils.ts';
// import { mapGetters, mapState } from 'vuex'
var self = null;
// let self;
var selfEXP = {
  install: function install(Vue, options) {
    Vue.mixin({
      beforeCreate: function beforeCreate() {
        self = this;
      },
      watch: {},
      computed: _objectSpread({}, mapGetters(['tok', "_currentUser", "isFb", "_userUid", "isCrm", "_isAdmin", "_isAdminPlus", "_isExternSv", "_isSvPlus", "_isInternSv", "_isGf", "isSv", '_isSv', "_userUid", "_userRole", "svRights", 'userUid', 'isNoRole', '_crmMayEditVertraulich'])),
      methods: _objectSpread(_objectSpread(_objectSpread({}, utils), mapActions(["SET", "UNSET", '_config'])), {}, {
        $_error: function $_error(msg) {
          self.$notify({
            group: "main",
            text: msg,
            type: "error"
          });
        },
        $_extension: function $_extension(name) {
          return (name || "").split(".").pop().toLowerCase();
        },
        $_splitName: function $_splitName(name) {
          var nameArr = (name || "").split(".");
          var format = '';
          var nuname = name;
          if (nameArr.length > 1) {
            // format = '.' + nameArr[nameArr.length - 1]
            format = '.' + nameArr.pop();
            nuname = nameArr.join('.');
          }
          return {
            format: format,
            nuname: nuname
          };
        },
        // makeId(eTag) {
        $_makeEtagId: function $_makeEtagId(eTag) {
          // console.log(eTag.substring(3,38));
          return eTag.substring(2, 38).toUpperCase();
        },
        $_makeImgThumbnailUrl: function $_makeImgThumbnailUrl(item) {
          var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 256;
          var spToken = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '#';
          var inputFormat = this.$_extension(item.name);
          var preUrl1 = "https://westeurope1-mediap.svc.ms/transform/thumbnail?provider=spo&inputFormat=" + inputFormat + "&cs=fFNQTw&docid=";
          var preUrl2 = "https%3A%2F%2Fconsense.sharepoint.com%3A443%2F_api%2Fv2.0%2Fdrives%2Fb!wug5omUQ50G4ePNqKK2kP0kmZKHyL-hNh7blIuRKX_SxDiJ-BNwXTqqQBdK7mkKX%2Fitems%2F";
          var postUrl1 = "%3Fversion%3DPublished";
          var postUrl2 = "&access_token=" + spToken;
          var postUrl3 = "&encodeFailures=1&width=" + size + "&height=" + size;
          var link = preUrl1 + preUrl2 + item.id + postUrl1 + postUrl2 + postUrl3;
          // console.log(link)
          return link;
        },
        $_getImgUrlT: function $_getImgUrlT(itemPath, size) {
          return self.$env.mainurl + self.$_makeImageSize(itemPath)[size];
        },
        $_getImgUrl: function $_getImgUrl(itemPath, size) {
          return self.$env.mainurl + self.$_makeImageSize(itemPath)[size] + self.tok;
        },
        $_makeImageSize: function $_makeImageSize(itemPath) {
          // console.log(itemPath)
          var fromArr = itemPath.split('.');
          // console.log(fromArr);
          var nuformat = '.' + fromArr[fromArr.length - 1];
          var isIMG = itemPath.includes('/Bilder/');
          var isHVW = itemPath.includes('/HVW/');
          var isDOC = itemPath.includes('/Dokumente/');
          var isRealPath = isHVW || isDOC;
          var mainImage = isIMG ? itemPath.replace('/Bilder/', '/_originalBilder/').replace('.webp', '.jpg') : itemPath;
          var fname = itemPath.substring(0, itemPath.length - nuformat.length);
          var orgUrl = isRealPath ? itemPath : fname + '_original.jpg';
          // orgUrl = self.$_validateIfUrlExists(orgUrl) ? orgUrl : itemPath

          var result = {
            original: orgUrl,
            c: isRealPath ? itemPath : fname + '_c_.jpg',
            t: isRealPath ? itemPath : fname + '_t_.webp',
            path: itemPath,
            mainImage: mainImage
          };
          return result;
        },
        $_clickToDownload: function $_clickToDownload(item) {
          var imgLink = self.$_getImgUrl(item, "mainImage");
          var isJpgUrl = self.$_validateIfUrlExists(imgLink);
          var pngImgLink = imgLink.replace(".jpg", ".png");
          var isPngUrl = self.$_validateIfUrlExists(pngImgLink);
          var thisLink = isJpgUrl ? imgLink : isPngUrl ? pngImgLink : "";
          thisLink && window.open(thisLink, "_blank");
        },
        $_validateIfUrlExists: function $_validateIfUrlExists(url) {
          try {
            var http = new XMLHttpRequest();
            http.open("HEAD", url, false);
            http.send();
            // console.log('$_validateIfUrlExists', url, http.status != 404)
            return http.status != 404;
          } catch (error) {
            // console.log('error in $_validateIfUrlExists')
            console.error(error);
            return true;
          }
        },
        $_byStr: function $_byStr(o, s) {
          s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
          s = s.replace(/^\./, ''); // strip a leading dot
          var a = s.split('.');
          for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
              o = o[k];
            } else {
              return;
            }
          }
          return o;
        },
        $_ceilMoney: function $_ceilMoney(val, t, noloc) {
          // = old roundC
          return self.$$__roundCeilMony(val, t, noloc, 'ceil');
        },
        $_roundMoney: function $_roundMoney(val, t, noloc) {
          // = old round
          return self.$$__roundCeilMony(val, t, noloc, 'round');
        },
        $$__roundCeilMony: function $$__roundCeilMony(val, t, noloc, roundCeil) {
          // = old round
          // ROUND GIVEN NUMBVER UP TO NEXT "t"POTENZ  1: .1 2: .01 ...  -1: 10 -2: 100 -3: 1000  ... // noloc : bool -> give not back in german format
          var resVal = 0;
          if (roundCeil === 'round') {
            resVal = Math.round(val * Math.pow(10, t)) / Math.pow(10, t);
          } else if (roundCeil === 'ceil') {
            resVal = Math.ceil(val * Math.pow(10, t)) / Math.pow(10, t);
          }
          var returnVal = noloc ? resVal : resVal.toLocaleString("de-DE", {
            minimumFractionDigits: t < 2 ? 2 : t,
            maximumFractionDigits: t < 2 ? 2 : t
          });
          return returnVal;
        },
        /*************************************************
         fPages
         *************************************************/
        $_fPages: function $_fPages(filteredList, itemsPerPage, onpage) {
          var pageArr = [];
          var resultArr = [];
          var countsPerPage = 0;
          var pagesCount = 0;
          var filteredListLength = filteredList.length;
          // filteredList = this.$_cp(filteredList);

          for (var item in filteredList) {
            countsPerPage++;
            if (onpage - 1 == pagesCount) pageArr.push(filteredList[item]);else pageArr.push({});
            if (countsPerPage === itemsPerPage || countsPerPage + pagesCount * itemsPerPage === filteredListLength) {
              resultArr.push(pageArr);
              pageArr = [];
              countsPerPage = 0;
              pagesCount++;
            }
          }
          return resultArr;
        },
        $_successResponse: function $_successResponse(sucMsg) {
          self.$notify({
            group: "shortInfo",
            title: "Daten gespeichert",
            text: sucMsg,
            type: "success"
          });
        },
        $_errorResponse: function $_errorResponse(errMsg) {
          self.$notify({
            group: "shortInfo",
            title: "Daten nicht gespeichert",
            text: errMsg,
            type: "error"
          });
        },
        $_Autorespond: function $_Autorespond(req, err) {
          /*************************************************
           this is only for FS-Allianz-Akt-Data
           *************************************************/
          if (req.err) {
            self.$notify({
              group: "shortInfo",
              text: "Daten nicht gespeichert! \n" + err,
              type: "error"
            });
          } else {
            self.$notify({
              group: "shortInfo",
              text: "Daten gespeichert!",
              type: "success"
            });
          }
        }
      })
    });
  }
};
export default selfEXP;