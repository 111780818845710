var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [!_vm.isShowingOnlySp ? _c('div', {
    staticClass: "nav-wrapper",
    class: {
      'user-signed-in': _vm.isShowingSidebar,
      _shallow: _vm.isToggeldSidebar
    }
  }, [_c('the-navigation', {
    staticClass: "grid-header"
  }), _c('nav', {
    staticClass: "nav"
  }, [_c('div', {
    staticClass: "padding _vertical _hover"
  }, [_c('div', {
    staticClass: "color-white tac",
    on: {
      "click": function click($event) {
        _vm.isToggeldSidebar = !_vm.isToggeldSidebar;
      }
    }
  }, [_c('i', {
    staticClass: "icon-rounddots _large"
  })])]), _c('the-sidebar', {
    attrs: {
      "shallow": _vm.isToggeldSidebar
    }
  })], 1), _c('main', {
    staticClass: "main"
  }, [_c('keep-alive', {
    attrs: {
      "exclude": _vm.excludeFromKeepingAlive
    }
  }, [_c('router-view')], 1)], 1), _c('div', {
    staticClass: "submain"
  }), _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "credits"
  }, [_vm._v("Consense")]), _c('div', {
    staticClass: "credits"
  }, [_vm._v(_vm._s(_vm.userName))]), _c('div', {
    staticClass: "credits"
  }, [_vm.hasNuVersion ? _c('span', {
    staticClass: "color-red bold",
    on: {
      "click": _vm.forceRefreshPage
    }
  }, [_vm._v("UPDATE VERFÜGBAR ")]) : _vm._e(), _c('router-link', {
    staticClass: "color-white",
    attrs: {
      "to": "/changelogs"
    }
  }, [_vm._v("V " + _vm._s(_vm.$env.version))])], 1)])], 1) : _vm.isShowingOnlySp ? _c('div', {
    staticClass: "sharepoint-view"
  }, [_c('router-view')], 1) : _vm._e(), _c('NotificationsModal'), _c('notifications', {
    attrs: {
      "group": "notifications",
      "duration": 12000,
      "position": "bottom left"
    }
  }), _c('notifications', {
    attrs: {
      "group": "shortInfo",
      "duration": 3000
    }
  }), _c('notifications', {
    attrs: {
      "group": "main",
      "duration": 8000,
      "position": "bottom left"
    }
  }), _c('notifications', {
    attrs: {
      "group": "mid",
      "duration": 12000
    }
  }), _c('notifications', {
    attrs: {
      "group": "longinfo",
      "duration": 30000
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        return [_c('div', [_c('a', {
          staticClass: "title"
        }, [_vm._v(_vm._s(props.item.title))]), _c('a', {
          staticClass: "close",
          on: {
            "click": props.close
          }
        }, [_c('i', {
          staticClass: "fa fa-fw fa-close"
        })]), _c('div', {
          domProps: {
            "innerHTML": _vm._s(props.item.text)
          }
        })])];
      }
    }])
  }), _c('div', {
    staticClass: "infotag",
    class: {
      'infotag--opened': _vm.isUploadingOfflineFiles
    }
  }, [_vm._m(0), _c('span', [_vm._v("  Dateien hochladen! "), _c('small', [_vm._v("(" + _vm._s(_vm.uploadInfo) + ")")])])]), _vm.isShowingInternetError ? _c('div', {
    staticClass: "shadow",
    attrs: {
      "id": "NoInternet"
    }
  }, [_vm._v("Keine Verbindung zum Server!")]) : _vm._e(), _vm.isShowingInfoToUseOnlyOwn ? _c('div', {
    staticClass: "info-for-tablet shadow"
  }, [_c('div', {
    staticClass: "link _whitep",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('infoShowOnlyMy');
      }
    }
  }, [_vm._v("Info fürs Tablet")])]) : _vm._e(), _vm.hasNuVersion ? _c('div', {
    staticClass: "general-user-info shadow"
  }, [_c('div', {
    staticClass: "link _whitep",
    on: {
      "click": _vm.forceRefreshPage
    }
  }, [_vm._v("NEUE VERSION VERFÜGBAR"), _c('br'), _vm._v("Bitte hier klicken")])]) : _vm.generalUserInfo ? _c('div', {
    staticClass: "general-user-info shadow"
  }, [_c('div', {
    staticClass: "link _whitep",
    on: {
      "click": function click($event) {
        return _vm.$modal.show('nuversion');
      }
    }
  }, [_vm._v("WICHTIGE INFO"), _c('br'), _vm._v("Bitte hier klicken")])]) : _vm._e(), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "infoShowOnlyMy",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("INFO:"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('infoShowOnlyMy');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('h4', [_vm._v("INFO:")]), _c('p', [_vm._v("Am Tablet ist es sinnvoll nicht alle Akte anzuzeigen, sondern nur die eigenen, es schont Resourcen und läuft schneller.")]), _c('p', [_vm._v("Es kann in den Einstellungen mit dem Punkt \"nur meine eigene Akte laden (CRM und Allianz)\" ausgewählt werden:")]), _c('span', {
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('infoShowOnlyMy');
      }
    }
  }, [_c('router-link', {
    staticClass: "bold",
    attrs: {
      "to": "/useraccount"
    }
  }, [_vm._v("Einstellungen")])], 1)])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "nuversion",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("User Info"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('nuversion');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.generalUserInfo)
    }
  })]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('nuversion');
      }
    }
  }, [_vm._v("OK")])])]), _c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "Loader",
      "clickToClose": true,
      "width": "300",
      "height": "90"
    },
    on: {
      "before-open": _vm.LoderStarts
    }
  }, [_c('div', {
    staticClass: "padding tac"
  }, [_c('div', {
    staticClass: "loading-spinner tac"
  })])]), _c('OfflineImageStore'), _c('div', {
    staticClass: "main-page-loader",
    class: {
      _opened: _vm.isLoading
    },
    on: {
      "click": function click($event) {
        _vm.isLoading = false;
      }
    }
  }, [_c('div', {
    staticClass: "loading-spinner"
  })]), _c('v-dialog', {
    attrs: {
      "transition": "scale"
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('div', {
    staticClass: "loading-spinner inline"
  })]);
}];
export { render, staticRenderFns };