var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "notificationsModal"
  }, [_c('modal', {
    staticClass: "modal noselect",
    attrs: {
      "name": "MessageBack",
      "adaptive": true,
      "draggable": false,
      "scrollable": true,
      "resizable": true,
      "reset": true,
      "width": "640",
      "height": "auto"
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Antworten"), _c('span', {
    staticClass: "closer _hover",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('MessageBack');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])]), _c('div', {
    staticClass: "block"
  }, [_c('h3', [_vm._v("Antworten")]), _c('TipTap', {
    attrs: {
      "content": _vm.sendBackMessage,
      "ckey": 'message'
    }
  })], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "btn _med _red",
    on: {
      "click": function click($event) {
        return _vm.$modal.hide('MessageBack');
      }
    }
  }, [_vm._v("Abbrechen")]), _c('div', {
    staticClass: "btn _med _green",
    on: {
      "click": function click($event) {
        _vm.sendMessageBack(), _vm.$modal.hide('MessageBack');
      }
    }
  }, [_vm._v("Absenden")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };