import "core-js/modules/es.object.to-string.js";
import store from '@/store/store';
/**
 * To add a new feature flag to the platform, insert it here!
 * The code below will take care of injecting the feature flag into the exported object
 * by querying the value from Vuex and checking against current user roles.
 * The object will then also be typed correctly by typescript!
 *
 * This also applies in reverse: To remove a feature flag, take it out of the array,
 * typescript will then complain to you where you still have dangling references to it.
 *
 * To check for a feature, you can either include this module and just access it's properties normally
 * (from the default export), or, when working in a view template, you can access it via the global `$features` object.
 */
var features = ['messages-as-email', 'hnImprovements', 'create-timetable'];
var featureFlags = {};
features.forEach(appendFeature);
export default featureFlags;
function appendFeature(featureFlag) {
  Object.defineProperty(featureFlags, featureFlag, {
    get: function get() {
      var userData = store.state.userDataFb;
      var flag = store.getters.featureFlags[featureFlag] || false;
      return isFeatureAllowed(flag, userData);
    },
    configurable: false,
    enumerable: true
  });
}
function isFeatureAllowed(definition, user) {
  if (typeof definition === 'boolean') return definition;
  return definition.split('|').some(function (part) {
    switch (part) {
      case 'NONE':
        return false;
      case 'ALL':
        return true;
      case 'ADMIN':
        return user.userRole === 'admin';
      case 'GF':
        return user.userRole === 'gf';
      case 'SV':
        return user.userRole === 'sv';
      case 'SSV':
        return user.userRole === 'sv' && user.rights.powerUser;
      default:
        return false;
    }
  });
}