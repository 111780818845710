var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-sidebar",
    class: {
      _shallow: _vm.shallow
    }
  }, [_vm._isAdmin || _vm._isSv && (_vm.svRights.crmReadWriteOwn || _vm.svRights.crmReadWriteOwn) ? [_c('div', {
    staticClass: "menu-title"
  }, [_vm._v("CRM")]), _c('ul', {
    staticClass: "sidebar-menu"
  }, [_vm._isAdmin || _vm.svRights.crmReadWriteAll ? _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute == 'list'
    },
    attrs: {
      "to": "/list"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Offene Akte")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'map'
    },
    attrs: {
      "to": "/map"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-mapmarker"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Map CRM")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'schedule'
    },
    attrs: {
      "to": "/schedule"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-reminder"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Besichtigungen")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'calendar'
    },
    attrs: {
      "to": "/calendar"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-address"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Sachverständige")])])], 1) : _vm._isAdmin || _vm.svRights.crmReadWriteOwn ? _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute == 'list'
    },
    attrs: {
      "to": "/list"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Offene Akte")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'map'
    },
    attrs: {
      "to": "/map"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-mapmarker"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Map CRM")])])], 1) : _vm._e(), _vm._isAdmin || _vm.svRights.crmReadWriteOwn ? _c('li', [_c('router-link', {
    staticClass: "rel",
    class: {
      current: _vm.currentRoute == 'notifications'
    },
    attrs: {
      "to": "/notifications"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Nachrichten")]), _vm.ownPendingNotificationsCount ? _c('div', {
    staticClass: "numberCount"
  }, [_vm._v(_vm._s(_vm.ownPendingNotificationsCount))]) : _vm._e()])], 1) : _vm._e(), _vm._isAdmin ? _c('li', [_c('router-link', {
    staticClass: "rel",
    class: {
      current: _vm.currentRoute == 'urgences'
    },
    attrs: {
      "to": "/urgences"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Urgenzen")])])], 1) : _vm._e()])] : _vm._e(), _vm._isAdmin || _vm._isSv && (_vm.svRights.allianzReadWriteOwn || _vm.svRights.allianzReadWriteAll || _vm.svRights.allianzReadReports) ? [_c('div', {
    staticClass: "menu-title"
  }, [_vm._v("Allianz")]), _c('ul', {
    staticClass: "sidebar-menu"
  }, [_vm._isAdmin || _vm._isSv && _vm.svRights.allianzReadWriteAll ? _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzList'
    },
    attrs: {
      "to": "/allianz/list"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Allianz Akte")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzHVW'
    },
    attrs: {
      "to": "/allianz/hvw"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-address"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Hausverw.")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzMap'
    },
    attrs: {
      "to": "/allianz/map"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-mapmarker"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Allianz Karte")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzStatus'
    },
    attrs: {
      "to": "/allianz/status"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-checklist"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Allianz Status")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzUploads'
    },
    attrs: {
      "to": "/allianz/uploads"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-upload"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Uploads")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzReports'
    },
    attrs: {
      "to": "/allianz/reports"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-checkmark"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Fertige Bewer.")])]), _vm._isAdmin ? _c('span', [_c('router-link', {
    class: {
      current: _vm.currentRoute == 'StatsAllianz'
    },
    attrs: {
      "to": "/allianz/stats"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Statistik")])])], 1) : _vm._e(), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'Wiki'
    },
    attrs: {
      "to": "/allianz/wiki"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Wiki")])])], 1) : _vm._isAdmin || _vm._isSv && _vm.svRights.allianzReadWriteOwn ? _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzList'
    },
    attrs: {
      "to": "/allianz/list"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Meine Akte")])]), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzMap'
    },
    attrs: {
      "to": "/allianz/map"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-mapmarker"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Allianz Karte")])]), _vm.svRights.allianzReadReports ? _c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzReports'
    },
    attrs: {
      "to": "/allianz/reports"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-checkmark"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Fertige Bewer.")])]) : _vm._e(), _c('router-link', {
    class: {
      current: _vm.currentRoute == 'Wiki'
    },
    attrs: {
      "to": "/wiki"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Wiki")])])], 1) : _vm._isSv && _vm.svRights.allianzReadReports ? _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute == 'AllianzReports'
    },
    attrs: {
      "to": "/allianz/reports"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-checkmark"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Fertige Bewer.")])])], 1) : _vm._e()])] : _vm._e(), _vm.$_size(_vm.offlineImages) > 0 ? [_c('div', {
    staticClass: "menu-title"
  }, [_vm._v("Offline")]), _c('ul', {
    staticClass: "sidebar-menu color white"
  }, [_c('li', [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.showOflineImages();
      }
    }
  }, [_vm._m(0), _c('span', {
    staticClass: "label"
  }, [_vm._v("OfflineStore (" + _vm._s(_vm.$_size(_vm.offlineImages)) + ")")])])])])] : _vm._e(), _vm._isAdmin ? [_c('div', {
    staticClass: "menu-title"
  }, [_vm._v("Admin")]), _c('ul', {
    staticClass: "sidebar-menu color white"
  }, [_vm._isAdminPlus ? _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute === 'Users'
    },
    attrs: {
      "to": "/users"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-users"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Benutzer")])])], 1) : _vm._e(), _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute === 'Insurers'
    },
    attrs: {
      "to": "/insurers"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-users"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Versicherer")])])], 1), _c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute === 'templates'
    },
    attrs: {
      "to": "/templates"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-gear"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Geb.Vorlagen")])])], 1)])] : _vm._e(), _vm._isAdmin || _vm._isSv || _vm.isNoRole ? [_c('ul', {
    staticClass: "sidebar-menu _bottom"
  }, [_c('li', [_c('router-link', {
    class: {
      current: _vm.currentRoute == 'UserAccount'
    },
    attrs: {
      "to": "/useraccount"
    }
  }, [_c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-gear"
  })]), _c('span', {
    staticClass: "label"
  }, [_vm._v("Einstellungen")])])], 1)])] : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "icon-item"
  }, [_c('i', {
    staticClass: "icon-list"
  })]);
}];
export { render, staticRenderFns };