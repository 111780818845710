var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "modal"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.title))];
  }), _c('span', {
    staticClass: "closer _hover",
    attrs: {
      "data-testid": "close"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('i', {
    staticClass: "icon-times inline"
  })])], 2), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };