export default {
  name: "VDate",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: [String, null],
      required: true,
      default: ""
    }
  },
  watch: {
    value: function value(_value) {
      this.currentValue = _value;
    },
    currentValue: function currentValue(value) {
      this.$emit("input", value || "");
    }
  },
  data: function data() {
    return {
      currentValue: this.value || ""
    };
  },
  computed: {},
  methods: {}
};