var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-popover', {
    attrs: {
      "placement": "right"
    }
  }, [_c('button', {
    staticClass: "btn _small _bordered",
    class: {
      'color-red': _vm.status === 'pending',
      'color-orange': _vm.status === 'processing',
      'color-green': _vm.status === 'completed'
    }
  }, [_c('small', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.translateStatus(_vm.status)))])]), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "chooseList"
  }, [_c('li', {
    staticClass: "chooseList_li color-red",
    on: {
      "click": function click($event) {
        return _vm.$emit('status-changed', 'pending');
      }
    }
  }, [_vm._v(_vm._s(_vm.translateStatus('pending')))]), _c('li', {
    staticClass: "chooseList_li color-orange",
    on: {
      "click": function click($event) {
        return _vm.$emit('status-changed', 'processing');
      }
    }
  }, [_vm._v(_vm._s(_vm.translateStatus('processing')))]), _c('li', {
    staticClass: "chooseList_li color-green",
    on: {
      "click": function click($event) {
        return _vm.$emit('status-changed', 'completed');
      }
    }
  }, [_vm._v(_vm._s(_vm.translateStatus('completed')))])])])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };