var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('dynamic-modal-container', {
    attrs: {
      "title": "Abbildungsbeschriftung"
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('textarea-autosize', {
    attrs: {
      "placeholder": "",
      "min-height": 24,
      "max-height": 320
    },
    model: {
      value: _vm.caption,
      callback: function callback($$v) {
        _vm.caption = $$v;
      },
      expression: "caption"
    }
  }), _c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("Ok")]), _c('button', {
    staticClass: "btn",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Abbrechen")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };