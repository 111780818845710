var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('vue-dropzone', {
    ref: "dropzone",
    attrs: {
      "id": "dropzone",
      "options": _vm.dropzoneConfiguration,
      "use-custom-slot": true
    },
    on: {
      "vdropzone-file-added": _vm.uploadFileToSharepointAndAttachAdded,
      "vdropzone-success-multiple": _vm.uploadFileToSharepointAndAttachSuccess,
      "vdropzone-error": _vm.uploadFileToSharepointAndAttachError,
      "vdropzone-total-upload-progress": _vm.uploadFileToSharepointAndAttachProgress
    }
  }, [_c('div', [_c('h3', [_vm._v("Dateien zum hochladen hier hineinziehen")]), _c('div', [_vm._v("...oder hier klicken, um Dateien auszuwählen")])])]), _vm.uploadProgress > 0 ? _c('div', {
    staticClass: "padding"
  }, [_vm._m(0), _c('div', {
    staticClass: "flex aic jcc"
  }, [_c('progress', {
    attrs: {
      "id": "file-progress",
      "max": "100"
    },
    domProps: {
      "value": _vm.uploadProgress
    }
  })])]) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "flex aic jcc"
  }, [_c('div', {
    staticClass: "loading-spinner"
  })]);
}];
export { render, staticRenderFns };