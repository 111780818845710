import _objectSpread from "/Users/weber/projects/CONSENSE/app/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import createMutationsSharer from "vuex-shared-mutations";
import VuexEasyFirestore from "vuex-easy-firestore";
import VuexPersistence from "vuex-persist";
import * as FBActions from "@/store/FBActions";
import * as CRMActions from "@/store/CRMActions";
import * as GlobalActions from "@/store/GlobalActions";
import * as GlobalGetters from "@/store/GlobalGetters";
import * as GlobalMutations from "@/store/GlobalMutations";
import * as initialState from "@/store/initialState";
import firestoreModules from "@/store/firestoreModules.js";
import { getField, updateField } from 'vuex-map-fields';
import notifications, { manageGroupSubscriptions } from './modules/notifications';
import commissionMetadata, { manageCommissionMetadata } from "@/store/modules/commissionMetadata";
Vue.use(Vuex);
/*************************************************
 Local Storage Persist
 *************************************************/
var vuexPersistLocalStorage = new VuexPersistence({
  storage: window.localStorage,
  key: "constore",
  reducer: function reducer(state) {
    return {
      firsttimecalls: state.firsttimecalls,
      userLogin: state.userLogin,
      userDataFb: state.userDataFb,
      userDataCrm: state.userDataCrm,
      user: state.user,
      isAutoSaveGa: state.isAutoSaveGa,
      selTab: state.selTab,
      showInAllianzTable: state.showInAllianzTable,
      lastcalls: state.lastcalls,
      filterMyAkte: state.filterMyAkte,
      filterMyAkteSv1: state.filterMyAkteSv1,
      filterZ1: state.filterZ1,
      iperpage: state.iperpage,
      isUsingOffline: state.isUsingOffline,
      "FB_users.data": state.FB_users.data,
      "FB_allianzStatus.data": state.FB_allianzStatus.data
    };
  }
});
var actions = Object.assign({}, GlobalActions.default, FBActions.default, CRMActions.default);
var getters = Object.assign({}, GlobalGetters.default);
var mutations = Object.assign({}, GlobalMutations.default);
var easyFirestore = VuexEasyFirestore(firestoreModules, {
  logging: true,
  FirebaseDependency: firebase
});
var plugins = [vuexPersistLocalStorage.plugin, function (store) {
  if (store.state.isUsingOffline) {
    firebase.firestore().enablePersistence({
      synchronizeTabs: true
    }).then(function () {
      console.log("done: synchronizeTabs enablePersistence");
    }).catch(function (err) {
      console.log(err);
    });
  }
}, easyFirestore, createMutationsSharer({
  predicate: ["setAuth"]
}), manageGroupSubscriptions, manageCommissionMetadata];
/*************************************************
 THE ACTUAL STORE
 *************************************************/
var thestore = new Vuex.Store({
  // strict: true,
  plugins: plugins,
  state: initialState.default(),
  mutations: _objectSpread(_objectSpread({}, mutations), {}, {
    updateField: updateField
  }),
  getters: _objectSpread(_objectSpread({}, getters), {}, {
    getField: getField
  }),
  actions: actions,
  modules: {
    notifications: notifications,
    commissionMetadata: commissionMetadata
  }
});
export default thestore;